import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSelectorModel } from '../shared/models/language-selector.model';
import { Observable, of } from 'rxjs';
import { TransferState, StateKey, makeStateKey } from '@angular/platform-browser';

export class TranslateBrowserLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
  ) {
  }

  public getTranslation(lang: string): Observable<unknown> {
    const key: StateKey<string> = makeStateKey<string>('transfer-translate-' + lang,);

    const data = this.transferState.get(key, null);

    if (data) {
      return of(data);
    }

    return new TranslateHttpLoader(this.http).getTranslation(lang);
  }
}

export function translateBrowserLoaderFactory(
  httpClient: HttpClient,
  transferState: TransferState,
): TranslateBrowserLoader {
  return new TranslateBrowserLoader(httpClient, transferState);
}


@Injectable({
  providedIn: 'root'
})
export class CustomNgxTranslateService {

  constructor(private translate: TranslateService) { }

  initDefaultLang() {
    // this.translate.setDefaultLang('en');
    // this.translate.use('en');
    this.translate.use(this.getLanguage());
  }

  getAvailableLanguages(): LanguageSelectorModel[] {
    const result = [];

    const en = new LanguageSelectorModel();
    en.alpha2 = "en";
    en.translationKey = "EN";
    en.iconPath = "assets/svg/lang/en.svg";
    result.push(en);


    const ua = new LanguageSelectorModel();
    ua.alpha2 = "ua";
    ua.translationKey = "UA";
    ua.iconPath = "assets/svg/lang/ua.svg";
    result.push(ua);

    return result;
  }

  getDefaultSelectedLanguage(): LanguageSelectorModel {
    const en = new LanguageSelectorModel();
    en.alpha2 = "en";
    en.translationKey = "en";
    en.iconPath = "assets/svg/lang/en.svg";

    return en;
  }

  getLanguage(): string {
    const browserLang: string = this.translate.getBrowserLang();
    const selectedCountry: string = localStorage?.getItem('selectedCountry');

    if (selectedCountry) {
      return selectedCountry;
    } else if (this.translate.getLangs().includes(browserLang)) {
      return browserLang;
    } else {
      return "en";
    }
  }

  setLanguageToLocalStorage(language: string) {
    localStorage?.setItem("selectedCountry", language)
  }


}
