<div class="servicesShared">

  <div class="leftSection">
    <div class="title">specialist <span>support</span></div>
    <div class="smallPicture pic6"></div>


    <div class="content">
      If you encounter an unexpected challenge, sudden glitch, or simply want to draw on advanced, specialist IT services, we’re here to help.
      <br/><br/>
      Our talent includes top tech leaders, each with over 20 years of experience in development and systems engineering. Their expertise is at your disposal whenever you need extra support, insight, or specific skillsets.
    </div>
    <div class="letsTalkButton"  (click)="letsTalkClicked()">Let’s Talk</div>
  </div>


  <div class="rightSide">
    <div class="picture pic6"></div>
  </div>


</div>


<app-rotating-technologies></app-rotating-technologies>
<app-testimonials></app-testimonials>
<app-ready-steady-go></app-ready-steady-go>
<app-supporting-services [isSupporting]="false"></app-supporting-services>
<app-why-our-services></app-why-our-services>
<app-ready-to-talk></app-ready-to-talk>
