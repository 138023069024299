import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IWorkingAtGlobalImageItemData } from '../models/working-at-global.model';
import { DialogFullScreenService } from '../../services/dialog-full-screen.service';
import { FullScreenDialogData } from '../models/dialog-full-screen.model';
@Component({
  selector: 'app-ng-image-slider-custom',
  templateUrl: './ng-image-slider-custom.component.html',
  styleUrls: ['./ng-image-slider-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NgImageSliderCustomComponent implements OnInit {

  @Input() width: string;
  @Input() height: string;
  @Input() itemObject: IWorkingAtGlobalImageItemData;

  constructor(private dialogFullScreenService: DialogFullScreenService) { }

  ngOnInit() {
  }

  onImageClicked() {
    const dialogData = new FullScreenDialogData();
    dialogData.srcImgPath = this.itemObject.image;

    this.dialogFullScreenService.openDialog(dialogData);
  }

}
