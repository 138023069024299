import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { RouterData } from '../shared/models/common.model';


@Injectable({
  providedIn: 'root'
})
export class SeoTagsService {

  private currentTitleForTranslation = 'COMMON.GLOBAL_TEAMS_LOWER';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private metaTagService: Meta
  ) { }

  subscribeNavigationChange(): void {
    this.addBaseMetaTags();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;

        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data: RouterData) => {
      const routeTitle: string = data?.title;

      if (routeTitle) {
        this.currentTitleForTranslation = routeTitle;
        this.translateAndSetTitle(this.currentTitleForTranslation);
      }

      const metaDescriptionTag: string = data?.metaDescriptionTag;

      if (metaDescriptionTag) {
        this.updateMetaTag(metaDescriptionTag);
      }
    });
  }

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  private translateAndSetTitle(newTitle: string = this.currentTitleForTranslation): void {
    this.translate.get(newTitle).subscribe((translatedTitle: string) => this.setTitle(translatedTitle));
  }

  private addBaseMetaTags() {
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Software Developers, East-Central Europe Remote Work, It East-Central European, It Outsourcing Ukraine, It outstaffing East-Central European, Global Teams, It developers' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Global Teams' },
      //{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

  updateMetaTag(tagContent: string) {
    if (!tagContent) {
      return;
    }

    const translatedTagContent = this.translate.instant(tagContent);

    if (!translatedTagContent) {
      return;
    }

    this.metaTagService.updateTag(
      { name: 'description', content: translatedTagContent }
    );
  }
}
