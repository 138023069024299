import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ImageCustomService } from 'src/app/services/image-custom.service';

@Component({
  selector: 'app-circle-with-text',
  templateUrl: './circle-with-text.component.html',
  styleUrls: ['./circle-with-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CircleWithTextComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() bodyText: string;
  @Input() hoverImage?: string;

  constructor(private imageCustomService: ImageCustomService) { }

  ngOnInit() {
    if (this.hoverImage) {
      this.imageCustomService.preloadImage(this.hoverImage);
    }
  }

}
