<div class="screenBlockerMenu" (click)="closeMenu()" *ngIf="menuVisible || menuPcVisible" [@inOutAnimation]></div>

<div id="header" class="header sticky max-body-width " [ngClass]="{'dark': onWhite || stateIsWhite}">

  <div id="logo" class="logo white" [ngClass]="{'dark': onWhite || stateIsWhite}" [routerLink]="['/']"></div>
  <div class="navBar">

    <div class="item" [ngClass]="{'selected':menuSelected.home}" [routerLink]="['/']">
      {{"HEADER.Home"|translate}}
      <div class="progressContainer">
        <div class="dotBar"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>

      </div>
    </div>
    <div class="item hasChildren" (click)="openTopBar('services')"
         [ngClass]="{'active': menuState['services'], 'selected':menuSelected.services }">
      {{"HEADER.Services"|translate}}

      <div class="progressContainer">
        <div class="dotBar"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>

      </div>

    </div>
    <div class="item hasChildren" (click)="openTopBar('company')"
         [ngClass]="{'active': menuState['company'], 'selected':menuSelected.company }">
      {{"HEADER.Company"|translate}}

      <div class="progressContainer">
        <div class="dotBar"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>

      </div>
    </div>
    <div class="item hasChildren" (click)="openTopBar('career')"
         [ngClass]="{'active': menuState['career'], 'selected':menuSelected.career }">
      {{"HEADER.Career"|translate}}

      <div class="progressContainer">
        <div class="dotBar"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>
        <div class="progressDot"></div>

      </div>
    </div>

    <div class="item" (click)="openBlog()">
      {{"HEADER.Blog"|translate}}
    </div>
  </div>

  <div class="language-selector-lets-talk">
    <app-language-selector class="desktop-view"></app-language-selector>

    <div class="letsTalkButtonSmall" (click)="openLetsTalk(false)">
      <span>{{"HEADER.Lets_Talk"|translate}}</span>
    </div>
  </div>

  <div class="mobileMenuButton" *ngIf="!menuVisible" (click)="openMenu()"></div>

</div>

<div class="mobileMenu" *ngIf="menuVisible || menuPcVisible" [@inOutAnimation]>
  <div class="logo colorful"></div>
  <div class="crossIcon" (click)="closeMenu()" style="position: absolute;right:30px;top:30px;"></div>


  <div class="menu" [ngClass]="{'dark': onWhite || stateIsWhite}">

    <div class="mobileMenuItem" [routerLink]="['/']">
    {{"HEADER.Home"|translate}}
    </div>
    <div class="seperatorLine" *ngIf="!menuState['services']"></div>
    <div class="mobileMenuItem hasChildren" (click)="openTopBar('services')"
         [ngClass]="{'active': menuState['services'] }">
      {{"HEADER.Services"|translate}}
    </div>
    <div class="mobileMenuSubItems" [ngClass]="{'showSubMenu': menuState['services']}">
      <a class="mobileMenuItem" [routerLink]="['/services/extended-teams']">
      {{"HEADER.Extended_Teams"|translate}}
      </a>
      <a class="mobileMenuItem" [routerLink]="['/services/managed-teams']">
        {{"HEADER.Managed_Teams"|translate}}
      </a>
      <a class="mobileMenuItem" (click)="closeMenu()" [routerLink]="['/services/supporting-services']"
        [fragment]="'specialistsupport'">
        {{"HEADER.Specialist_Support"|translate}}
      </a>
      <a class="mobileMenuItem" [routerLink]="['/services/end-to-end-projects']">
        {{"HEADER.End_to_End_Projects"|translate}}
      </a>
      <a class="mobileMenuItem" (click)="closeMenu()" [routerLink]="['/services/supporting-services']"
         [fragment]="'uiux'">
        {{"HEADER.UI_UX"|translate}}
      </a>
      <a class="mobileMenuItem" (click)="closeMenu()" [routerLink]="['/services/supporting-services']"
        [fragment]="'qa'">
        {{"HEADER.QA"|translate}}
      </a>
    </div>

    <div class="seperatorLine" *ngIf="!menuState['company']"></div>
    <div class="mobileMenuItem hasChildren" (click)="openTopBar('company')"
         [ngClass]="{'active': menuState['company'] }">
      {{"HEADER.Company"|translate}}
    </div>
    <div class="mobileMenuSubItems" [ngClass]="{'showSubMenu': menuState['company']}">
      <a class="mobileMenuItem" [routerLink]="['/company/about-us']">
       {{"HEADER.About_us"|translate}}
      </a>
      <a class="mobileMenuItem" [routerLink]="['/company/why-global-teams']">
        {{"HEADER.Why_Global_Teams"|translate}}
      </a>
      <!--
      <a class="mobileMenuItem" [routerLink]="['/company/about-us']" fragment="ourleaders">
        {{"HEADER.Leadership"|translate}}
      </a>
      -->
      <!--      <a class="mobileMenuItem" [routerLink]="['/company/trusted-by']">-->
      <!--        Trusted by-->
      <!--      </a>-->
      <a class="mobileMenuItem" [routerLink]="['/company/why-east-central-europe']">
        {{"HEADER.Why_choose_East_Central_Europe"|translate}}
      </a>

      <a class="mobileMenuItem" [routerLink]="['/company/industries-we-serve']">
        {{"Industries_we_serve.TITLE"|translate}}
      </a>

      <a class="mobileMenuItem" [routerLink]="['/contact-us']">
        {{"HEADER.Contact_us"|translate}}
      </a>
      <div class="mobileMenuItem">

      </div>
    </div>

    <div class="mobileMenuItem" (click)="openBlog()">
      {{"HEADER.Blog"|translate}}
    </div>

    <div class="seperatorLine" *ngIf="!menuState['career']"></div>
    <div class="mobileMenuItem hasChildren" (click)="openTopBar('career')"
         [ngClass]="{'active': menuState['career'] }">
      {{"HEADER.Career"|translate}}
    </div>
    <div class="mobileMenuSubItems" [ngClass]="{'showSubMenu3': menuState['career']}">
      <!--      <a class="mobileMenuItem">-->
      <!--        Open Positions-->
      <!--      </a>-->
      <a class="mobileMenuItem" [routerLink]="['/career/working-at-global']">
      {{"HEADER.Working_at_GT"|translate}}
      </a>
      <a class="mobileMenuItem" [routerLink]="['/career/open-positions']">
      {{"HEADER.Open_Positions"|translate}}
      </a>
      <a class="mobileMenuItem pointer" (click)="openLetsTalk(true)">
      {{"HEADER.Send_your_CV"|translate}}
      </a>


    </div>

    <app-language-selector class="mobileMenuItem mobile-view"></app-language-selector>

    <div class="letsTalkButtonSmall letsTalkMobileButton" (click)="closeMenu();openLetsTalk(false)">
      <span>{{"HEADER.Lets_Talk"|translate}}</span></div>

    <!--    <div class="seperatorLine" *ngIf="!menuState['language']"></div>-->
    <!--    <div class="mobileMenuItem hasChildren" (click)="openTopBar('language')"-->
    <!--         [ngClass]="{'active': menuState['language'] }">-->
    <!--      Languages-->
    <!--    </div>-->
    <!--    <div class="mobileMenuSubItems" [ngClass]="{'showSubMenu3': menuState['language']}">-->
    <!--      <div class="mobileMenuItem">-->
    <!--        English-->
    <!--      </div>-->
    <!--      <div class="mobileMenuItem">-->
    <!--        Russian-->
    <!--      </div>-->
    <!--      <div class="mobileMenuItem">-->
    <!--        Ukrainian-->
    <!--      </div>-->
    <!--    </div>-->
    </div>
</div>


<ng-container *ngIf="letsTalkOpen">
  <app-contact-us [@inOutAnimation] (close)="closeLetsTalk()" [jobId]="jobId" [isCV]="isCV" class="contactLayer"
                  [isPopup]="true"></app-contact-us>
</ng-container>













