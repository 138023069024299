<div class="we-are-growing-container">
  <div class="map-container">
    <div *ngFor="let office of companyOffices"
      (click)="navigateToContactUs()"
      class="company-office {{office.className}}"
      [ngStyle]="{ 'top': office.top, 'left': office.left }">

      <div class="company-office-core"></div>

      <div class="company-office-on-hover-picture">
        <img [src]="office.officeImage" class="company-office-on-hover-picture__image"/>
      </div>

      <div class="company-office-on-hover-text">
        <div class="company-office-on-hover-country">
          {{office.country|translate}}
        </div>
        <div class="company-office-on-hover-city">
          {{office.city|translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="we-are-growing-text-container">
    <div class="we-are-growing-title">
      <div>
        {{"ABOUT_US.WE_ARE"|translate}}
      </div>
      <div>
        {{"ABOUT_US.GROWING"|translate}}
      </div>
    </div>

    <div class="we-are-growing-body">
      {{"ABOUT_US.Today_Global_Teams_has_expanded_to_include_over_150_experienced"|translate}}
    </div>

  </div>
</div>
