<div class="image-container" *ngIf="imgPath"
  [ngStyle]="{
    'background-image': 'url(' + imgPath + ')',
    'width': item.width,
    'height': item.height,
    'left': item.left,
    'top': item.top,
    'right': item.right,
    'animation-delay': item.animationDelay,
    'border-radius': item.borderRadius
  }">
</div>
