import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpenPositionFilterItem } from '../models/open-positions.model';

@Component({
  selector: 'app-open-position-filter',
  templateUrl: './open-position-filter.component.html',
  styleUrls: ['./open-position-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenPositionFilterComponent implements OnInit {

  @Input() filterItem: OpenPositionFilterItem;
  @Input() isFilterActive = true;
  @Output() filterClicked  = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit() {
  }

  onFilterClicked(tag: string) {
    this.filterClicked.emit(tag);
  }

}
