<app-dot class="green-10-10"></app-dot>
<app-dot class="blue-30-30"></app-dot>
<app-dot class="green-19-19-out"></app-dot>
<app-dot class="black-10-10-out desktop-only"></app-dot>

<div class="powered-by-people-container">
  <app-dot class="blue-25-25"></app-dot>
  <app-dot class="blue-19-19"></app-dot>
  <app-dot class="green-16-16 desktop-only"></app-dot>
  <app-dot class="green-19-19 desktop-only"></app-dot>
  <app-dot class="black-15-15"></app-dot>
  <app-dot class="black-10-10 desktop-only"></app-dot>

  <app-employee-profile-info
    *ngFor="let item of employees"
    [item]="item">
  </app-employee-profile-info>

  <div class="titlesBlock">
    <h1 class="preBuild">
      {{"INTRO.POWERED_BY"|translate}}
      <span class="secondLine">{{"INTRO.PEOPLE"|translate}}</span>
    </h1>

    <div class="elite-remote-staffing-solutions-container">
      <h2>
        {{"INTRO.ELITE_REMOTE_STAFFING_SOLUTIONS"|translate}}
      </h2>
      <h2>
        {{"INTRO.ELITE_REMOTE_STAFFING_SOLUTIONS_FROM"|translate}}
      </h2>
    </div>

    <div class="arrow-scroll-down" (click)="scrollToServices()">
      <img loading="lazy" src="assets/images/home/vector-strok.svg" />
    </div>
</div>
