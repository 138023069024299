<div class="container">

  <div class="title-container" *ngIf="!isLatestOpeningsView">
    <h1 class="title">
      <div class="find_your-label">
        {{"OPEN_POSITIONS.Find_your"|translate}}
        <div class="bubble-green"></div>
      </div>
      <div>
        {{"OPEN_POSITIONS.dream_job"|translate}}
      </div>
    </h1>
  </div>


  <div class="latest-openings-title" *ngIf="isLatestOpeningsView">
    {{ 'COMMON.Latest_Openings' | translate}}
  </div>


  <div class="open-positions-section" *ngIf="jobs$ | async as jobs">

    <div class="search-filters" *ngIf="!isLatestOpeningsView">
      <app-open-positions-search-filters [formGroup]="filtersFormGroup"
        (searchFiltersData)="onButtonSearchClicked($event)">
      </app-open-positions-search-filters>
    </div>

    <div class="filters-wrapper" *ngIf="!isLatestOpeningsView">
      <div class="filters__section">
        <div class="filters__popular-category desktop-only">
          {{"OPEN_POSITIONS.Popular_category"|translate}}
        </div>

        <div class="filters-container">
          <app-open-position-filter [filterItem]="filterItem" *ngFor="let filterItem of filters$ | async"
            (filterClicked)="onPopularCategoryClicked($event)" [isFilterActive]="activeCategoryFilters[filterItem.tag]">
          </app-open-position-filter>
        </div>
      </div>

      <div class="view-types-container desktop-only" (click)="changeJobsView()">
        <img class="column-filter" loading="lazy"
          [src]="!isRowView ? 'assets/svg/filter-column-active.svg' : 'assets/svg/filter-column.svg'" />
        <img loading="lazy" [src]="isRowView ? 'assets/svg/filter-row-active.svg' : 'assets/svg/filter-row.svg'" />
      </div>
    </div>

    <div class="open-positions-container">
      <ng-container>
        <ng-container *ngFor="let item of jobs | slice:0:(isLatestOpeningsView ? 5 : jobs?.length)">
          <app-job-item [job]="item" [isRowView]="isRowView" [routerLink]="'/career/view-position/' + item.id"
            [ngClass]="{'row-view-active': isRowView}">
          </app-job-item>
        </ng-container>

        <div class="no-data-to-display" *ngIf="jobs?.length === 0">
          {{"OPEN_POSITIONS.THERE_ARE_NO_VACANCIES_FOR_THE_SELECTED_FILTERS"|translate}}
        </div>

        <ng-container *ngIf="isLatestOpeningsView && (jobs?.length - 5 > 0)">
          <app-job-item [latestOpeningsViewShowCount]="jobs?.length - 5" [isRowView]="isRowView"
            [routerLink]="'/career/open-positions'" [ngClass]="{'row-view-active': isRowView}">
          </app-job-item>
        </ng-container>
      </ng-container>
    </div>

    <div class="role-that-fits" *ngIf="!isLatestOpeningsView">
      <div>
        <div class="role-that-fits__label">
          {{"OPEN_POSITIONS.Do_not_see_a_role_that_fits"|translate}}
        </div>

        <div class="role-that-fits__button">
          <app-button-default [buttonText]="'HEADER.Send_your_CV'" (buttonClicked)="onSendCvClicked()">
          </app-button-default>
        </div>
      </div>
    </div>

  </div>

</div>

<app-loader-spinner [isSpinnerVisible]="isSpinnerVisible | async">
</app-loader-spinner>
