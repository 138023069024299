import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-search',
  templateUrl: './button-search.component.html',
  styleUrls: ['./button-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonSearchComponent implements OnInit {

  @Input() isEmitOnEnterClick: boolean;
  @Output() buttonClicked = new EventEmitter<void>();

  constructor() { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isEmitOnEnterClick && event.key === "Enter") {
      this.onButtonClicked();
    }
  }

  ngOnInit() {
  }

  onButtonClicked() {
    this.buttonClicked.emit();
  }
}
