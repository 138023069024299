import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { JobOpenPosition } from '../models/open-positions.model';

@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobItemComponent implements OnInit {

  @Input() job: JobOpenPosition;
  @Input() isRowView: boolean;
  @Input() latestOpeningsViewShowCount?: number;

  constructor() { }

  ngOnInit(): void {
  }

}
