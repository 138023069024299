import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {UiServiceService} from '../../services/ui-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsPageComponent implements OnInit, AfterViewInit, OnDestroy {

  private sub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private uiService: UiServiceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 300);
  }

  ngAfterViewInit(): void {
    this.initializeData();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private initializeData() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.sub = this.activatedRoute.fragment.subscribe((fragment: string) => {
          if (fragment != null) {
            this.uiService.scroll(fragment, 1000, -60);
          }
        });
      }, 0);
    }
  }

}
