import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TheyTrustUsLogoInfo } from 'src/app/shared/models/common.model';
import { TheyTrustUsService } from '../../../services/they-trust-us.service'
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-they-trust-us',
  templateUrl: './they-trust-us.component.html',
  styleUrls: ['./they-trust-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TheyTrustUsComponent implements OnInit, OnDestroy {

  displayData: TheyTrustUsLogoInfo[] = [];

  private allClients: TheyTrustUsLogoInfo[];
  private intervalSubscriptions: Subscription[] = [];
  private configDisplayDataChanged: { [key: number]: number[] };

  constructor(
    private theyTrustUsService: TheyTrustUsService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.allClients = this.theyTrustUsService.getClients();
    this.displayData = this.allClients.splice(0, 6);
    this.configDisplayDataChanged = this.theyTrustUsService.getConfigDisplayDataChanged();

    this.initDisplayDataChanged();
  }

  ngOnDestroy(): void {
    this.intervalSubscriptions?.forEach(element => element?.unsubscribe());
  }

  private initDisplayDataChanged() {
    for (const intervalPeriod in this.configDisplayDataChanged) {
      if (isPlatformBrowser(this.platformId)) {
        const intervalSubscription = interval(Number(intervalPeriod)).subscribe(() => {
          this.changeLogo(this.configDisplayDataChanged[intervalPeriod]);
        });

        this.intervalSubscriptions.push(intervalSubscription);
      }
    }
  }

  private changeLogo(indexList: number[]) {

    for (const indexItem of indexList) {
      const item = { ...this.displayData[indexItem] };

      if (!item) {
        continue;
      }

      this.allClients.push(item);

      this.displayData[indexItem] = this.allClients.shift();
    }

    this.detectChanges();
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
}
