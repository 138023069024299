import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent implements OnInit {

  @Input() label = '';
  @Input() checked: UntypedFormControl;

  constructor() {
  }

  ngOnInit(): void {
    if (this.checked == null) {
      this.checked = new UntypedFormControl();
    }
  }

}
