import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { UiServiceService } from 'src/app/services/ui-service.service';
import { EmployeeProfileInfo } from 'src/app/shared/models/common.model';

@Component({

  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntroComponent implements OnInit, AfterViewInit {

  @Output() JumpTo: EventEmitter<any> = new EventEmitter<any>();
  public isMobile = false;
  employees: EmployeeProfileInfo[] = [];

  constructor(
    private employeesService: EmployeesService,
    private uiService: UiServiceService,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.resize();
    this.employees = this.employeesService.getHomeImg(this.isMobile);
  }

  ngAfterViewInit(): void {
    this.detectChanges();
  }

  scrollToContact(): void {
    this.JumpTo.emit('letstalk');

  }

  @HostListener('window:resize', ['$event'])
  resize(): void {
    const isMobile = window.innerWidth < 600 || (window.innerWidth / window.innerHeight >= 1.8 && window.innerWidth < 1000);
    if (isMobile !== this.isMobile ) {
      this.employees = this.employeesService.getHomeImg(this.isMobile);
    }

    this.isMobile = isMobile;

    this.employees = this.employeesService.getHomeImg(this.isMobile);

    this.detectChanges();
  }


  scrollToServices() {
    this.uiService.scroll("services",0,-120);
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

}
