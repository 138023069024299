import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {UiServiceService} from '../../../services/ui-service.service';
import {EmployeesService} from '../../../services/employees.service';
import {NgImageSliderComponent} from 'ng-image-slider';
import {ActivatedRoute} from '@angular/router';
import {animate, style, transition, trigger} from '@angular/animations';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('2s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('2s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class AboutUsComponent implements OnInit, OnDestroy {

  @ViewChild('nav') mySlider: NgImageSliderComponent;
  public videoPlaying = false;
  public webView = false;
  public lastScrollState = 0;
  public currentPage = 0;
  publiv;
  videoEnded = false;
  public pagesIds = ['page', 'atmosphere', 'contact'];

  public imageObject = [];
  private routerSubscription: Subscription;

  constructor(
    private employeesService: EmployeesService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private uiService: UiServiceService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.imageObject = this.employeesService.imageObject;

    if (isPlatformBrowser(this.platformId)) {
      this.webView = window.innerWidth > 1000;

      setTimeout(() => {
        this.routerSubscription = this.route.fragment.subscribe((fragment: string) => {
          if (fragment != null) {
            this.uiService.scroll(fragment, 1000, -60);
          } else {
            this.uiService.doScrolling(0, 300);
          }
        });
      }, 200);
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (document.getElementById('videoElement') == null) {
      return;
    }
    const videoItems: HTMLVideoElement = document.getElementById("videoElement") as HTMLVideoElement;
    const isMobile = window.innerWidth <= 800;

    if (!videoItems || isMobile) {
      return;
    }

    videoItems.muted = true;

    if (!this.videoPlaying && this.isInViewport()) {
      videoItems.play();
    } else {
      videoItems.pause();
    }
  }


  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.webView = window.innerWidth > 1000;
  }


  playVideo(): void {
    if (document.getElementById('videoElement') == null) {
      return;
    }
    const videoItem: HTMLVideoElement = document.getElementById('videoElement') as HTMLVideoElement;
    this.videoEnded = false;
    videoItem.play();
  }

  vidEnded(): void {
    this.videoEnded = true;
  }


  onPictureMove(item): void {
    //console.log(item);

  }

  isInViewport(): boolean {
    return this.uiService.isInViewport('videoElement');
  }


}
