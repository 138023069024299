<div class="ourleaders" id="page">

  <div class="title">
    {{"OUR_LEADERS.Our_Leaders"|translate}}
  </div>


  <div class="leaders">
    <div class="person">
      <div class="picture profile1"></div>
      <div class="profileName">Rinat Buchholz</div>
      <div class="profileProfession">{{"OUR_LEADERS.CEO"|translate}}</div>
      <div class="linkedIn-linkedAbs">
        <a href="https://www.linkedin.com/in/rinat-buchholz/" target="_blank">
          LinkedIn
        </a>
      </div>
    </div>



    <div class="person">
      <div class="picture profile2"></div>
      <div class="profileName">Dmytro Polyvianyi</div>
      <div class="profileProfession">{{"OUR_LEADERS.GM_Kyiv_Headquarters"|translate}}</div>
      <div class="linkedIn-linkedAbs">
        <a href="https://www.linkedin.com/in/rinat-buchholz/" target="_blank">
          LinkedIn
        </a>
      </div>
    </div>

    <div class="person">
      <div class="picture profile4"></div>
      <div class="profileName">Elena Vlasova</div>
      <div class="profileProfession">{{"OUR_LEADERS.Chief_Growth_Officer"|translate}}</div>
      <div class="linkedIn-linkedAbs">
        <a href="https://www.linkedin.com/in/elenavlasova/" target="_blank">
          LinkedIn
        </a>
      </div>
    </div>

    <div class="person">
      <div class="picture profile5"></div>
      <div class="profileName">Bruno Puka</div>
      <div class="profileProfession">{{"OUR_LEADERS.Finance_Manager"|translate}}</div>
      <div class="linkedIn-linkedAbs">
        <a href="https://www.linkedin.com/in/bruno-puka-6067a847/" target="_blank">
          LinkedIn
        </a>
      </div>
    </div>

  </div>

</div>
