import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UiServiceService } from 'src/app/services/ui-service.service';

@Component({
  selector: 'app-industries-we-serve',
  templateUrl: './industries-we-serve.component.html',
  styleUrls: ['./industries-we-serve.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndustriesWeServeComponent implements OnInit {

  textBody: string[] = [
    'Industries_we_serve.TOP_SECTION.content'
  ];

  isFintechVisible = false;
  isAutomotiveBlockVisible = false;
  isECommerceBlockVisible = false;
  isCybersecurityBlockVisible = false;
  isTelecommunicationsBlockVisible = false;


  constructor(
    private readonly uiService: UiServiceService
  ) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 50);
  }

  onInViewportEventFintechVisibleBlock(): void {
    this.isFintechVisible = true;
  }

  onInViewportEventAutomotiveBlock(): void {
    this.isAutomotiveBlockVisible = true;
  }

  onInViewportEventECommerceBlock(): void {
    this.isECommerceBlockVisible = true;
  }

  onInViewportEventCybersecurityBlock(): void {
    this.isCybersecurityBlockVisible = true;
  }

  onInViewportEventTelecommunicationsBlock(): void {
    this.isTelecommunicationsBlockVisible = true;
  }

}
