import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-home-personal-touch',
  templateUrl: './home-personal-touch.component.html',
  styleUrls: ['./home-personal-touch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePersonalTouchComponent {

}
