import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BenefitsItemData } from 'src/app/shared/models/common.model';
import { UiServiceService } from '../../../services/ui-service.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BenefitsComponent implements OnInit {

  benefitsList: BenefitsItemData[];

  constructor(
    private uiService: UiServiceService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  onInViewportEvent(): void {
    if (this.benefitsList) {
      return;
    }

    this.benefitsList = this.uiService.benefitsList;
    this.detectChanges();
  }

  private detectChanges(): void {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

}
