<app-services-main-base [title]="'Industries_we_serve.TOP_SECTION.title'" [textBody]="textBody">
  <app-dot class="managed-teams-65-65 desktop-only"></app-dot>
  <app-dot class="managed-teams-30-30"></app-dot>
  <app-dot class="industries-we-serve-95-95 desktop-only"></app-dot>
</app-services-main-base>


<div class="industries-we-serve-container">
  <div class="industries-we-serve__block Fintech-block" appInViewPort
    (inViewportEvent)="onInViewportEventFintechVisibleBlock()">
    <ng-container *ngIf="isFintechVisible">
      <div class="block-content industries-we-serve__block-content fadeInLeft-move">
        <div class="block-content__title">
          <h2>
            {{"Industries_we_serve.BLOCKS.Fintech.title"|translate}}
          </h2>
        </div>

        <div class="block-content__text">
          {{"Industries_we_serve.BLOCKS.Fintech.Business_owners_increasingly_turn_to_development_outstaffin"|translate}}
        </div>
      </div>

      <div class="block-image fadeInRight-move">
        <img loading="lazy" src="/assets/images/industries-we-serve/1.jpg" />
      </div>
    </ng-container>
  </div>


  <div class="industries-we-serve__block Automotive-block" appInViewPort
    (inViewportEvent)="onInViewportEventAutomotiveBlock()">
    <ng-container *ngIf="isAutomotiveBlockVisible">
      <div class="block-image fadeInLeft-move">
        <img loading="lazy" src="/assets/images/industries-we-serve/2.jpg" />
      </div>

      <div class="block-content fadeInRight-move">
        <div class="block-content__title">
          <h2>
            {{"Industries_we_serve.BLOCKS.Automotive.title"|translate}}
          </h2>
        </div>

        <div class="block-content__text">
          {{"Industries_we_serve.BLOCKS.Automotive.the_automotive_industry_is_evolving_and_technological"|translate}}

          <div class="Automotive-block-description-list-container">
            {{"Industries_we_serve.BLOCKS.Automotive.Skills_in_the_automotive_industry_include"|translate}}
            <ul>
              <li>{{'Industries_we_serve.BLOCKS.Automotive.Data_Analytics'| translate}}</li>
              <li>{{'Industries_we_serve.BLOCKS.Automotive.Artificial_intelligence'| translate}}</li>

              <li>{{'Industries_we_serve.BLOCKS.Automotive.Cybersecurity'| translate}}</li>
              <li>{{'Industries_we_serve.BLOCKS.Automotive.Electric_and_hybrid_vehicles'| translate}}</li>

              <li>{{'Industries_we_serve.BLOCKS.Automotive.Software_development'| translate}}</li>
              <li>{{'Industries_we_serve.BLOCKS.Automotive.Embedded_systems'| translate}}</li>
            </ul>
          </div>

          {{"Industries_we_serve.BLOCKS.Automotive.Our_IT_specialists_are_at_the_forefront_of_automotive_innovation"|translate}}
        </div>
      </div>
    </ng-container>
  </div>


  <div class="industries-we-serve__block e-commerce-block" appInViewPort
    (inViewportEvent)="onInViewportEventECommerceBlock()">
    <ng-container *ngIf="isECommerceBlockVisible">
      <div class="block-content fadeInLeft-move">
        <div class="block-content__title">
          <h2>
            {{"Industries_we_serve.BLOCKS.e_commerce.title"|translate}}
          </h2>
        </div>

        <div class="block-content__text">
          {{"Industries_we_serve.BLOCKS.e_commerce.The_e_commerce_industry_has_had_a_significant_impact"|translate}}

          <div class="Automotive-block-description-list-container">
            {{"Industries_we_serve.BLOCKS.e_commerce.Some_trends_driving_demand_for_e_commerce_recruits_include"|translate}}
            <ul>
              <li>
                {{'Industries_we_serve.BLOCKS.e_commerce.IT_specialists_in_the_field_of_artificial_intelligence_and_automation'|
                translate}}</li>
              <li>
                {{'Industries_we_serve.BLOCKS.e_commerce.Specialists_with_data_analysis_and_personalization_skills'|
                translate}}</li>
              <li>{{'Industries_we_serve.BLOCKS.e_commerce.AR_and_VR_IT_specialists'| translate}}
              </li>
            </ul>
          </div>

          {{"Industries_we_serve.BLOCKS.e_commerce.Outsourcing_e_commerce_services_can_be_a_beneficial_strategy_for_many_businesses"|translate}}
        </div>
      </div>

      <div class="block-image fadeInRight-move">
        <img loading="lazy" src="/assets/images/industries-we-serve/3.jpg" />
      </div>
    </ng-container>
  </div>


  <div class="industries-we-serve__block Cybersecurity-block" appInViewPort
    (inViewportEvent)="onInViewportEventCybersecurityBlock()">

    <ng-container *ngIf="isCybersecurityBlockVisible">
      <div class="block-image fadeInLeft-move">
        <img loading="lazy" src="/assets/images/industries-we-serve/4.jpg" />
      </div>

      <div class="block-content fadeInRight-move">
        <div class="block-content__title">
          <h2>
            {{"Industries_we_serve.BLOCKS.Cybersecurity.title"|translate}}
          </h2>
        </div>

        <div class="block-content__text">
          {{"Industries_we_serve.BLOCKS.Cybersecurity.Cyber_attacks_are_becoming_more_common_as_technology_advances"|translate}}
        </div>
      </div>

    </ng-container>
  </div>


  <div class="industries-we-serve__block Telecommunications-block" appInViewPort
    (inViewportEvent)="onInViewportEventTelecommunicationsBlock()">
    <ng-container *ngIf="isTelecommunicationsBlockVisible">
      <div class="block-content industries-we-serve__block-content fadeInLeft-move">
        <div class="block-content__title">
          <h2>
            {{"Industries_we_serve.BLOCKS.Telecommunications.title"|translate}}
          </h2>
        </div>

        <div class="block-content__text">
          {{"Industries_we_serve.BLOCKS.Telecommunications.The_telecom_industry_must_meet_the_demand"|translate}}
        </div>
      </div>

      <div class="block-image fadeInRight-move">
        <img loading="lazy" src="/assets/images/industries-we-serve/5.jpg" />
      </div>
    </ng-container>
  </div>
</div>
