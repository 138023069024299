import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-end-to-end-projects',
  templateUrl: './end-to-end-projects.component.html',
  styleUrls: ['./end-to-end-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EndToEndProjectsComponent implements OnInit {

  textBody: string[] = [
    'SUPPORTING_SERVICES.From_creating_a_new_app_to_building_a_large_RD_center',
    'SUPPORTING_SERVICES.We_assemble_a_team_of_expert_talent_with_all_the_skills_needed',
    'SUPPORTING_SERVICES.Our_professionals_possess_experience_in_diverse_industries'
  ];

  constructor(
    private uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
    ) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 300);
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

}
