
import { Directive, AfterViewInit, OnDestroy, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appInViewPort]'
})
export class InViewPortDirective implements AfterViewInit, OnDestroy {

  @Input() throttleTime = 100;

  @Output() inViewportEvent = new EventEmitter<void>();

  private scrollSubscription: Subscription;

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.windowScrollSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscribeEvent();
  }

  private unsubscribeEvent(): void {
    this.scrollSubscription?.unsubscribe();
  }

  private windowScrollSubscription(): void {
    this.scrollSubscription = fromEvent(window, 'scroll')
      .pipe(throttleTime(this.throttleTime))
      .subscribe(() => this.checkIsInViewport());
  }

  private checkIsInViewport(): void {
    const windowHeight = window.innerHeight;

    const boundedRect = this.elementRef.nativeElement.getBoundingClientRect();

    const isInViewport = boundedRect.top >= 0 && boundedRect.bottom <= windowHeight;

    if (isInViewport) {
      this.inViewportEvent.emit();
      this.unsubscribeEvent();
    }
  }
}
