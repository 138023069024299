import { Component, OnInit } from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-specialist-support',
  templateUrl: './specialist-support.component.html',
  styleUrls: ['./specialist-support.component.scss']
})
export class SpecialistSupportComponent implements OnInit {

  constructor(
    private updateDifferentStateService: UpdateDifferentStateService,
    private uiService: UiServiceService) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 300);
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }
}
