import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';
import { TestimonialsService } from './services/testimonials.service'
import { interval, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2s ease-out',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class TestimonialsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() teamsOrder = [];
  @Input() teamsRotating = false;
  @ViewChild('myCarouselB') carousel: NguCarousel<unknown>;

  public isMobile = false;
  public init = false;
  public selectedIndex = 0;
  public selectedItemNo = 0;
  private subscriptionInterval: Subscription;
  public teams = [];

  public carouselTiles: any = {
    grid: { xs: 1, sm: 1, md: 2, lg: 3, all: 0 },
    slide: 1,
    speed: 0,
    point: {
      visible: true
    },
    load: 2,
    touch: true,
    loop: true,
    velocity: 0,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  caroselLoaded(): void {
    this.init = true;
    this.resize();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private testimonialsService: TestimonialsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initIntervalMoveCarousel();
  }

  ngOnInit(): void {
    this.loadClients();

    this.resize();
    if (this.teamsOrder.length === 0) {
      this.teamsOrder = [0, 1, 2];
    }
    this.selectedIndex = this.teamsOrder[0];
    this.selectedItemNo = 0;
  }

  ngOnDestroy() {
    this.subscriptionInterval?.unsubscribe();
  }


  isCenterItem(data: NguCarouselStore, index: number) {
    if (this.isMobile) {
      return true;
    }

    const centerItemIndex = data.currentSlide + data.items - 1;

    return centerItemIndex === index + 1;
  }

  changeTestimonial(idx, itemId): void {
    if (this.teamsRotating) {
      this.selectedIndex = idx;
      this.selectedItemNo = itemId;

      this.cdr.detectChanges();
    }
  }

  @HostListener('window:resize', ['$event'])
  resize(): void {
    this.isMobile = window.innerWidth < 800;

    this.cdr.detectChanges();

  }

  mouseEnter(isCenterItem: boolean) {
    if (isCenterItem) {
      this.subscriptionInterval?.unsubscribe();
    }
  }

  mouseLeave(isCenterItem: boolean) {
    if (isCenterItem) {
      this.initIntervalMoveCarousel();
    }
  }

  private loadClients() {
    const homeCarouselSourceData = this.testimonialsService.getClients();

    this.teams = homeCarouselSourceData.concat(homeCarouselSourceData.slice(0, 2));
  }

  private initIntervalMoveCarousel() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionInterval = interval(6000).subscribe(() => {
        const slideNumber = this.carousel.isLast ? 0 : this.carousel.currentSlide + 1;

        this.carousel.moveTo(slideNumber, true);
      });
    }
  }

}
