import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';

@Component({
  selector: 'app-services-main-base',
  templateUrl: './services-main-base.component.html',
  styleUrls: ['./services-main-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesMainBaseComponent implements OnInit {

  @Input() title: string;
  @Input() textBody: string[] = [];

  constructor(private updateDifferentStateService: UpdateDifferentStateService) { }

  ngOnInit() {
  }

  onButtonClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

}
