<div class="what-are-dedicated-teams-container">
  <div class="what-are-dedicated-teams-left-block">
    <h2>
      {{'SUPPORTING_SERVICES.What_Are_Dedicated_Teams'| translate}}
    </h2>
  </div>

  <div class="what-are-dedicated-teams-right-block">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'SUPPORTING_SERVICES.What_is_a_Dedicated_software_development_team'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-panel-hidden-part">
          {{'SUPPORTING_SERVICES.Businesses_and_startups_need_specialized_development_knowledge_to_stay_competitive'|
          translate}}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'SUPPORTING_SERVICES.What_is_the_advantage_of_project_dedicated_teams'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-panel-hidden-part">
          {{'SUPPORTING_SERVICES.Business_process_outsourcing_is_considered_an_essential_factor_for_success_in_nearly_every_industry'|
          translate}}
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{'SUPPORTING_SERVICES.When_is_the_dedicated_team_model_ideal_for_your_project'| translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="expansion-panel-hidden-part">
          {{'SUPPORTING_SERVICES.In_most_cases_outsourcing_a_dedicated_team'| translate}}

          <div class="What_is_the_advantage_of_project_dedicated_teams-list">
            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.Launch_of_a_new_product'| translate}}
            </div>

            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.Long_term_projects'| translate}}
            </div>

            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.Scaling'| translate}}
            </div>

            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.Projects_with_changing_requirements'| translate}}
            </div>

            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.If_your_project_requires_specific_skills_or_experience'| translate}}
            </div>

            <div class="What_is_the_advantage_of_project_dedicated_teams-list-item">
              {{'SUPPORTING_SERVICES.Projects_with_tight_deadlines'| translate}}
            </div>
          </div>
        </div>
      </mat-expansion-panel>

    </mat-accordion>
  </div>

  <app-dot class="managed-teams-what-are-dedicated-teams-280-280 desktop-only"></app-dot>
  <app-dot class="managed-teams-what-are-dedicated-teams-70-70 desktop-only"></app-dot>
</div>
