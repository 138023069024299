<div class="container">

  <app-dot class="footer-green-16-16 desktop-only"></app-dot>
  <app-dot class="footer-green-30-30"></app-dot>
  <app-dot class="footer-white-right-12-12"></app-dot>

  <div class="footerArea" [ngClass]="{noFormMode: !formMode}">

    <div class="footerWrapper">

      <div class="footerLinks">
        <div class="footerBlock-routes">
          <div class="footerBlock">
            <div class="title">{{"HEADER.Home"|translate}}</div>
            <div class="footerLink" [routerLink]="['/']" fragment="services">{{"HEADER.Services"|translate}}</div>
            <div class="footerLink" [routerLink]="['/']" fragment="benefits">{{"FOOTER.Why_choose_Ukraine"|translate}}</div>
            <div class="footerLink" [routerLink]="['/']" fragment="success">{{"FOOTER.Your_success_is_our_KPI"|translate}}</div>
            <div class="footerLink" [routerLink]="['/']" fragment="testimonials">{{"FOOTER.Clients_share"|translate}}</div>


          </div>
          <div class="footerBlock">
            <div class="title">{{"HEADER.Services"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/services/extended-teams']">{{"HEADER.Extended_Teams"|translate}}</div>

            <div class="footerLink"  [routerLink]="['/services/managed-teams']">{{"HEADER.Managed_Teams"|translate}}</div>

            <div class="footerLink"  [routerLink]="['/services/supporting-services']" [fragment]="'specialistsupport'">
              {{"HEADER.Specialist_Support"|translate}}
            </div>

            <div class="footerLink"  [routerLink]="['/services/end-to-end-projects']">{{"HEADER.End_to_End_Projects"|translate}}</div>

            <div class="footerLink"  [routerLink]="['/services/supporting-services']" [fragment]="'uiux'">{{"HEADER.UI_UX"|translate}}</div>

            <div class="footerLink"  [routerLink]="['/services/supporting-services']" [fragment]="'qa'">
              {{"HEADER.QA"|translate}}
            </div>

          </div>
          <div class="footerBlock">
            <div class="title">{{"HEADER.Company"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/company/about-us']">{{"HEADER.About_us"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/company/why-global-teams']">{{"HEADER.Why_Global_Teams"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/company/about-us']" fragment="ourleaders">{{"FOOTER.Our_Leaders"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/contact-us']">{{"HEADER.Contact_us"|translate}}</div>

          </div>
          <div class="footerBlock">
            <div class="title">{{"HEADER.Career"|translate}}</div>
                      <div class="footerLink"  [routerLink]="['/career/open-positions']">{{"HEADER.Open_Positions"|translate}}</div>
            <div class="footerLink"  [routerLink]="['/career/working-at-global']">{{"HEADER.Working_at_GT"|translate}}</div>
            <div class="footerLink" (click)="openLetsTalk(true)">{{"HEADER.Send_your_CV"|translate}}</div>
          </div>

          <div class="footerBlock">
            <div class="title">{{"HEADER.Blog"|translate}}</div>
              <div class="footerLink" (click)="openBlog()">{{"HEADER.All_Posts"|translate}}</div>
          </div>

          <div class="footerBlock">
            <div class="title">{{"HEADER.Contact_us"|translate}}</div>
              <div class="footerLink" (click)="openMailto()">
                info@globalteams.ltd
              </div>

              <div class="footerLink">
                <div class="iconsWrapper">
                  <a  href="https://www.linkedin.com/company/global-teams-europe" target="_blank">
                    <div class="linkedIn pointer" target="_blank"></div>
                  </a>

                  <a  href="https://www.instagram.com/globalteams.ltd" target="_blank">
                    <div class="insta pointer" target="_blank"></div>
                  </a>

                  <a href="https://www.facebook.com/Global-Teams-101113049274779" target="_blank">
                    <div class="facebookSvg pointer"></div>
                  </a>
                </div>
              </div>

              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>

              <div class="footerLink copyright-container">
                <div class="globalCopyright">
                  {{"FOOTER.Global_Teams_Copyright"|translate}}
                  {{2020}}
                </div>

                <div class="link termsLink" (click)="openPrivacy()">
                  {{"FOOTER.Terms_Of_Use_and_Privacy_Policy"|translate}}
                </div>
              </div>

          </div>
        </div>

      </div>

      <div class="copyright-socialNetwork-container">
        <div class="socialNetwork-container">
          <app-dot class="footer-green-center-17-17"></app-dot>

          <div class="circle-socialNetwork">
            <app-dot class="footer-blue-left-25-25"></app-dot>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div class="footerBottom-container">
    <div class="footerBottom">
      <div class="globalTeamsIconWhite"></div>

      <div class="globalCopyright">
        {{"COMMON.GLOBAL_TEAMS_LOWER"|translate}}
      </div>

      <div class="globalCopyright-separator">-</div>

      <div class="termsLink">
        {{"INTRO.ELITE_REMOTE_STAFFING_SOLUTIONS_FULL_TEXT"|translate}}
      </div>
    </div>

    <div class="arrow-up-container">
      <div class="arrow-up-circle" (click)="scrollTop()">
        <img src="/assets/svg/home-arrow-up.svg" alt="up">
      </div>
    </div>
  </div>

</div>

