<div class="main-section">
  <div class="left-section">

    <h1 class="title">
      {{"WORKING_AT_GLOBAL.WORKING_AT"|translate}}<br />
      {{"ABOUT_US.Global_Teams"|translate}}
    </h1>

    <div class="content">
      <div>
        {{"WORKING_AT_GLOBAL.content_main_text"|translate}}
      </div>

      <div>
        {{"WORKING_AT_GLOBAL.We_provide_everything_you_need"|translate}}
        {{"WORKING_AT_GLOBAL.As_part_of_our_commitment_to_our_people"|translate}}
      </div>

      <div>
        {{"WORKING_AT_GLOBAL.Our_well_appointed_work_stations"|translate}}
      </div>
    </div>

    <div clas="join-us-section">
      <app-button-default [buttonText]="'COMMON.Join_Us'" (buttonClicked)="joinUsClicked()">
      </app-button-default>
    </div>

  </div>


  <div class="working-point">
    <app-dot class="working-at-global-teams-28-28 desktop-only"></app-dot>
    <app-dot class="working-at-global-teams-70-70 desktop-only"></app-dot>
    <app-dot class="working-at-global-teams-21-21 desktop-only"></app-dot>

    <app-circle-with-text class="working-at-global-health-and-wellness"
      [title]="'WORKING_AT_GLOBAL.Health_and_Wellness'" [bodyText]="'WORKING_AT_GLOBAL.Health_and_Wellness_content'">
    </app-circle-with-text>

    <app-circle-with-text class="working-at-global-company-social-events"
      [title]="'WORKING_AT_GLOBAL.Company_Social_Events'"
      [bodyText]="'WORKING_AT_GLOBAL.Company_Social_Events_content'">
    </app-circle-with-text>

    <app-circle-with-text class="working-at-global-professional-development"
      [title]="'WORKING_AT_GLOBAL.Professional_Development'"
      [bodyText]="'WORKING_AT_GLOBAL.Professional_Development_content'">
    </app-circle-with-text>
  </div>

</div>

<div class="people-work-better-section" *ngIf="allImagesList">
  <div class="people-work-better-section__title">
    {{"WORKING_AT_GLOBAL.We_believe_that_happy_people_work_better"|translate}}
  </div>

  <div class="people-work-better-section__content">
    {{"WORKING_AT_GLOBAL.We_believe_that_happy_people_work_better_main_text"|translate}}
  </div>

  <div class="people-work-better-section__images-container">

    <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="allImagesList" class="slider">
      <ngu-tile *nguCarouselDef="let item; let i = index">

        <div class="people-work-better-section__images">

          <div class="people-work-better-section__images__left">
            <div class="section__images__column--top">
              <app-ng-image-slider-custom [itemObject]="item.leftSection.topImage" [width]="'476px'" [height]="'314px'">
              </app-ng-image-slider-custom>
            </div>

            <div class="section__images__column--bottom">
              <app-ng-image-slider-custom [itemObject]="item.leftSection.bottomImage" [width]="'476px'"
                [height]="'400px'">
              </app-ng-image-slider-custom>
            </div>
          </div>


          <div class="people-work-better-section__images__center">
            <div class="section__images__column--top">
              <app-ng-image-slider-custom [itemObject]="item.centerSection.topImage" [width]="'480px'"
                [height]="'500px'">
              </app-ng-image-slider-custom>
            </div>

            <div class="section__images__column--bottom">
              <app-ng-image-slider-custom [itemObject]="item.centerSection.bottomImage" [width]="'480px'"
                [height]="'213px'">
              </app-ng-image-slider-custom>
            </div>
          </div>


          <div class="people-work-better-section__images__right">
            <div class="section__images__column--top">
              <app-ng-image-slider-custom [itemObject]="item.rightSection.topImage" [width]="'476px'" [height]="'314px'">
              </app-ng-image-slider-custom>
            </div>

            <div class="section__images__column--bottom">
              <app-ng-image-slider-custom [itemObject]="item.rightSection.bottomImage" [width]="'476px'"
                [height]="'400px'">
              </app-ng-image-slider-custom>
            </div>
          </div>
        </div>
      </ngu-tile>

      <ul class="myPoint-mobile bullets" NguCarouselPoint>
        <li *ngFor="let j of myCarousel.pointNumbers; let index = index" class="bullet"
          [class.selected]="index==myCarousel.activePoint" (click)="myCarousel.moveTo(index)"></li>
      </ul>
    </ngu-carousel>

  </div>

</div>


<app-open-positions [isLatestOpeningsView]="true">
</app-open-positions>
