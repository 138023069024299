<div class="language-selector-container" (click)="toggleSelector()" *ngIf="selectedLanguage">
  <div class="languageSelector selected-language">
      <div class="lang-flag">
        <img [src]="selectedLanguage.iconPath" />
      </div>

      <div class="lang-title">
        {{selectedLanguage.translationKey}}
      </div>
  </div>


  <div class="lang-list" [hidden]="!isOpen" (mouseleave)="toggleSelector()">

    <ng-container *ngFor="let item of availableLanguages">
      <div class="lang-item" (click)="setLanguage(item)" *ngIf="item.alpha2 !== selectedLanguage.alpha2">
        <div class="lang-flag">
          <img [src]="item.iconPath" />
        </div>

        <div class="lang-title">
          {{item.translationKey}}
        </div>

      </div>
    </ng-container>

  </div>

</div>
