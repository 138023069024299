<div class="formBg" *ngIf="!sent" [ngClass]="{'basicForm': basicForm}">

  <form [formGroup]="formGroup">

    <div class="contactWrapper" [ngClass]="{'basicForm': basicForm, 'isProcessing': this.isProcessing , 'isPopup': isPopup, 'isHome': isHome}">

      <div class="crossIcon" (click)="closePopup()" style="position: fixed;right:90px;top:60px;"></div>

      <ng-container *ngIf="isCV">
        <h1 class="popupTitle">
          {{"CONTACT_US.SEND_YOUR"|translate}}
          {{"CONTACT_US.SEND_YOUR_CV"|translate}}
        </h1>
        <span class="hrDepartment">
        {{"CONTACT_US.SEND_US_YOUR_CV_BY_THIS_FORM"|translate}}
        - jobs@globalteams.com.ua</span>
      </ng-container>

      <ng-container *ngIf="!isCV">
        <h1 class="popupTitle">{{"HEADER.Lets_Talk"|translate}}</h1>
        <h2 class="popupSubtitle">{{"CONTACT_US.JUST_ENTER_YOUR_DETAILS"|translate}}</h2>
        <h1 class="title">
          {{"CONTACT_US.CONTACT"|translate}}
          <span>{{"CONTACT_US.CONTANT_US"|translate}} </span>
        </h1>
      </ng-container>


      <div class="contactElement"
           [ngClass]="{'invalidField': formGroup.controls['name'].invalid && (formGroup.controls['name'].dirty || showErrors)}">
        <input type="text" [placeholder]="'CONTACT_US.NAME'|translate" [formControl]="formGroup.controls['name']"/>
        <div class="error">{{"COMMON.THIS_FIELD_IS_REQUIRED"|translate}}</div>
      </div>
      <div class="contactElement"
           [ngClass]="{'invalidField': formGroup.controls['email'].invalid && (formGroup.controls['email'].dirty || showErrors)}">
        <input type="text" [placeholder]="'CONTACT_US.EMAIL'|translate" [formControl]="formGroup.controls['email']"/>
        <div class="error">{{"COMMON.EMAIL_IS_REQUIRED"|translate}}</div>
      </div>
      <div class="contactElement" *ngIf="!isCV"
           [ngClass]="{'invalidField': formGroup.controls['company'].invalid && (formGroup.controls['company'].dirty || showErrors)}">
        <input type="text" [placeholder]="'CONTACT_US.Company'|translate" [formControl]="formGroup.controls['company']"/>
        <div class="error">{{"COMMON.THIS_FIELD_IS_REQUIRED"|translate}}</div>
      </div>

      <div class="contactElement" *ngIf="isCV">
        <input type="text" [placeholder]="'CONTACT_US.Phone'|translate" [formControl]="formGroup.controls['phone']"/>
      </div>

      <div class="contactElement country-selector-container"
           [ngClass]="{'invalidField': formGroup.controls['country'].invalid && (formGroup.controls['country'].dirty || showErrors)}">
        <select type="text" [placeholder]="'CONTACT_US.Country'|translate" [ngClass]="{'white':basicForm}"
                [formControl]="formGroup.controls['country']">
          <option value="null">{{"CONTACT_US.Country"|translate}}</option>
          <option *ngFor="let item of countries" [value]="item.code">{{item.name}}</option>
        </select>
        <div class="error">{{"COMMON.THIS_FIELD_IS_REQUIRED"|translate}}</div>
      </div>
      <div class="contactElement messageSection"
           [ngClass]="{'invalidField': formGroup.controls['message'].invalid && (formGroup.controls['message'].dirty || showErrors)}">
        <input type="text" [placeholder]="'CONTACT_US.Message'|translate" [formControl]="formGroup.controls['message']"/>
        <div class="error error-message-input">{{"COMMON.THIS_FIELD_IS_REQUIRED"|translate}}</div>
      </div>

      <div class="contactElement fileUploadSection">

        <input type="button" class="fileUploadInput" [ngClass]="{'white':basicForm}" value="Upload file"
               (click)="uploadElement.click()"/>
        <input #uploadElement ng2FileSelect [uploader]="cvUploader" style="display:none;" type="file"
               [placeholder]="'CONTACT_US.Upload_file'|translate" value="Upload file"/>
        <div class="fileUploadLabel">
          (
            {{"CONTACT_US.File_requirements"|translate}}
            : pdf, doc, docx, rtf, ppt, pptx
          )
        </div>
      </div>

      <div class="attachmentFile" *ngIf="fileName">
        <div class="fileName-label">
          {{fileName}}
        </div>

        <div (click)="clearFile();" class="crossIcon"></div>
      </div>


      <div class="content">
        {{"CONTACT_US.BY_SUBMITTING_COMPLETED_CONTENT.BY_SUBMITTING_COMPLETED_CONTACT_US_FORM"|translate}}
        {{"CONTACT_US.BY_SUBMITTING_COMPLETED_CONTENT.PLEASE_READ_OUR_PRIVACY_NOTICE_FOR_MORE_INFORMATION"|translate}}
        {{"CONTACT_US.BY_SUBMITTING_COMPLETED_CONTENT.IF_YOU_HAVE_ANY_QUESTIONS_REGARDING_YOUR_RIGHTS"|translate}}
      </div>

      <div class="checkboxesContainer">
        <div class="checkItem1" [ngClass]="{'invalidField': agreeToTerms.value != true && this.showErrors}">

          <app-checkbox ngDefaultControl [checked]="agreeToTerms"
                        [label]="'CONTACT_US.I_AM_INFORMED_ABOUT_THE_PROCESSING_OF_MY_PERSONAL_DATA'|translate"></app-checkbox>
        </div>
        <div class="checkItem2" [ngClass]="{'invalidField': agreeToBeAdded.value != true && this.showErrors}">

          <app-checkbox ngDefaultControl [checked]="agreeToBeAdded"
                        [label]="'CONTACT_US.I_AGREE_TO_BE_INCLUDED_INTO_GLOBAL_TEAMS_SYSTEMS'|translate"></app-checkbox>
        </div>
      </div>
      <div class="submitButton" (click)="submitForm();">
      {{"CONTACT_US.SEND_MESSAGE"|translate}}
      </div>

      <div class="loader" style="z-index: 99999;position: absolute" *ngIf="isProcessing"></div>
    </div>
  </form>


</div>

<div class="thankYou" *ngIf="sent" [ngClass]="{'isPopup': isPopup}">
  <div class="wrapper">
  <div class="crossIcon" (click)="closePopup()"></div>

  <svg width="119" height="80" viewBox="0 0 119 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110.957 0H21.6812C17.3978 0 13.913 3.48475 13.913 7.76812V24.3478H1.73913C0.778667 24.3478 0 25.1263 0 26.087C0 27.0477 0.778667 27.8261 1.73913 27.8261H22.6087C23.5692 27.8261 24.3478 27.0477 24.3478 26.087C24.3478 25.1263 23.5692 24.3478 22.6087 24.3478H17.3913V7.76812C17.3913 7.22504 17.4968 6.70701 17.6819 6.22841L51.4535 40L17.6819 73.7716C17.4968 73.293 17.3913 72.775 17.3913 72.2319V60.8696C17.3913 59.9089 16.6126 59.1304 15.6522 59.1304C14.6917 59.1304 13.913 59.9089 13.913 60.8696V72.2319C13.913 76.5153 17.3978 80 21.6812 80H110.957C115.24 80 118.725 76.5153 118.725 72.2319V7.76812C118.725 3.48475 115.24 0 110.957 0ZM81.1842 40L114.956 6.22841C115.141 6.70701 115.246 7.22504 115.246 7.76812V72.2319C115.246 72.775 115.141 73.293 114.956 73.7716L81.1842 40ZM110.957 3.47826C111.5 3.47826 112.018 3.58377 112.496 3.76881L73.6974 42.5677C69.629 46.6365 63.0089 46.6365 58.9403 42.5677L20.1415 3.76881C20.6201 3.58377 21.1381 3.47826 21.6812 3.47826H110.957ZM21.6812 76.5217C21.1381 76.5217 20.6201 76.4162 20.1415 76.2312L53.913 42.4596L56.4809 45.0275C59.1933 47.7396 62.7562 49.0959 66.3191 49.0959C69.882 49.0959 73.4446 47.7396 76.157 45.0275L78.7249 42.4596L112.496 76.2312C112.018 76.4162 111.5 76.5217 110.957 76.5217H21.6812Z" fill="url(#paint0_linear)"/>
    <path d="M15.6522 31.3041C14.6917 31.3041 13.913 32.0826 13.913 33.0433V38.2607H1.73913C0.778666 38.2607 0 39.0391 0 39.9998C0 40.9605 0.778666 41.7389 1.73913 41.7389H29.5652C30.5257 41.7389 31.3043 40.9605 31.3043 39.9998C31.3043 39.0391 30.5257 38.2607 29.5652 38.2607H17.3913V33.0433C17.3913 32.0826 16.6126 31.3041 15.6522 31.3041Z" fill="url(#paint1_linear)"/>
    <path d="M22.6087 55.6521C23.5692 55.6521 24.3478 54.8737 24.3478 53.913C24.3478 52.9523 23.5692 52.1739 22.6087 52.1739H17.3913V46.9565C17.3913 45.9958 16.6126 45.2173 15.6522 45.2173C14.6917 45.2173 13.913 45.9958 13.913 46.9565V52.1739H1.73913C0.778666 52.1739 0 52.9523 0 53.913C0 54.8737 0.778666 55.6521 1.73913 55.6521H22.6087Z" fill="url(#paint2_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="87.0993" y1="104.518" x2="3.78469" y2="-2.9345" gradientUnits="userSpaceOnUse">
        <stop stop-color="#77FFD2"/>
        <stop offset="0.48" stop-color="#6297DB"/>
        <stop offset="0.576" stop-color="#5D9EDE"/>
        <stop offset="0.7125" stop-color="#4EB0E6"/>
        <stop offset="0.8727" stop-color="#35CFF3"/>
        <stop offset="0.9988" stop-color="#1EECFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear" x1="22.9656" y1="44.9369" x2="15.4619" y2="25.3736" gradientUnits="userSpaceOnUse">
        <stop stop-color="#77FFD2"/>
        <stop offset="0.48" stop-color="#6297DB"/>
        <stop offset="0.576" stop-color="#5D9EDE"/>
        <stop offset="0.7125" stop-color="#4EB0E6"/>
        <stop offset="0.8727" stop-color="#35CFF3"/>
        <stop offset="0.9988" stop-color="#1EECFF"/>
      </linearGradient>
      <linearGradient id="paint2_linear" x1="17.8622" y1="58.8501" x2="8.96009" y2="40.7987" gradientUnits="userSpaceOnUse">
        <stop stop-color="#77FFD2"/>
        <stop offset="0.48" stop-color="#6297DB"/>
        <stop offset="0.576" stop-color="#5D9EDE"/>
        <stop offset="0.7125" stop-color="#4EB0E6"/>
        <stop offset="0.8727" stop-color="#35CFF3"/>
        <stop offset="0.9988" stop-color="#1EECFF"/>
      </linearGradient>
    </defs>
  </svg>


  <h1>{{"CONTACT_US.THANK_YOU_WE_RECEIVED_YOUR_EMAIL"|translate}}</h1>
  <h2>{{subTitle}}</h2>

  <div class="goToHomeButton" (click)="closePopup()" [routerLink]="'/'">
    {{"CONTACT_US.GO_TO_HOMEPAGE"|translate}}
  </div>
  </div>
</div>
