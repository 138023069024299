export class EmployeeProfileInfo {
  public imgPath: string;
  public animationDelay: string;
  public width: string;
  public height: string;
  public top: string;
  public left?: string;
  public right?: string;
  public borderRadius?: string;
  public nextImgPath?: string;
  public interval?: number;
}

export class TeamProfileInfo {
  public imgPath?: string;
  public hoverImgPath?: string;
  public fullName?: string;
  public fullNameShort?: string;
  public position?: string;
  public hoverBackgroundColor?: string;
  public hoverTextColor?: string;
  public isByDefaultVisibleText?: boolean;
}

export interface CarouselSourceData {
  imgPath: string;
  text: string;
  hoverText: string[];
  route: string;
  routeFragment?: string;
};

export interface ServisesHomePageData {
  data: ServisesHomePageDataConfig[];
  route?: string;
  routeFragment?: string;
};

export interface ServisesHomePageDataConfig {
  title?: string;
  text: string;
  isBlockElement?: boolean;
  route?: string;
  routeFragment?: string;
  additionalClass?: string;
};

export interface BenefitsItemCircleItemData {
  backgroundColor: string;
  width: string;
  height: string;
  top: string;
  left?: string;
  delay: string;
  animationName: string;
}

export interface BenefitsItemCircleItemTextData {
  top: string;
  left: string;
  text: string;
}
export interface BenefitsItemData {
  subtitle: string;
  content: string;
  backgroundColor: string;
  subtitleMarginLeft?: string;
  circleItem?: BenefitsItemCircleItemData;
  circleItemText?: BenefitsItemCircleItemTextData;
}

export class OpenLetsTalkEventDto {
  isCV: boolean;
  jobId: number;
}

export class TheyTrustUsLogoInfo {
  width: string;
  height: string;
  imgPath: string;
  paddingTop?: string;
}

export class RouterData {
  public title: string;
  public metaDescriptionTag: string;
}

export class KeyValuePair {
  public key: string;
  public value: string | number;
}
