<div class="home-lets-talk-container">
  <app-dot class="dot-left-bottom desktop-only"></app-dot>

  <div class="home-lets-talk__title">
    {{'HOME_LETS_TALK.TITLE'| translate}}
  </div>

  <div class="home-lets-talk__description">
    {{description| translate}}
  </div>

  <div class="home-lets-talk__footer">
    <app-button-default [buttonText]="'HEADER.Contact_us'" routerLink="/contact-us">
    </app-button-default>
  </div>

  <app-dot class="dot-right-center desktop-only"></app-dot>
  <app-dot class="dot-right-bottom desktop-only"></app-dot>
</div>
