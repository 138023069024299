import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {

  private readonly clients = [
    {
      author: 'TESTIMONIALS.Ox_Gaming.author',
      position: "TESTIMONIALS.Ox_Gaming.position",
      testimonial: 'TESTIMONIALS.Ox_Gaming.testimonial',
      picture: 'oxgaming-mgr.jpg',
      logo: 'klips.svg',
      logoGray: 'klips.svg',
      extra: 'TESTIMONIALS.Ox_Gaming.extra'
    },
    {
      author: 'TESTIMONIALS.AvaTrade.author',
      position: "TESTIMONIALS.AvaTrade.position",
      testimonial: 'TESTIMONIALS.AvaTrade.testimonial',
      picture: 'yaron.jpg',
      logo: 'Avapatner.svg',
      logoGray: 'Avapatner.svg',
      extra: 'TESTIMONIALS.AvaTrade.extra'
    },
    {
      author: 'TESTIMONIALS.Bulls_Media.author',
      position: "TESTIMONIALS.Bulls_Media.position",
      testimonial: 'TESTIMONIALS.Bulls_Media.testimonial',
      logo: 'Buls_white.svg',
      logoGray: 'Buls_white.svg',
      extra: 'TESTIMONIALS.Bulls_Media.extra'
    },
    {
      author: 'TESTIMONIALS.Smart_Gravity.author',
      position: "TESTIMONIALS.Smart_Gravity.position",
      testimonial: 'TESTIMONIALS.Smart_Gravity.testimonial',
      logo: 'Smart_white.svg',
      logoGray: 'Smart_white.svg',
      extra: 'TESTIMONIALS.Smart_white.extra'
    },
    {
      author: 'TESTIMONIALS.Smart_Bid.author',
      position: "TESTIMONIALS.Smart_Bid.position",
      testimonial: 'TESTIMONIALS.Smart_Bid.testimonial',
      picture: 'nir-smartbid.jpg',
      logo: 'smartbid.svg',
      logoGray: 'smartbid.svg',
      extra: 'TESTIMONIALS.Smart_Bid.extra'
    },
    {
      author: 'TESTIMONIALS.Ox_Gaming.author',
      position: "TESTIMONIALS.Ox_Gaming.position",
      testimonial: 'TESTIMONIALS.Ox_Gaming.testimonial',
      picture: 'oxgaming-mgr.jpg',
      logo: 'klips.svg',
      logoGray: 'klips.svg',
      extra: 'TESTIMONIALS.Ox_Gaming.extra'
    },
    {
      author: 'TESTIMONIALS.AvaTrade.author',
      position: "TESTIMONIALS.AvaTrade.position",
      testimonial: 'TESTIMONIALS.AvaTrade.testimonial',
      picture: 'yaron.jpg',
      logo: 'Avapatner.svg',
      logoGray: 'Avapatner.svg',
      extra: 'TESTIMONIALS.AvaTrade.extra'
    },
    {
      author: 'TESTIMONIALS.Smart_Bid.author',
      position: "TESTIMONIALS.Smart_Bid.position",
      testimonial: 'TESTIMONIALS.Smart_Bid.testimonial',
      picture: 'nir-smartbid.jpg',
      logo: 'smartbid.svg',
      logoGray: 'smartbid.svg',
      extra: 'TESTIMONIALS.Smart_Bid.extra'
    },
    {
      author: 'TESTIMONIALS.AvaTrade.author',
      position: "TESTIMONIALS.AvaTrade.position",
      testimonial: 'TESTIMONIALS.AvaTrade.testimonial',
      picture: 'yaron.jpg',
      logo: 'Avapatner.svg',
      logoGray: 'Avapatner.svg',
      extra: 'TESTIMONIALS.AvaTrade.extra'
    },
  ];

  constructor() { }


  getClients() {
    return this.clients;
  }
}
