import {AfterViewInit, Component, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {UiServiceService} from '../../services/ui-service.service';
import {ActivatedRoute} from '@angular/router';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

declare global {
  interface Window {
    onWheelDetect: any;
  }
}
/*
window.animatedScrolling = false;
window.onWheelDetect = (e) => {
  if (window.animatedScrolling) {
    e.preventDefault();
    return false;
  }
  return true;
};
*/

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.5s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class HomeComponent implements OnDestroy, AfterViewInit {

  @ViewChild('header') header;
  public lastScrollState = 0;
  public currentPage = 0;
  public pagesIds = ['home', 'success', 'benefits', 'testimonials', 'footer'];
  public letsTalkOpen = false;
  public scrollingTimeout = null;
  public isDark = false;
  //private isLoadingCompleted: boolean;
  private routerSubscription: Subscription;

  constructor(public dialog: MatDialog,
              private updateDifferentStateService: UpdateDifferentStateService,
              private uiService: UiServiceService,
              private route: ActivatedRoute) {
    window.addEventListener('wheel', window.onWheelDetect, {passive: false});
    this.uiService.doScrolling(0, 100);
  }


  ngAfterViewInit() {
    this.checkScrollPosition();


    //setTimeout(() => {
      this.routerSubscription = this.route.fragment.subscribe((fragment: string) => {
        if (fragment != null) {
          this.uiService.scroll(fragment, 1000, -100);
        } else {
          this.uiService.doScrollingWidthDelay(0, 0);
        }
      });

      // this.isLoadingCompleted = true;
    //}, 1000 );
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    window.removeEventListener('wheel', window.onWheelDetect);
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event): void {
  //   if (!this.isLoadingCompleted) {
  //     return;
  //   }

  //   if ($('html')[0].scrollTop > 40 && !this.isDark) {
  //     console.log(this);
  //     // $('.dynamicColor').addClass('dark');
  //     this.header.stateIsWhite = true;
  //     this.isDark = true;
  //   }
  //   if ($('html')[0].scrollTop <= 40 && this.isDark) {
  //     this.header.stateIsWhite = false;
  //     this.isDark = false;
  //   }
  // }

  // @HostListener('window:wheel', ['$event']) // for window scroll events
  // onWheel(event): void {
  //   if (!window.animatedScrolling) {
  //     let doScroll = false;
  //     let scrollingDown = false;
  //     if (event.deltaY > 0) {
  //       scrollingDown = true;
  //     }
  //
  //     const currentItem = $('#' + this.pagesIds[this.currentPage]);
  //     const windowView = $('.scrollFrame')[0].scrollTop + window.outerHeight;
  //     if (scrollingDown && windowView > currentItem.offset().top + currentItem.height()) {
  //       this.currentPage++;
  //       doScroll = true;
  //     }
  //     console.log(this.pagesIds[this.currentPage] + ' - ' + currentItem.offset().top);
  //     if (!scrollingDown && currentItem.offset().top>0){ //$('.scrollFrame')[0].scrollTop <= currentItem.offset().top) {
  //       this.currentPage--;
  //       doScroll = true;
  //     }
  //
  //     this.lastScrollState = $('.scrollFrame')[0].scrollTop;
  //
  //     console.log(this.currentPage);
  //
  //     if (this.currentPage < 0) {
  //       this.currentPage = 0;
  //       doScroll = false;
  //     }
  //     //this.currentPage = 5;
  //     if (this.currentPage > this.pagesIds.length - 1) {
  //       this.currentPage = this.pagesIds.length - 1;
  //       doScroll = false;
  //     }
  //     if (doScroll) {
  //       this.uiService.scroll(this.pagesIds[this.currentPage]);
  //     }
  //   }
  // }

  scrollNext(scrollBack: boolean): void {
    if (scrollBack) {
      this.currentPage = 0;
      this.uiService.doScrolling(0, 1000);
      return;
    }
    this.currentPage++;
    if (this.currentPage > this.pagesIds.length - 1) {
      this.currentPage = this.pagesIds.length - 1;
      this.uiService.scroll(this.pagesIds[this.currentPage], 0, 0);
    } else {
      const currentItem = document.getElementById(this.pagesIds[this.currentPage]) as HTMLElement;

      const windowView = document.querySelector("html").scrollTop + window.outerHeight;
      //console.log(this.pagesIds[this.currentPage]);
      //console.log(windowView + ' vs ' + (currentItem[0].offsetTop + ',' + currentItem.height()));
      if (windowView >= currentItem.offsetTop + currentItem.offsetHeight) {
        this.scrollNext(false);
      } else {
        this.uiService.scroll(this.pagesIds[this.currentPage], 0, 0);
      }
    }
  }

  // @HostListener('window:mousedown', ['$event']) // for window scroll events
  // onMouseDown(event): void {
  //   event.preventDefault();
  // }


  // @HostListener('window:mouseup', ['$event']) // for window scroll events
  // onMouseUp(event): void {
  //   event.preventDefault();
  // }

  openLetsTalk(): void {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }


  scrollTo(element): void {
    this.currentPage = this.pagesIds.indexOf(element);
    this.uiService.scroll(element, 0, 0);
  }

  private checkScrollPosition() {
    if (document.querySelector("html").scrollTop > 0) {
      this.uiService.doScrolling(0, 0);
    }
  }

}
