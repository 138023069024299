import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';
import {UpdateDifferentStateService} from '../../services/update-different-state.service'
import { fromEvent, Subscription } from 'rxjs';
import { OpenLetsTalkEventDto } from '../models/common.model';
import { debounceTime, filter } from 'rxjs/operators';
import { UiServiceService } from 'src/app/services/ui-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('0.5s ease-out',
              style({opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({opacity: 1}),
            animate('0.5s ease-in',
              style({opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() onWhite = true;

  public stateIsWhite = false;

  public lastScrollState = 0;
  public currentPage = 0;
  public letsTalkOpen = false;
  public menuVisible = false;
  public menuPcVisible = false;
  public isCV = false;
  public jobId = 0;
  public menuState = {
    services: false,
    company: false,
    career: false,
    language: false
  };
  public menuSelected = {
    home: false,
    services: false,
    company: false,
    career: false,
  };

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
    ) { }

  ngOnInit(): void {
    this.eventRouterNavigationEnd();

    const subscription = this.updateDifferentStateService.getLetsTalkSubsciption()
      .subscribe(
        (eventData: OpenLetsTalkEventDto) =>  this.openLetsTalk(eventData.isCV, eventData.jobId)
    );

    this.subscriptions.push(subscription);

    this.subscriptionWindowScroll();
  }

  ngOnDestroy() {
    this.subscriptions?.forEach(element => element?.unsubscribe());
  }



  toggleItem(menu): void {
    this.menuState[menu] = !this.menuState[menu];
    this.menuPcVisible = this.menuState[menu];
  }

  openTopBar(menu): void {
    this.toggleItem(menu);
    for (const key in this.menuState) {
      if (key !== menu) {
        this.menuState[key] = false;
      }
    }
  }

  openMenu(): void {
    //console.log('toggeling menu');
    this.menuVisible = true;
  }

  closeMenu(): void {
    this.menuVisible = false;
    this.menuPcVisible = false;
    this.menuState = {
      services: false,
      company: false,
      career: false,
      language: false
    };
  }

  openLetsTalk(isCV, jobId = null): void {
    this.isCV = isCV;
    this.jobId = jobId;
    this.letsTalkOpen = true;

    this.closeMenu();


    this.menuState = {
      services: false,
      company: false,
      career: false,
      language: false
    };
    this.menuSelected = {
      home: false,
      services: false,
      company: false,
      career: false,
    };

    this.uiService.doScrollingWidthDelay(0, 500);
  }

  closeLetsTalk(): void {
    this.letsTalkOpen = false;

    (document.querySelector("body") as HTMLElement).style.overflow = "auto";
  }


  subscriptionWindowScroll() {
    const subscription = fromEvent(window, 'scroll')
    .pipe(debounceTime(1))
    .subscribe(() => this.onScroll());

    this.subscriptions.push(subscription);
  }

  onScroll(): void {
    const winScroll = document.querySelector("html").scrollTop;
    const height = document.querySelector("html").scrollHeight - document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;

    const dotBarList = document.querySelectorAll<HTMLElement>(".dotBar");

    dotBarList?.forEach(element => element.style.width = scrolled + '%');

    //(document.querySelectorAll(".dotBar") as HTMLElement).style.width = scrolled + '%';
  }

  openBlog() {
    window.open("https://www.blog.globalteams.ltd");
  }

  private eventRouterNavigationEnd() {
    const subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((x: NavigationEnd) => {
        this.checkActiveBlock(x.url);
        this.closeMenu();
      });

     this.subscriptions.push(subscription);
  }

  private checkActiveBlock(url: string) {
    for (const key in this.menuSelected) {
      this.menuSelected[key] = url.includes(key);

      if (key === "home" && url === "/") {
        this.menuSelected[key] = true;
      }
    }
  }
}
