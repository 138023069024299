import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselStore } from '@ngu/carousel';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { ServisesHomePageData, ServisesHomePageDataConfig } from 'src/app/shared/models/common.model';
import { UiServiceService } from '../../../services/ui-service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent implements OnInit {

  public servisesHomePage: { [key: string]: ServisesHomePageData } = {};
  public selectedService: string;
  public displayedDescription: ServisesHomePageDataConfig[];

  public carouselTile: any;
  public carouselTileItems: Array<any> = [1, 2, 3, 4];

  constructor(
    private uiService: UiServiceService,
    private router: Router,
    private updateDifferentStateService: UpdateDifferentStateService
  ) {
    this.servisesHomePage = this.uiService.servisesHomePage;

    if (!this.uiService.isMobile()) {
      this.selectedService = this.uiService.defaultServiseOnHomePage;
      this.onServiceClicked(this.selectedService);
    }

  }

  onServiceClicked(name: string, data?: ServisesHomePageData) {
    this.selectedService = name;
    this.displayedDescription = this.servisesHomePage[name]?.data;

    if (this.uiService.isMobile()) {
      this.navigateTo(data?.route, data?.routeFragment);
    }
  }

  asIsOrder(a, b) {
    return 0;
  }

  onmoveFn(data: NguCarouselStore): void {
    //console.log(data);
  }

  openLetsTalk() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

  ngOnInit(): void {
    this.carouselTile = {
      grid: { xs: 1, sm: 4, md: 4, lg: 4, all: 0 },
      slide: 1,
      speed: 400,
      interval: {
        timing: 5000,
        initialDelay: 100
      },
      point: {
        visible: true
      },
      load: 2,
      loop: true,
      touch: true
    };

    // setTimeout(() => {
    //   const marqueeDom: HTMLElement = document.querySelector('.marquee');

    //   if (marqueeDom) {
    //     marqueeDom.style.display = 'none';
    //   }
    // }, 20000);

  }

  navigateTo(route: string, routeFragment: string) {
    if (!route) {
      return;
    }

    this.router.navigate(
      [
        route
      ], {
      fragment: routeFragment
    }
    );
  }


}
