import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UiServiceService } from 'src/app/services/ui-service.service';

@Component({
  selector: 'app-why-east-central-europe',
  templateUrl: './why-east-central-europe.component.html',
  styleUrls: ['./why-east-central-europe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhyEastCentralEuropeComponent implements OnInit {

  constructor(
    private uiService: UiServiceService
  ) { }

  ngOnInit() {
    this.uiService.doScrolling(0, 100);
  }

}
