import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-supporting-services',
  templateUrl: './supporting-services.component.html',
  styleUrls: ['./supporting-services.component.scss']
})
export class SupportingServicesComponent implements OnInit {

  @Output() contact: EventEmitter<any> = new EventEmitter<any>();
  @Input() isSupporting = true;
  public title = ['', ''];
  public items: any = [];

  constructor() {
  }

  triggerContact(): void {
    this.contact.emit(false);
  }


  ngOnInit(): void {
    this.title = ['SUPPORTING_SERVICES.Supporting', 'SUPPORTING_SERVICES.Services'];

    if (this.isSupporting) {
      this.items = [
        {
          title: 'HEADER.QA',
          desc: 'SUPPORTING_SERVICES.QA_DESC',
          css: 'supporting-service-qa',
          button: 'SUPPORTING_SERVICES.learn_more',
          url: '/services/supporting-services',
          fragment: 'qa'
        },
        {
          title: 'HEADER.UI_UX',
          desc: 'SUPPORTING_SERVICES.UI_UX_desc',
          css: 'supporting-service-ui',
          button: 'SUPPORTING_SERVICES.learn_more',
          url: '/services/supporting-services',
          fragment: 'uiux'
        },
        {
          title: 'HEADER.Specialist_Support',
          desc: 'SUPPORTING_SERVICES.Specialist_Support_desc',
          css: 'supporting-service-specialist',
          button: 'SUPPORTING_SERVICES.learn_more',
          url: '/services/supporting-services',
          fragment: 'specialistsupport'
        }
      ];
    } else {
      this.items = [
        {
          title: 'HEADER.Extended_Teams',
          desc: 'SUPPORTING_SERVICES.Extended_Teams_desc',
          css: 'dedicatedTeams ',
          button: 'SUPPORTING_SERVICES.help_me_grow',
          url: '/services/extended-teams',
          fragment:''
        },
        {
          title: 'HEADER.Managed_Teams',
          desc: 'SUPPORTING_SERVICES.Managed_Teams_desc',
          css: 'managedTeams',
          button: 'SUPPORTING_SERVICES.build_my_team',
          url: '/services/managed-teams',
          fragment:''
        },
        {
          title: 'HEADER.End_to_End_Projects',
          desc: 'SUPPORTING_SERVICES.End_to_End_Projects_desc',
          css: 'endToEnd',
          button: 'SUPPORTING_SERVICES.build_my_team',
          url: '/services/end-to-end-projects',
          fragment:''
        }
      ];

    }


  }

}
