<div class="container" *ngIf="job">
  <div class="title-section">
    <div class="title" [innerHtml]="job.jobTitle">
    </div>

    <div *ngIf="job.shortBy === 'HOT' && !isRowView"  class="title__hot-vacancy">
      <img loading="lazy" src="assets/svg/noto_fire.svg" />
    </div>
  </div>

  <div class="job-body-section">
    <div class="experience-section">
      <div class="experience" [innerHtml]="job.experience">
      </div>
    </div>

    <div class="techTags-section">
      <ng-container *ngIf="job.techTags">
        <span class="jobTechTag" *ngFor="let jobTechTag of job.techTags;let last = last">
          {{jobTechTag}}

          <ng-container *ngIf="!last">
            ,
          </ng-container>
        </span>
      </ng-container>
    </div>

    <div class="card-footer" [ngClass]="{'job-footer-hidden': !(job.locationCity || job.locationCountry)}">
      <div class="location-section">
        <div class="location-logo">
          <img loading="lazy" src="assets/svg/job-location-logo.svg" />
        </div>

        <div class="location">
          {{ (job.locationCity || job.locationCountry)}}
        </div>
      </div>

    </div>

  </div>

</div>


<div *ngIf="latestOpeningsViewShowCount" class="latestOpeningsViewShowCount-container">
  +
  {{latestOpeningsViewShowCount}}

  {{'OPEN_POSITIONS.count_more_openings' | translate}}

  <img loading="lazy" src="assets/svg/working-at-global-arrow-right.svg" />
</div>
