<app-services-main-base [title]="'HEADER.Managed_Teams'" [textBody]="textBody">

  <app-dot class="managed-teams-65-65 desktop-only"></app-dot>
  <app-dot class="managed-teams-30-30"></app-dot>
  <app-dot class="managed-teams-95-95 desktop-only"></app-dot>

  <div class="image-right-part">
    <img src="/assets/svg/Ellipse132.svg" loading="lazy">
  </div>
</app-services-main-base>


<app-managed-teams-what-are-dedicated-teams></app-managed-teams-what-are-dedicated-teams>
