export class OpenPositionFilterItem {
  public tag: string;
  public itemCount: number;
}

export enum JobPositionLevel {
  "Junior" = "Junior",
  "Middle" = "Middle",
  "Senior" = "Senior",
  "TeamLead" = "Team Lead"
}

export enum JobShortBy {
  "New" = "New",
  "HOT" = "HOT"
}

export enum JobLocationsOptions {
  "Kyiv" = "Ukraine (Kyiv)",
  "Ukraine" = "Ukraine (all)",
  "Romania" = "Romania",
  "Serbia" = "Serbia"
}

export class JobOpenPosition {
  public id: number;
  public locationCity: string;
  public locationCountry: string;
  public jobTitle: string;
  public experience: string;
  public description: string;
  public techTags: string[];
  public requirements: string[];
  public responsibilities: string[];
  public advantages: string[];
  public positionLevel: JobPositionLevel;
  public shortBy: string;
  public you_are: string
  public techStack: string[];
  public about: string;
  public englishLevel: string;
}

export class SearchFilterFormData {
  public search: string;
  public positionLevel: string;
  public location: string;
  public shortBy: string;
}
