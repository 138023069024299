import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageCustomService {

  constructor() { }

  preloadImage(url: string) {
    const img = document.createElement('img');
    img.src = url;
    img.decoding = "async";
    img['loading'] = "lazy";
  }

}
