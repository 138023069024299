
<div class="aboutUs" id="page">


  <div class="body">

    <div class="emptyContainer"></div>

    <div class="flexContainer">
      <div class="content">

        <div class="title main-tile-aboutUs">
          {{"ABOUT_US.WE_ARE"|translate}}
          <div>
            {{"ABOUT_US.Global_Teams"|translate}}
          </div>
        </div>

        <img loading="lazy" class="about-us-mobile-view"
          src="assets\images\about\new\mobile-team.jpg" alt="team">

        {{"ABOUT_US.We_are_Global_Teams_founded_in_2014"|translate}}

        <br/><br/>

        {{"ABOUT_US.Today_Global_Teams_is_more_than_a_recruitment_specialist"|translate}}

        <br/><br/>

        {{"ABOUT_US.We_began_as_a_software_development_outsourcing_company"|translate}}
        <Br/><br/>

        {{"ABOUT_US.Today_Global_Teams_has_expanded_to_include_over_150_experienced"|translate}}
      </div>

      <div class="profiles">

        <app-dot class="about-us-green-90-90"></app-dot>
        <app-dot class="about-us-green-41-41"></app-dot>

        <div class="pic-about about-us-desktop-view picture-cut-right">
        </div>
      </div>

    </div>
  </div>

</div>


<app-our-leaders id="ourleaders"></app-our-leaders>

<!-- <app-why-east-central-europe></app-why-east-central-europe> -->
<app-benefits id="benefits" #benefits></app-benefits>

<!-- <app-our-team></app-our-team> -->


<div class="atmosphere" id="atmosphere">
  <div class="playIcon" *ngIf="videoEnded" (click)="playVideo()"></div>

  <div class="title title2">
    {{"ABOUT_US.Join_the_atmosphere_of_our_office"|translate}}
  </div>

  <video id="videoElement" controls preload="auto" style="width:100%;" (ended)="vidEnded()">
    <source src="//cdn.globalteams.ltd/assets/videos/about.mov" type="video/mp4">
  </video>

</div>

<app-they-trust-us></app-they-trust-us>
<app-we-are-growing></app-we-are-growing>

<div class="slider">

  <div class="title title2">
    {{"ABOUT_US.Join_our_team"|translate}}
  </div>

  <ng-image-slider [images]="imageObject" #nav [showArrow]="false" [infinite]="true" [autoSlide]="true"
                   [imageSize]="{width: '462px', height: '323px'}"></ng-image-slider>

  <div class="bullets">

    <ng-container *ngFor="let item of imageObject; let i=index;">
      <div class="bullet" [ngClass]="{'selected': i==nav.visiableImageIndex}">
      </div>
    </ng-container>
  </div>
</div>

<div class="see-open-positions" [routerLink]="['/career/open-positions']">
  <div class="see-open-positions-text">
    {{"WORKING_AT_GLOBAL.SEE_OPEN_POSITIONS"|translate}}
  </div>
</div>

