<div class="why-east-central-europe-container">
  <div class="why-east-central-europe-top-block">
    <div class="why-east-central-europe-top-block__title">
      <h1>
        {{"why_east_central_europe.Why_choose"|translate}}
        <span>
          {{"why_east_central_europe.East_Central_Europe"|translate}}
        </span>
        {{"why_east_central_europe.as_your_remote_base"|translate}}
      </h1>
    </div>

    <div class="why-east-central-europe-top-block__image">
      <img loading="lazy" src="/assets/images/why-east-central-europe/1.png" />
    </div>
  </div>

  <div class="why-east-central-europe-content-block">
    <div class="why-east-central-europe-content-block-container">

      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/1.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.Remote_but_Close"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.UKRAINE_IS_JUST_2_HOURS_AHEAD_OF_GMT"|translate}}
          </div>
        </div>
      </div>

      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/2.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.Low_in_cost"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.Pricing_always_matters_Outstaffing_to_Ukraine_allows"|translate}}
          </div>
        </div>
      </div>

      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/3.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.Rich_in_talent"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.Ukraine_is_one_of_the_out_outstaffing_capitals"|translate}}
          </div>
        </div>
      </div>


      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/4.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.English_speaking"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.Over_80perc_of_Ukrainian_developers_speak_English"|translate}}
          </div>
        </div>
      </div>


      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/5.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.Focus_on_education"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.Ukraine_invests_heavily_in_tech_education"|translate}}
          </div>
        </div>
      </div>


      <div class="content-block-item">
        <div class="content-block-item__image">
          <img loading="lazy" src="/assets/svg/why-east-central-europe/6.svg" />
        </div>

        <div class="content-block-item__content">
          <div class="content-block-item__content-title">
            {{"BENEFITS.Globally_recognized"|translate}}
          </div>

          <div class="content-block-item__content-description">
            {{"BENEFITS.International_corporations_including_Google"|translate}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
