import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { JobsService } from 'src/app/services/jobs.service';
import { KeyValuePair } from 'src/app/shared/models/common.model';
import { JobLocationsOptions, JobPositionLevel, JobShortBy, SearchFilterFormData } from '../models/open-positions.model';

@Component({
  selector: 'app-open-positions-search-filters',
  templateUrl: './open-positions-search-filters.component.html',
  styleUrls: ['./open-positions-search-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenPositionsSearchFiltersComponent implements OnInit {

  @Output() searchFiltersData = new EventEmitter<SearchFilterFormData>();
  @Input() formGroup: UntypedFormControl;

  shortByOptions: KeyValuePair[] = [];
  positionLevelOptions: KeyValuePair[] = [];
  locationOptions: KeyValuePair[] = [];

  constructor(
    private readonly jobService: JobsService
  ) { }

  ngOnInit() {
    this.positionLevelOptions = this.jobService.enumToKeyValuePair(JobPositionLevel);
    this.shortByOptions = this.jobService.enumToKeyValuePair(JobShortBy);
    this.locationOptions = this.jobService.enumToKeyValuePair(JobLocationsOptions);
  }

  onButtonSearchClicked() {
    this.searchFiltersData.emit(this.formGroup.value);
  }

}
