<div class="servicesShared">

  <div class="leftSection">
    <div class="title"><span>UI/UX</span></div>

    <div class="smallPicture pic5"></div>

    <div class="content">Kyiv is often said to be Berlin’s cooler, cheaper younger brother. It’s home to some of the world’s leading design and UX talent, giving it a reputation for excellence in design innovation.
      <br/><br/>
      When your project team can draw on an expert UX consultant, you’ll give your product the competitive edge it needs to stand out in a crowded market. That said, you might not need a full-cycle UX professional as a permanent member of your team, which is why we offer UI/UX services by the hour or by project.

    </div>
    <div class="letsTalkButton"  (click)="letsTalkClicked()">Let’s Talk</div>
  </div>


  <div class="rightSide">
    <div class="picture pic5"></div>
  </div>


</div>


<app-rotating-technologies></app-rotating-technologies>
<app-testimonials></app-testimonials>
<app-ready-steady-go></app-ready-steady-go>
<app-supporting-services [isSupporting]="false"></app-supporting-services>
<app-why-our-services></app-why-our-services>
<app-ready-to-talk></app-ready-to-talk>
