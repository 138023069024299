import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-input-custom',
  templateUrl: './input-custom.component.html',
  styleUrls: ['./input-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputCustomComponent implements OnInit {

  @Input() inputType = "text";
  @Input() placeholderText: string = "";
  @Input() formControlItem = new UntypedFormControl();


  constructor() { }

  ngOnInit() {
  }

}
