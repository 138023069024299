import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-ready-steady-go',
  templateUrl: './ready-steady-go.component.html',
  styleUrls: ['./ready-steady-go.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({opacity: 0}),
            animate('1s ease-out',
              style({opacity: 1}))
          ]
        )
      ]
    )
  ]
})
export class ReadySteadyGoComponent implements OnInit {

  public showing = 0;

  constructor() {
  }

  ngOnInit(): void {
  }


}
