import {BrowserModule, TransferState} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {ContactUsComponent} from './shared/contact-us/contact-us.component';
import {CheckboxComponent} from './shared/checkbox/checkbox.component';
import {CookiesComponent} from './shared/cookies/cookies.component';
import {ServicesComponent} from './pages/home/services/services.component';
import {SuccessComponent} from './pages/home/success/success.component';
import {BenefitsComponent} from './pages/home/benefits/benefits.component';
import {IntroComponent} from './pages/home/intro/intro.component';
import {TestimonialsComponent} from './pages/home/testimonials/testimonials.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NguCarouselModule} from '@ngu/carousel';
import {AboutUsComponent} from './pages/company/about-us/about-us.component';
import {WhyGlobalTeamsComponent} from './pages/company/why-global-teams/why-global-teams.component';
import {HowItWorksComponent} from './pages/company/how-it-works/how-it-works.component';
import {TrustedByComponent} from './pages/company/trusted-by/trusted-by.component';
import {OurLeadersComponent} from './pages/company/our-leaders/our-leaders.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HeaderComponent} from './shared/header/header.component';
import {ReadyToTalkComponent} from './shared/ready-to-talk/ready-to-talk.component';
import {ContactUsPageComponent} from './pages/contact-us-page/contact-us-page.component';
import {NgImageSliderModule} from 'ng-image-slider';
import {UiUxComponent} from './pages/services/ui-ux/ui-ux.component';
import {QAComponent} from './pages/services/qa/qa.component';
import {SpecialistSupportComponent} from './pages/services/specialist-support/specialist-support.component';
import {DedicatedTeamsComponent} from './pages/services/dedicated-teams/dedicated-teams.component';
import {ManagedTeamsComponent} from './pages/services/managed-teams/managed-teams.component';
import {EndToEndProjectsComponent} from './pages/services/end-to-end-projects/end-to-end-projects.component';
import {RotatingTechnologiesComponent} from './shared/rotating-technologies/rotating-technologies.component';
import {ReadySteadyGoComponent} from './shared/ready-steady-go/ready-steady-go.component';
import {SupportingServicesComponent} from './shared/supporting-services/supporting-services.component';
import {WhyOurServicesComponent} from './shared/why-our-services/why-our-services.component';
import {WorkingAtGlobalComponent} from './pages/career/working-at-global/working-at-global.component';
import {SupportingComponent} from './pages/services/supporting/supporting.component';
import {ArrowMoveDownComponent} from './shared/arrow-move-down/arrow-move-down.component';
import {OpenPositionsComponent} from './pages/career/open-positions/open-positions.component';
import {JobItemComponent} from './pages/career/open-positions/job-item/job-item.component';
import {JobViewerComponent} from './pages/career/open-positions/job-viewer/job-viewer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {FileUploadModule} from 'ng2-file-upload';
import { IntroServicesComponent } from './pages/home/intro-services/intro-services.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LanguageSelectorComponent } from './shared/language-selector/language-selector.component';
import { translateBrowserLoaderFactory } from './services/custom-ngx-translate.service';
import { DotComponent } from './shared/dot/dot.component';
import { EmployeeProfileInfoComponent } from './shared/employee-profile-info/employee-profile-info.component';
import { InViewPortDirective } from './shared/directives/in-view-port.directive';
import {OurTeamItemComponent} from './pages/company/our-team-item/our-team-item.component';
import {TheyTrustUsComponent} from './pages/company/they-trust-us/they-trust-us.component';
import {WeAreGrowingComponent} from './pages/company/we-are-growing/we-are-growing.component';
import {CircleWithTextComponent} from './shared/circle-with-text/circle-with-text.component'
import { MeetOurSpecialistsComponent} from './shared/meet-our-specialists/meet-our-specialists.component';
import { EmployeePositionHoverTextComponent } from './shared/employee-position-hover-text/employee-position-hover-text.component';
import { ServicesMainBaseComponent } from './pages/services/services-main-base/services-main-base.component'
import { ButtonDefaultComponent } from './shared/button-default/button-default.component'
import { OpenPositionFilterComponent } from './pages/career/open-positions/open-position-filter/open-position-filter.component'
import { NgImageSliderCustomComponent } from './shared/ng-image-slider-custom/ng-image-slider-custom.component'
import { DialogFullScreenComponent } from './shared/dialog-full-screen/dialog-full-screen.component'
import { LoaderSpinnerComponent } from './shared/loader-spinner/loader-spinner.component'
import { InputCustomComponent } from './shared/input-custom/input-custom.component'
import { SelectCustomComponent } from './shared/select-custom/select-custom.component'
import { OpenPositionsSearchFiltersComponent } from './pages/career/open-positions/open-positions-search-filters/open-positions-search-filters.component'
import { ButtonSearchComponent } from './shared/button-search/button-search.component'
import { MatDialogModule } from '@angular/material/dialog';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomePersonalTouchComponent } from './pages/home/home-personal-touch/home-personal-touch.component';
import { HomeLetsTalkComponent } from './pages/home/home-lets-talk/home-lets-talk.component';
import { JobsApiServiceBase } from './services/JobsApiServiceBase';
import { JobsApiService } from './services/jobs-api.service';
import {
  ManagedTeamsWhatAreDedicatedTeamsComponent
} from './pages/services/managed-teams/managed-teams-what-are-dedicated-teams/managed-teams-what-are-dedicated-teams.component';
import { WhyEastCentralEuropeComponent } from './pages/company/why-east-central-europe/why-east-central-europe.component';
import { IndustriesWeServeComponent } from './pages/company/industries-we-serve/industries-we-serve.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    CheckboxComponent,
    CookiesComponent,
    ServicesComponent,
    SuccessComponent,
    BenefitsComponent,
    IntroComponent,
    HomePersonalTouchComponent,
    HomeLetsTalkComponent,
    ManagedTeamsWhatAreDedicatedTeamsComponent,
    TestimonialsComponent,
    FooterComponent,
    PrivacyComponent,
    HeaderComponent,
    PageNotFoundComponent,
    AboutUsComponent,
    WhyGlobalTeamsComponent,
    WeAreGrowingComponent,
    InViewPortDirective,
    LoaderSpinnerComponent,
    HowItWorksComponent,
    TrustedByComponent,
    OurLeadersComponent,
    ReadyToTalkComponent,
    WhyEastCentralEuropeComponent,
    DotComponent,
    EmployeeProfileInfoComponent,
    NgImageSliderCustomComponent,
    DialogFullScreenComponent,
    IndustriesWeServeComponent,
    OurTeamItemComponent,
    TheyTrustUsComponent,
    // OurTeamComponent,
    ContactUsPageComponent,
    UiUxComponent,
    MeetOurSpecialistsComponent,
    QAComponent,
    SpecialistSupportComponent,
    DedicatedTeamsComponent,
    LanguageSelectorComponent,
    ManagedTeamsComponent,
    EndToEndProjectsComponent,
    EmployeePositionHoverTextComponent,
    OpenPositionFilterComponent,
    InputCustomComponent,
    SelectCustomComponent,
    ButtonSearchComponent,
    OpenPositionsSearchFiltersComponent,
    ServicesMainBaseComponent,
    RotatingTechnologiesComponent,
    ReadySteadyGoComponent,
    SupportingServicesComponent,
    WhyOurServicesComponent,
    WorkingAtGlobalComponent,
    CircleWithTextComponent,
    SupportingComponent,
    ArrowMoveDownComponent,
    OpenPositionsComponent,
    JobItemComponent,
    JobViewerComponent,
    IntroServicesComponent,
    ButtonDefaultComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    MatDialogModule,
    HttpClientModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    NguCarouselModule,
    NgImageSliderModule,
    FileUploadModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    })
  ],
  providers: [
    { provide: JobsApiServiceBase, useClass: JobsApiService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
