<div class="container" *ngIf="formGroup">
  <div class="search-filters">
    <div class="positions-search search-filter-item">
      <app-input-custom [formControlItem]="formGroup.get('search')"
        [placeholderText]="'OPEN_POSITIONS.search_by_skills_of_job_title'">
      </app-input-custom>
    </div>

    <div class="position-level search-filter-item">
      <app-select-custom [formControlItem]="formGroup.get('positionLevel')"
        [placeholderText]="'OPEN_POSITIONS.position_level'" [dataOptions]="positionLevelOptions">
      </app-select-custom>
    </div>

    <div class="location-search search-filter-item">
      <app-select-custom [formControlItem]="formGroup.get('location')" [placeholderText]="'OPEN_POSITIONS.location'"
        [dataOptions]="locationOptions">
      </app-select-custom>
    </div>

    <div class="short-by search-filter-item">
      <app-select-custom [formControlItem]="formGroup.get('shortBy')" [placeholderText]="'OPEN_POSITIONS.Short_by'"
        [dataOptions]="shortByOptions">
      </app-select-custom>
    </div>
  </div>

  <div class="search-button-container">
    <app-button-search [isEmitOnEnterClick]="true" (buttonClicked)="onButtonSearchClicked()">
    </app-button-search>
  </div>

</div>
