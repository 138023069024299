import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Countries} from '../../jsons/countries';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {JobsService} from '../../services/jobs.service';
import {FileUploader} from 'ng2-file-upload';

function notEmpty(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export const customEmailValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } => {
  if (notEmpty(Validators.required(control))) {
    return null;
  }

  const controlValue: string = control.value;

  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(controlValue) ? null : {'email': true};
};


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],

})
export class ContactUsComponent implements OnInit, OnChanges {

  public countries = Countries;
  public showErrors = false;
  public sent = false;
  @Input() public isCV = false;
  @Input() public jobId = 0;
  @Input() public basicForm = false;
  @Input() public isHome = false;
  @Input() public isPopup = false;
  @Output() public close: EventEmitter<any> = new EventEmitter<any>();
  public cvUploader: FileUploader;
  public fileName: string = null;

  public agreeToTerms = new UntypedFormControl();
  public agreeToBeAdded = new UntypedFormControl();
  public isProcessing = false;
  public formGroup: UntypedFormGroup;
  public subTitle = 'Our Sales team will be responding to you soon';

  constructor(private jobsService: JobsService, @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder) {
    this.initFileUpload();
  }

  ngOnInit(): void {
    if (this.isCV) {
      this.subTitle = 'Our HR will be responding to you soon';
      this.formGroup = this.fb.group({
        name: [null, Validators.required],
        email: [null, Validators.compose([Validators.required, customEmailValidator])],
        phone: [null],
        country: [null, Validators.required],
        message: [null, Validators.required],
        jobTitle: [null],
        isCV: [true],
      });

    } else {
      this.formGroup = this.fb.group({
        name: [null, Validators.required],
        email: [null, Validators.compose([Validators.required, customEmailValidator])],
        company: [null, Validators.required],
        country: [null, Validators.required],
        message: [null, Validators.required],
        jobTitle: [null],
        isCV: [false],
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.setBodyOverflow(changes?.isPopup?.currentValue);
  }

  initFileUpload(): void {
    const url = 'https://api.globalteams.ltd/api/emailsender/email';

    this.cvUploader = new FileUploader({
      'url': url,
      isHTML5: true,
      maxFileSize: 10 * 1024 * 1024,
      queueLimit: 1,
      autoUpload: false,
      disableMultipart: false
    });

    this.cvUploader.onBuildItemForm = (fileItem: any, form: any) => {
      //console.log(fileItem.file.name);
      if (this.jobId > 0) {
        this.jobsService.getJob(this.jobId).subscribe(job => {
          if (job) {
            form.append('jobTitle', job.jobTitle + ' - job Id:' + this.jobId);
          }
          this.appendDataToForm(form);
        });

      } else {
        this.appendDataToForm(form);
      }
    };
    this.cvUploader.onAfterAddingFile = (fileItem: any) => {
      //console.log(fileItem.file.name);
      this.fileName = fileItem.file.name;
      fileItem.file.withCredentials = false;
    };

    this.cvUploader.onWhenAddingFileFailed = (fileItem: any, filter: any, options: any) => {
      this.fileName = null;
    };

    this.cvUploader.onErrorItem = (item: any, response: any, status: number, headers: any) => {
      // this.isLoading = false;
      // this.resultText = "";
      // this.errorText = 'there was an error';
      (document.querySelector('input[type=\'file\']') as HTMLInputElement).value = '';
    };

    this.cvUploader.onCompleteItem = (item: any, response: any, status: number, headers: any) => {
      //console.log(response);
    };

    this.cvUploader.onSuccessItem = (item: any, response: any, status: number, headers: any) => {
      //console.log(response);
      (document.querySelector('input[type=\'file\']') as HTMLInputElement).value = '';
      //  this.resultText = "";
      //  this.errorText = "";
      this.isProcessing = false;
      this.sent = true;
      item.remove();
      //  this.isLoading = false;
    };
  }

  clearFile(): void {
    this.cvUploader.clearQueue();
    //this.cvUploader.destroy();
    this.cvUploader.cancelAll();
    this.fileName = null;
    (document.querySelector('input[type=\'file\']') as HTMLInputElement).value = '';
  }


  closePopup(): void {
    this.sent = false;
    this.formGroup.reset();
    this.showErrors = false;
    this.setBodyOverflow(false);
    this.close.emit();
  }

  submitForm(): void {

    if (this.formGroup.valid && this.agreeToBeAdded.value === true && this.agreeToTerms.value === true) {
      if (this.fileName) {
        this.isProcessing = true;
        this.cvUploader.uploadAll();
      } else {

        if (this.jobId > 0) {
          this.jobsService.getJob(this.jobId).subscribe(job => {
            if (job) {
              this.formGroup.get('jobTitle').setValue(job.jobTitle + ' - job Id:' + this.jobId);
            }
            this.sendCV();
          });
        } else {
          this.sendCV();
        }
      }

    } else {
      this.showErrors = true;
    }
  }

  private setBodyOverflow(isHidden = false) {
    const bodyElement = document.querySelector('body') as HTMLElement;

    bodyElement.style.overflow = (isHidden ? "hidden" : "auto")
  }

  private appendDataToForm(form)  {
    form.append('email', this.formGroup.get('email').value);
    form.append('name', this.formGroup.get('name').value);
    form.append('country', this.formGroup.get('country').value);
    form.append('message', this.formGroup.get('message').value);
    form.append('isCV', this.formGroup.get('isCV').value);
    if (this.isCV) {
      form.append('phone', this.formGroup.get('phone').value);
    } else {
      form.append('company', this.formGroup.get('company').value);
    }
  }

  private sendCV() {
    this.isProcessing = true;
    this.jobsService.sendCV(this.formGroup.value).subscribe(x => {
      console.log('sent');
      this.sent = true;
      this.isProcessing = false;
    });
  }

}
