import { Component, OnInit } from '@angular/core';
import { CustomNgxTranslateService } from './services/custom-ngx-translate.service';
import { WorkingAtGlobalService } from './services/working-at-global.service';
import { SeoTagsService } from './services/seo-tags.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private customNgxTranslateService: CustomNgxTranslateService,
    private workingAtGlobalService: WorkingAtGlobalService,
    private seoTagsService: SeoTagsService
  ) { }

  ngOnInit(): void {
    this.customNgxTranslateService.initDefaultLang();

    this.workingAtGlobalService.preloadAllImages();
    this.seoTagsService.subscribeNavigationChange();
  }
}
