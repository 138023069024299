<div class="home-personal-touch-container">
  <div class="home-personal-touch__title">
    <h2>
      {{'HOME_PERSONAL_TOUCH.BRINGING_A_PERSONAL_TOUCH_TO_THE_HIGH_STAKES'| translate}}
    </h2>
  </div>

  <div class="home-personal-touch__body">
    <div class="home-personal-touch__body-item">
      <div class="home-personal-touch__body-item-logo">
        <img loading="lazy" src="assets/svg/home-personal-touch/quality-talent-logo.svg" />
      </div>

      <div class="home-personal-touch__body-item-description">
        <div class="home-personal-touch__body-item-description__title">
          {{'HOME_PERSONAL_TOUCH.Quality_Talent.title'| translate}}
        </div>

        <div class="home-personal-touch__body-item-description__body">
          {{'HOME_PERSONAL_TOUCH.Quality_Talent.description'| translate}}
        </div>

        <div class="home-personal-touch__body-item-separator"></div>
      </div>
    </div>

    <div class="home-personal-touch__body-item">
      <div class="home-personal-touch__body-item-logo">
        <img loading="lazy" src="assets/svg/home-personal-touch/cost-logo.svg" />
      </div>

      <div class="home-personal-touch__body-item-description">
        <div class="home-personal-touch__body-item-description__title">
          {{'HOME_PERSONAL_TOUCH.Cost.title'| translate}}
        </div>

        <div class="home-personal-touch__body-item-description__body">
          <div>
            {{'HOME_PERSONAL_TOUCH.Cost.description'| translate}}
          </div>
        </div>

        <div class="home-personal-touch__body-item-separator"></div>
      </div>
    </div>

    <div class="home-personal-touch__body-item">
      <div class="home-personal-touch__body-item-logo">
        <img loading="lazy" src="assets/svg/home-personal-touch/time_to_market_logo.svg" />
      </div>

      <div class="home-personal-touch__body-item-description">
        <div class="home-personal-touch__body-item-description__title">
          {{'HOME_PERSONAL_TOUCH.Time_to_Market.title'| translate}}
        </div>

        <div class="home-personal-touch__body-item-description__body">
          {{'HOME_PERSONAL_TOUCH.Time_to_Market.description'| translate}}
        </div>

        <div class="home-personal-touch__body-item-separator"></div>
      </div>
    </div>

    <div class="home-personal-touch__body-item">
      <div class="home-personal-touch__body-item-logo">
        <img loading="lazy" src="assets/svg/home-personal-touch/flexibility_logo.svg" />
      </div>

      <div class="home-personal-touch__body-item-description">
        <div class="home-personal-touch__body-item-description__title">
          {{'HOME_PERSONAL_TOUCH.Flexibility.title'| translate}}
        </div>

        <div class="home-personal-touch__body-item-description__body">
          <div>
            {{'HOME_PERSONAL_TOUCH.Flexibility.description'| translate}}
          </div>
        </div>

        <div class="home-personal-touch__body-item-separator"></div>
      </div>
    </div>

  </div>
</div>
