import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-spinner',
  templateUrl: './loader-spinner.component.html',
  styleUrls: ['./loader-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderSpinnerComponent implements OnInit {

  @Input() isSpinnerVisible: boolean;

  constructor() { }

  ngOnInit() {
  }

}
