import { Injectable } from '@angular/core';
import { TheyTrustUsLogoInfo } from '../shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class TheyTrustUsService {

  private readonly clients: TheyTrustUsLogoInfo[] = [
    {
      width: '100.76px',
      height: '70px',
      imgPath: 'assets/logos/new/buls_media.svg'
    },
    {
      width: '250px',
      height: '46.58px',
      imgPath: 'assets/logos/new/Dutyfreest.svg'
    },
    {
      width: '199.29px',
      height: '70px',
      imgPath: 'assets/logos/new/proline.svg'
    },
    {
      width: '273.25px',
      height: '50px',
      imgPath: 'assets/logos/new/pbc_consultants.svg',
      paddingTop: '20px'
    },
    {
      width: '158px',
      height: '72.29px',
      imgPath: 'assets/logos/new/smart_gravity.svg'
    },
    {
      width: '250px',
      height: '33.83px',
      imgPath: 'assets/logos/new/AVA.svg',
      paddingTop: '20px'
    },
    {
      width: '250px',
      height: '62.43px',
      imgPath: 'assets/logos/new/oxgaming.svg',
      paddingTop: '20px'
    },
    {
      width: '183px',
      height: '50px',
      imgPath: 'assets/logos/new/AutoDs.png',
      paddingTop: '20px'
    },
    {
      width: '228.35px',
      height: '40px',
      imgPath: 'assets/logos/new/smartbid.svg'
    },
    {
      width: '142.17px',
      height: '31,85px',
      imgPath: 'assets/logos/new/leverate.svg'
    }
  ];


  private readonly configDisplayDataChanged: { [key: number]: number[] } = {
    2500: [0, 5],
    4500: [1, 2],
    6500: [3, 4],
  };

  constructor() { }

  getClients(): TheyTrustUsLogoInfo[] {
    return [...this.clients];
  }

  getConfigDisplayDataChanged(): { [key: number]: number[] } {
    return this.configDisplayDataChanged;
  }

}
