import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor() { }

  isActiveFiltersEmpty(data: object): boolean {
    let isActiveFiltersEmpty = true;

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        isActiveFiltersEmpty = false;
        break;
      }
    }

    return isActiveFiltersEmpty;
  }

}
