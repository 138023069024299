import { trigger, state, style, AUTO_STYLE, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomNgxTranslateService } from 'src/app/services/custom-ngx-translate.service';
import { LanguageSelectorModel } from '../models/language-selector.model';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class LanguageSelectorComponent implements OnInit {

  selectedLanguage: LanguageSelectorModel;
  availableLanguages: LanguageSelectorModel[];

  isOpen = false;

  @HostListener('mouseleave', ['$event']) onLeave( e: MouseEvent ) {
    //console.log(e)
  }

  constructor(
    private customNgxTranslateService: CustomNgxTranslateService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.availableLanguages = this.customNgxTranslateService.getAvailableLanguages();

    this.selectedLanguage = this.availableLanguages.find(lang => {
      return lang.alpha2 === this.translate?.currentLang
    }) || this.customNgxTranslateService.getDefaultSelectedLanguage();
  }

  setLanguage(languageItem: LanguageSelectorModel) {
    this.selectedLanguage = languageItem;

    const language = languageItem.alpha2;

    this.customNgxTranslateService.setLanguageToLocalStorage(language);
    this.translate.use(language)
  }

  toggleSelector() {
    this.isOpen = !this.isOpen;
  }

}
