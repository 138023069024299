import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OpenLetsTalkEventDto } from '../shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class UpdateDifferentStateService {

  private letsTalk$ = new Subject<OpenLetsTalkEventDto>();

  constructor() { }

  getLetsTalkSubsciption() {
    return this.letsTalk$.asObservable();
  }

  emitLetsTalkEvent() {
    const dto = new OpenLetsTalkEventDto();
    dto.isCV = false;

    this.letsTalk$.next(dto);
  }

  emitLetsTalkCVForm(jobId: number) {
    const dto = new OpenLetsTalkEventDto();
    dto.isCV = true;
    dto.jobId = jobId;

    this.letsTalk$.next(dto);
  }

}
