<app-services-main-base [title]="'SUPPORTING_SERVICES.Speciality_Services'" [textBody]="textBody">
  <app-dot class="extended-teams-95-95"></app-dot>
  <app-dot class="extended-teams-30-30"></app-dot>
  <app-dot class="extended-teams-19-19 desktop-only"></app-dot>
</app-services-main-base>

<div class="servicesShared2" id="specialistsupport">

  <div class="leftSection padding-left-0">
    <app-dot class="supporting-services-specialist-support-img"></app-dot>
    <app-dot class="supporting-services-specialist-support-img-2"></app-dot>

    <div class="picture pic6 picture-cut-left"></div>
  </div>

  <div class="rightSide padding-left-0">
    <app-dot class="supporting-services-specialist-support-text"></app-dot>

    <h2 class="title title-wrap-text">
      {{'HEADER.Specialist_Support' | translate}}
    </h2>
    <div class="smallPicture pic6-mobile"></div>


    <div class="content">
      {{'SUPPORTING_SERVICES.IF_YOU_ENCOUNTER_AN_UNEXPECTED_CHALLENGE' | translate}}
      <br/><br/>
      {{'SUPPORTING_SERVICES.Our_talent_includes_top_tech_leaders' | translate}}
    </div>
  </div>


</div>

<div class="servicesShared2 lessMargin uiux-container" id="uiux">

  <div class="leftSection">
    <h2 class="title">
      {{'HEADER.UI_UX' | translate}}
    </h2>

    <div class="smallPicture pic5-mobile"></div>

    <div class="content">
      {{'SUPPORTING_SERVICES.Kyiv_is_often_said_to_be_Berlins_cooler' | translate}}
      <br/><br/>
      {{'SUPPORTING_SERVICES.When_your_project_team_can_draw_on_an_expert_UX_consultant' | translate}}

    </div>
  </div>

  <div class="rightSide">
  <app-dot class="supporting-services-ui-ux-text"></app-dot>

    <div class="picture pic5 picture-cut-right"></div>
  </div>

</div>


<div class="servicesShared2 lessMargin" id="qa">

  <div class="leftSection padding-left-0">
    <app-dot class="supporting-services-qa-img"></app-dot>

    <div class="picture pic4 picture-cut-left"></div>
  </div>

  <div class="rightSide">
    <h2 class="title">
      {{'HEADER.QA' | translate}}
    </h2>

    <div class="smallPicture pic4-mobile"></div>

    <div class="content">

      {{'SUPPORTING_SERVICES.Testing_is_an_essential_and_critical_part' | translate}}
      <br/><br/>
      {{'SUPPORTING_SERVICES.We_understand_that_you_might_not_always_need_full' | translate}}
      <br/><br/>

    </div>
  </div>
</div>

<div class="servicesShared2 lessMargin graphic-design-container" id="graphic-design">

  <div class="leftSection">
    <h2 class="title">
      {{'HEADER.Graphic_Design' | translate}}
    </h2>

    <div class="smallPicture pic7-mobile"></div>

    <div class="content">
      {{'SUPPORTING_SERVICES.Graphic_design_plays_a_crucial_role_in_both_a_company' | translate}}
      <br/><br/>
      {{'SUPPORTING_SERVICES.While_text_rich_websites_can_improve_search_engine_rankings' | translate}}
    </div>
  </div>

  <div class="rightSide">
  <app-dot class="supporting-services-ui-ux-text"></app-dot>

    <div class="picture pic7 picture-cut-right"></div>
  </div>

</div>


<app-meet-our-specialists></app-meet-our-specialists>
<app-supporting-services (contact)="letsTalkClicked()" [isSupporting]="false"></app-supporting-services>
<app-ready-to-talk></app-ready-to-talk>
