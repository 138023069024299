import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OfficeGoogleMap } from 'src/app/shared/models/we-are-growing.model';

@Component({
  selector: 'app-we-are-growing',
  templateUrl: './we-are-growing.component.html',
  styleUrls: ['./we-are-growing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WeAreGrowingComponent implements OnInit {

  companyOffices: OfficeGoogleMap[] = [
    {
      country: 'COMMON.SPAIN',
      city: 'COMMON.Barselona',
      left: '41%',
      top: '27%',
      className: "Spain-office-map",
      officeImage: "/assets/images/offices/hover/Spain.jpg"
    },
    {
      country: 'FOOTER.addressArea.Israel.country',
      city: 'COMMON.Tel_Aviv_Yafo',
      left: '52%',
      top: '31%',
      className: "Israel-office-map",
      officeImage: "/assets/images/offices/hover/Israel.jpg"
    },
    {
      country: 'FOOTER.addressArea.UKRAINE.country',
      city: 'COMMON.Kyiv',
      left: '51%',
      top: '20%',
      className: "Ukraine-office-map",
      officeImage: "/assets/images/offices/hover/Ukraine_Kyiv.jpg"
    },
    {
      country: 'FOOTER.addressArea.Albania.country',
      city: 'FOOTER.addressArea.Albania.city',
      left: '47%',
      top: '26%',
      className: "Albania-office-map",
      officeImage: "/assets/images/offices/hover/Albania.jpg"
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  navigateToContactUs() {
    this.router.navigate(['/contact-us']);
  }
}
