<app-services-main-base [title]="'HEADER.Extended_Teams'" [textBody]="textBody">

  <app-dot class="extended-teams-95-95"></app-dot>
  <app-dot class="extended-teams-30-30"></app-dot>
  <app-dot class="extended-teams-19-19 desktop-only"></app-dot>

  <div class="image-right-part">
    <img src="/assets/svg/Ellipse132.svg" loading="lazy">
  </div>
</app-services-main-base>


<div class="staff-augmentation-container">
  <h2 class="staff-augmentation_title">
    {{'STAFF_AUGMENTATION.What_is_Staff_Augmentation'| translate}}
  </h2>

  <div class="staff-augmentation_body">

    <div class="staff-augmentation_body__block staff-augmentation_body__block-first">
      <div class="staff-augmentation_body__block-text">
        <div class="staff-augmentation_body__block-text-title">
          {{'STAFF_AUGMENTATION.How_does_staff_augmentation_help_my_business'| translate}}
        </div>

        <div class="staff-augmentation_body__block-text-description-container">
          <div class="staff-augmentation_body__block-text-description">
            {{'STAFF_AUGMENTATION.Staff_augmentation_allows_companies_to_add_talents_to_their_existing_workforce_for_success'|
            translate}}
          </div>

          <div class="staff-augmentation_body__block-text-description">
            {{'STAFF_AUGMENTATION.Companies_partner_with_suppliers_for_short_term_labor'| translate}}
          </div>
        </div>
      </div>

      <div class="staff-augmentation_body__block-image augmentation-help-image desktop-only"></div>
    </div>


    <div class="staff-augmentation_body__block staff-augmentation_body__block-second">
      <div class="staff-augmentation_body__block-image choose-staff-augmentation-image desktop-only">
        <app-dot class="dedicated-teams-280-280 desktop-only"></app-dot>
        <app-dot class="dedicated-teams-70-70 desktop-only"></app-dot>
      </div>

      <div class="staff-augmentation_body__block-text ">
        <div class="staff-augmentation_body__block-text-title">
          {{'STAFF_AUGMENTATION.When_should_I_choose_staff_augmentation'| translate}}
        </div>

        <div class="staff-augmentation_body__block-text-description-container">
          <div class="staff-augmentation_body__block-text-description">
            <div>
              {{'STAFF_AUGMENTATION.Consider_your_needs_goals_and_expectations_before'| translate}}
            </div>

            <div class="m-t-20">
              {{'STAFF_AUGMENTATION.Here_are_some_situations_where_it_can_be_beneficial'| translate}}
            </div>
          </div>
        </div>

        <div class="staff-augmentation_body__block-text-description-list-container">
          <ul>
            <li>{{'STAFF_AUGMENTATION.When_there_is_a_need_to_rapidly_boost_power'| translate}}</li>
            <li>{{'STAFF_AUGMENTATION.In_situations_requiring_access_to_developers_with_specialized_skills'|translate}}
            </li>
            <li>{{'STAFF_AUGMENTATION.Times_when_you_need_someone_with_expertise'| translate}}</li>
            <li>{{'STAFF_AUGMENTATION.When_a_business_requires_additional_assistance'| translate}}</li>
            <li>{{'STAFF_AUGMENTATION.When_a_company_needs_to_scale_quickly'| translate}}</li>
          </ul>
        </div>

        <div class="staff-augmentation_body__block-text-title Critical_skills_for_IT-title">
          {{'STAFF_AUGMENTATION.Critical_skills_for_IT_resource_augmentation'| translate}}
        </div>

        <div class="staff-augmentation_body__block-text-description-container">
          <div class="staff-augmentation_body__block-text-description">
            {{'STAFF_AUGMENTATION.The_most_critical_skills_to_look_for_in_IT_team_augmentation'| translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
