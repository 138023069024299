import { Injectable } from '@angular/core';
import { DialogFullScreenComponent } from '../shared/dialog-full-screen/dialog-full-screen.component';
import { FullScreenDialogData } from '../shared/models/dialog-full-screen.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogFullScreenService {

  constructor(public dialog: MatDialog) { }

  openDialog(dialogData: FullScreenDialogData) {
    this.dialog.open(DialogFullScreenComponent, {
      maxHeight: '100vh',
      //maxWidth: '100vw',
      height: '100vh',
      width: '100vw',
      data: dialogData,
    });
  }

}
