import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { KeyValuePair } from '../models/common.model';

@Component({
  selector: 'app-select-custom',
  templateUrl: './select-custom.component.html',
  styleUrls: ['./select-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCustomComponent implements OnInit {

  @Input() inputType = "text";
  @Input() placeholderText: string = "Select";
  @Input() formControlItem = new UntypedFormControl();

  @Input() dataOptions: KeyValuePair[] = [];


  constructor() { }

  ngOnInit() {
  }

}
