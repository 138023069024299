import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { CarouselSourceData } from 'src/app/shared/models/common.model';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuccessComponent implements OnInit, AfterViewInit {


  @Input() scrolOnInit = true;
  @Output() JumpTo: EventEmitter<any> = new EventEmitter<any>();


  public carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
    slide: 2,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  homeCarouselSourceData: CarouselSourceData[];

  constructor(
    public uiService: UiServiceService,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    this.loadhomeCarouselSourceData();

    if (this.scrolOnInit) {
      this.uiService.doScrolling(0, 300);
    }
  }

  ngAfterViewInit(): void {
    this.detectChanges();
  }

  scrollToContact(): void {
    this.JumpTo.emit('footer');
  }

  private loadhomeCarouselSourceData() {
    const homeCarouselSourceData = this.uiService.getHomeCarouselSourceData();

    this.homeCarouselSourceData = homeCarouselSourceData.concat(homeCarouselSourceData.slice(0, 2));
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr?.detectChanges();
    }
  }

}
