import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { UiServiceService } from '../../../services/ui-service.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-why-global-teams',
  templateUrl: './why-global-teams.component.html',
  styleUrls: ['./why-global-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhyGlobalTeamsComponent implements OnInit, OnDestroy {

  private routerSubscription: Subscription;

  constructor(private uiService: UiServiceService, private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit(): void {
    this.initializeData();

    if (isPlatformBrowser(this.platformId)) {
      this.uiService.doScrolling(0, 10);
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  private initializeData() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.routerSubscription = this.route.fragment.subscribe((fragment: string) => {
          if (fragment != null) {
            this.uiService.scroll(fragment, 1000, -60);
          }
        });
      }, 200);
    }
  }
}
