<!--

<div class="web" *ngIf="selectedIndex!=null">
  <div class="title">
    <span>{{"TRUSTED_BY.Clients"|translate}}</span>
    {{"COMMON.SHARE"|translate}}
  </div>
  <ng-container *ngFor="let item of teams;let i=index">

    <div *ngIf="selectedItemNo==i" [@inOutAnimation] class="testimonial" class="testIndex{{selectedItemNo}}">

      <img class="picture" src="//cdn.globalteams.ltd/assets/logos/{{teams[selectedIndex].picture}}"/>
      <div class="rightSide">
        <div class="content">
          {{teams[selectedIndex].testimonial | translate}}
        </div>
        <div class="writtenBy">
          {{teams[selectedIndex].author | translate}}
        </div>
        <div class="whatWeDo">{{teams[selectedIndex].extra | translate}}</div>
      </div>
    </div>

  </ng-container>


  <div class="clientsLogos">

    <ng-container *ngFor="let idx of this.teamsOrder;let i=index">

      <img [src]="selectedIndex==idx ? '//cdn.globalteams.ltd/assets/logos/' + this.teams[idx].logo  : '//cdn.globalteams.ltd/assets/logos/' + this.teams[idx].logoGray"
           style="cursor:pointer;" (click)="changeTestimonial(idx,i)"/>

    </ng-container>


  </div>
</div>
-->

<div class="web-mobile">
  <div class="title">
    {{"FOOTER.Clients_share"|translate}}
  </div>


  <ngu-carousel class="mobile" #myCarouselB [inputs]="carouselTiles" [dataSource]="teams"
    (carouselLoad)="caroselLoaded()">
    <ngu-tile *nguCarouselDef="let item; let index = index"
      [ngClass]="{'center-item-carousel-tile': isCenterItem(myCarouselB,index)}">
      <div class="testimonial" [ngClass]="{'center-item-carousel': isCenterItem(myCarouselB,index)}">

        <div class="testimonial-content" (mouseenter)="mouseEnter(isCenterItem(myCarouselB,index))"
          (mouseleave)="mouseLeave(isCenterItem(myCarouselB,index))">
          <div class="rightSide">
            <div class="testimonial-content-image">
              <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.0691 4.51056C7.89455 4.92642 6.36606 6.52591 5.48364 9.30902C5.04242 10.6846 4.82182 12.0441 4.82182 13.3877C4.82182 13.5477 4.82182 13.6916 4.82182 13.8196C4.85333 13.9155 4.88485 14.1555 4.91636 14.5393H10.0691V25H0V15.3551C0 10.6206 0.929697 6.97377 2.78909 4.41459C4.64848 1.85541 7.07515 0.383877 10.0691 0V4.51056ZM26 4.51056C24.2667 4.79846 22.943 5.79015 22.0291 7.4856C21.1467 9.18106 20.7055 11.1324 20.7055 13.3397C20.7055 13.5317 20.7055 13.7236 20.7055 13.9155C20.737 14.1075 20.7842 14.3154 20.8473 14.5393H26V25H15.8836V15.3551C15.8836 11.5483 16.6715 8.15739 18.2473 5.18234C19.823 2.1753 22.4073 0.447857 26 0V4.51056Z"
                  fill="#F3F3F3" />
              </svg>
            </div>

            <div>
              <div class="content">
                {{item.testimonial | translate}}
              </div>
              <div class="writtenBy">
                <div class="writtenBy-author">
                  {{item.author | translate}}
                </div>

                <div class="writtenBy-position">
                  {{item.position | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="writtenBy-company-logo-container">
            <img class="writtenBy-company-logo" src="assets/logos/new/white/{{item.logo}}" />
          </div>
        </div>

      </div>
    </ngu-tile>



    <div class="myPoints" NguCarouselPoint>

      <div class="carouselButton" *ngFor="let a of myCarouselB.pointNumbers; let index = index"
        [class.active]="index==myCarouselB.activePoint" (click)="myCarouselB.moveTo(index)">
      </div>

    </div>

  </ngu-carousel>

</div>
