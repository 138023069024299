<div class="page-not-found-container">
  <div class="page-not-found-header">
    {{"PAGE_NOT_FOUND.TITLE"|translate}}
  </div>

  <div class="page-not-found-body">
    <div>
      {{"PAGE_NOT_FOUND.THE_LINK_YOU_FOLLOWED_MAY_BE_BROKEN_OR_THE_PAGE_MIGHT_HAVE_MOVED"|translate}}
    </div>

    <div>
      {{"PAGE_NOT_FOUND.INTERESTING_IN_WORKING_WITH_GLOBAL_TEAMS_CLICK_THE_BUTTON_BELOW_TO_CONTACT_US"|translate}}
    </div>
  </div>

  <div class="page-not-found-button">
    <button routerLink="/contact-us" type="button">{{"HEADER.Lets_Talk"|translate}}</button>
  </div>
</div>
