import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { JobsService } from '../../../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiServiceService } from 'src/app/services/ui-service.service';
import { JobOpenPosition } from '../models/open-positions.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { SeoTagsService } from 'src/app/services/seo-tags.service';

@Component({
  selector: 'app-job-viewer',
  templateUrl: './job-viewer.component.html',
  styleUrls: ['./job-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobViewerComponent implements OnInit {

  public job$: Observable<JobOpenPosition>;

  constructor(
    private jobService: JobsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoTagsService: SeoTagsService,
    private uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
  ) {
  }

  ngOnInit(): void {
    this.uiService.scroll('header', 200, 0);
    const id = Number(this.activatedRoute.snapshot.params['id']);
    this.job$ = this.jobService.getJob(id);

    this.job$.subscribe(jobItem => {
      const jobTitle = jobItem?.jobTitle || "";
      this.seoTagsService.updateMetaTag(jobTitle);
      this.seoTagsService.setTitle(jobTitle);
    });
  }


  onButtonCVClicked(jobId: number) {
    this.updateDifferentStateService.emitLetsTalkCVForm(jobId);
  }

  openNavigateToOpenPositions() {
    this.router.navigate(['/career/open-positions']);
  }
}
