<div class="title">{{"READY_STEADY_GO.name"|translate}}</div>


<div class="numbers">

  <div class="lineShort"></div>
  <div class="box" (mouseover)="showing=1" (mouseleave)="showing=0">
    <div class="circle">
      <div class="subCircle">1</div>
    </div>
  </div>
  <div class="line"></div>

  <div class="box" (mouseover)="showing=2" (mouseleave)="showing=0">
    <div class="circle">
      <div class="subCircle">2</div>
    </div>
  </div>
  <div class="line"></div>
  <div class="box" (mouseover)="showing=3" (mouseleave)="showing=0">

    <div class="circle">
      <div class="subCircle">3</div>
    </div>
  </div>
  <div class="lineShort"></div>

</div>

<div class="descriptions">

  <div [@inOutAnimation] *ngIf="showing!==1">
    {{"READY_STEADY_GO.TELL_US"|translate}}
    <br/>
    {{"READY_STEADY_GO.YOUR_NEEDS"|translate}}
  </div>

  <div [ngClass]="{'smallerText':showing==1}" [@inOutAnimation] *ngIf="showing===1">
    {{"READY_STEADY_GO.Analyze_requirements_and_hand"|translate}}
    <br/>
    {{"READY_STEADY_GO.handpick_the_most_relevant_talent"|translate}}
  </div>

  <div class="middle" *ngIf="showing!=2" [@inOutAnimation]>
    {{"READY_STEADY_GO.Choose_Your"|translate}}
    <br/>
    {{"READY_STEADY_GO.Dream_Team"|translate}}
  </div>

  <div *ngIf="showing==2" [ngClass]="{'smallerText':showing==2}" class="middle" [@inOutAnimation]>
    {{"READY_STEADY_GO.Set_up_your_team"|translate}}
    <br/>
    {{"READY_STEADY_GO.according_on_your_needs"|translate}}
  </div>

  <div *ngIf="showing!=3" [@inOutAnimation]>
    {{"READY_STEADY_GO.Start"|translate}}
    <br/>
    {{"READY_STEADY_GO.Working"|translate}}
  </div>

  <div [ngClass]="{'smallerText':showing==3}" *ngIf="showing===3" [@inOutAnimation]>
    {{"READY_STEADY_GO.Run_back_office_management"|translate}}
    <br/>
    {{"READY_STEADY_GO.and_HR_retention"|translate}}
  </div>

</div>
