<div class="page">

  <div class="row-office">
    <div class="title">
      {{"COMMON.Our_Offices"|translate}}
    </div>

    <div class="ukraine-kyiv-office-container">
      <app-circle-with-text
        class="contact-us-ukraine-kyiv-office"
        [title]="'FOOTER.UKRAINE_KYIV'"
        [bodyText]="'FOOTER.Zlatoustivska_STREET'">
      </app-circle-with-text>
    </div>

    <div class="ukraine-lviv-office-container">
      <app-circle-with-text
        class="contact-us-ukraine-lviv-office"
        [title]="'FOOTER.UKRAINE_LVIV'"
        [bodyText]="'FOOTER.StepanaBandery_STREET'">
      </app-circle-with-text>
    </div>
  </div>

  <div class="row-office">
    <div class="spain-barcelona-office-container desktop-only">
      <app-circle-with-text
        class="contact-us-spain-barselona-office"
        [title]="'FOOTER.SPAIN_BARCELONA'"
        [bodyText]="'FOOTER.SPAIN_BARCELONA_Zlatoustivska_STREET'">
      </app-circle-with-text>
    </div>

    <div class="albania-office-container">
      <app-circle-with-text
        class="contact-us-albania-office"
        [title]="'FOOTER.ALBANIA'"
        [bodyText]="'FOOTER.ALBANIA_office'">
      </app-circle-with-text>
    </div>


    <div class="israel-office-container">
      <app-circle-with-text
        class="contact-us-israel-tel-aviv-office"
        [title]="'FOOTER.ISRAEL'"
        [subTitle]="'FOOTER.Tel_Aviv'"
        [bodyText]="'FOOTER.ISRAEL_Tel_Aviv_office'">
      </app-circle-with-text>
    </div>

    <div class="spain-barcelona-office-container mobile-only">
      <app-circle-with-text
        class="contact-us-spain-barselona-office"
        [title]="'FOOTER.SPAIN_BARCELONA'"
        [bodyText]="'FOOTER.SPAIN_BARCELONA_Zlatoustivska_STREET'">
      </app-circle-with-text>
    </div>

  </div>

</div>

<div>

  <app-contact-us [basicForm]="true" id="form"></app-contact-us>

</div>
