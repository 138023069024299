import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ImageCustomService } from 'src/app/services/image-custom.service';
import { TeamProfileInfo } from '../models/common.model';

@Component({
  selector: 'app-employee-position-hover-text',
  templateUrl: './employee-position-hover-text.component.html',
  styleUrls: ['./employee-position-hover-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeePositionHoverTextComponent implements OnInit {

  @Input() employeePositionProfileInfo: TeamProfileInfo;

  isHoverMode = false;

  constructor(private imageCustomService: ImageCustomService) { }

  ngOnInit() {
    this.isHoverMode = this.employeePositionProfileInfo?.isByDefaultVisibleText;
    this.imageCustomService.preloadImage(this.employeePositionProfileInfo?.imgPath);
  }

  onMouseenter() {
    this.isHoverMode = !this.isHoverMode;
  }

  onMouseleave() {
    this.isHoverMode = !this.isHoverMode;
  }

}
