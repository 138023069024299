<div class="container">
  <ng-content></ng-content>

  <div class="title-container">
    <h1 class="title">
      {{ title | translate }}
    </h1>
  </div>

  <div class="text-body" *ngIf="textBody">
    <div *ngFor="let paragraph of textBody" class="paragraph-item">
      {{ paragraph | translate }}
    </div>
  </div>

  <div class="button-container">
    <app-button-default (buttonClicked)="onButtonClicked()">
    </app-button-default>
  </div>

</div>
