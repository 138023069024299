import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-default',
  templateUrl: './button-default.component.html',
  styleUrls: ['./button-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonDefaultComponent implements OnInit {

  @Input() buttonText = "SERVICES.Extended_team.button";
  @Input() isEmitOnEnterClick: boolean;
  @Output() buttonClicked = new EventEmitter<void>();

  constructor() { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isEmitOnEnterClick && event.key === "Enter") {
      this.onButtonClicked();
    }
  }

  ngOnInit() {
  }

  onButtonClicked() {
    this.buttonClicked.emit();
  }

}
