import { isPlatformServer } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private readonly injector: Injector
  ) { }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      const response = this.injector.get(RESPONSE);
      response.status(404);
    }
  }

}
