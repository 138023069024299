import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JobOpenPosition, OpenPositionFilterItem, SearchFilterFormData } from '../pages/career/open-positions/models/open-positions.model';
import { map, tap } from 'rxjs/operators';
import { HelpersService } from './helpers.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { KeyValuePair } from '../shared/models/common.model';
import { JobsApiServiceBase } from './JobsApiServiceBase';


@Injectable({
  providedIn: 'root'
})
export class JobsService {

  private jobsCached: JobOpenPosition[];

  constructor(
    private http: HttpClient,
    private jobsApiService: JobsApiServiceBase,
    private fb: UntypedFormBuilder,
    private helpersService: HelpersService) {
  }

  sendCV(form: any): Observable<any> {
    const headers = new HttpHeaders().set('content-type', 'application/json').set('accept', 'application/json');

    return this.http.post<any>(
      'https://api.globalteams.ltd/api/emailsender/emailNoAttachment', form, { 'headers': headers });
  }

  getJob(id: number): Observable<JobOpenPosition> {
    return this.getJobs()
      .pipe(map(jobs => jobs?.find(x => x.id === id)));
  }

  clearJobsCache() {
    this.jobsCached = null;
  }

  getJobs(): Observable<JobOpenPosition[]> {
    if (this.jobsCached) {
      return of(this.jobsCached);
    }

    return this.jobsApiService.getJobs()
      .pipe(tap(jobs => this.jobsCached = jobs));
  }

  getFilters(): Observable<OpenPositionFilterItem[]> {
    return this.getJobs()
      .pipe(map((jobs: JobOpenPosition[]) => this.buildFilters(jobs)));
  }

  private buildFilters(jobs: JobOpenPosition[]): OpenPositionFilterItem[] {
    const tags = {};

    jobs?.forEach(jobItem => {
      jobItem.techTags?.forEach(tagName => {
        tags[tagName] = tags[tagName] ? tags[tagName] + 1 : 1
      });
    });

    const filters: OpenPositionFilterItem[] = [];

    for (const tagName in tags) {

      const openPositionFilterItem = new OpenPositionFilterItem();
      openPositionFilterItem.tag = tagName;
      openPositionFilterItem.itemCount = Number(tags[tagName]);

      filters.push(openPositionFilterItem);
    }

    return filters.sort((a, b) => b.itemCount - a.itemCount);
  }


  filterJobsByCategory(tagName: string, activeFilters: { [tagName: string]: boolean } = {}) {
    if (activeFilters[tagName]) {
      delete activeFilters[tagName];
    } else {
      activeFilters[tagName] = true;
    }

    return this.getJobs().pipe(map(jobs => {
      let jobsCache = jobs;

      if (!this.helpersService.isActiveFiltersEmpty(activeFilters)) {
        jobsCache = jobs.filter(item => item.techTags?.some(tag => activeFilters[tag]));
      }

      return jobsCache;
    }));
  }

  filterJobsByMainFilters(filterItems: SearchFilterFormData) {
    return this.getJobs().pipe(map(jobs => {
      const filteredData = jobs.filter(item => this.filterJobsBySearhFormValue(item, filterItems));

      return filteredData;
    }));
  }

  filterJobsBySearhFormValue(item: JobOpenPosition, filterItems: SearchFilterFormData) {
    for (const key in filterItems) {
      const filterValue: string = filterItems[key]?.toLowerCase();

      if (!filterValue || filterValue === 'null') {
        continue;
      }

      switch (key) {
        case "search":
          const searchInTechTags = item.techTags?.some(x => x.toLowerCase().includes(filterValue));

          const regExpSearch = new RegExp(`${filterValue.split(" ").join("|")}`, "g");
          const searchInJobTitle = item.jobTitle.toLowerCase()?.match(regExpSearch);

          if (!searchInTechTags && !Boolean(searchInJobTitle)) {
            return false;
          }
          break;
        case "positionLevel":
          if (!item.positionLevel?.toLowerCase().includes(filterValue)) {
            return false;
          }
          break;
        case "location":
          if (!item.locationCity?.toLowerCase().includes(filterValue) &&
            !item.locationCountry?.toLowerCase().includes(filterValue)) {
            return false;
          }
          break;
        case "shortBy":
          if (!item.shortBy?.toLowerCase().includes(filterValue)) {
            return false;
          }
          break;
      }
    }

    return true;
  }

  getSearchFilterForm(): UntypedFormGroup {
    const formGroup = this.fb.group({
      search: [],
      positionLevel: [],
      location: [],
      shortBy: []
    });

    return formGroup;
  }

  enumToKeyValuePair(enumName: Object) {
    const dataOptions: KeyValuePair[] = [];

    const jobPositionLevelList = enumName;

    for (const key in jobPositionLevelList) {
      const dataOptionItem = new KeyValuePair();
      dataOptionItem.key = jobPositionLevelList[key];
      dataOptionItem.value = key;

      dataOptions.push(dataOptionItem);
    }

    return dataOptions;
  }

}
