import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IWorkingAtGlobalImageItem, IWorkingAtGlobalImages } from '../shared/models/working-at-global.model';
import { ImageCustomService } from './image-custom.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WorkingAtGlobalService {

  constructor(
    private imageCustomService: ImageCustomService,
    @Inject(PLATFORM_ID) private platformId: Object
    ) {
  }


  private readonly imagesList: IWorkingAtGlobalImages[] = [
    {
      leftSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/1/1.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/1.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/1/4.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/4.png'
        }
      },
      centerSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/1/2.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/2.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/1/5.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/5.png'
        }
      },
      rightSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/1/3.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/3.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/1/6.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/1/6.png'
        }
      },
    },
    {
      leftSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/2/1.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/1.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/2/4.jpeg',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/4.png'
        }
      },
      centerSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/2/2.JPG',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/2.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/2/5.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/5.png'
        }
      },
      rightSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/2/3.JPG',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/3.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/2/6.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/2/6.png'
        }
      },
    },
    {
      leftSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/3/1.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/1.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/3/4.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/4.png'
        }
      },
      centerSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/3/2.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/2.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/3/5.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/5.png'
        }
      },
      rightSection: {
        topImage: {
          image: 'assets/images/workingAtGlobal/full/3/3.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/3.png'
        },
        bottomImage: {
          image: 'assets/images/workingAtGlobal/full/3/6.jpg',
          thumbImage: 'assets/images/workingAtGlobal/cut/3/6.png'
        }
      },
    },

  ];

  getImagesList() {
    return this.imagesList;
  }

  preloadAllImages() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => this.preloadImages(), 500);
    }
  }

  private preloadImages() {
    this.imagesList.forEach(element => {

      for (const key in element) {
        const sectionLevel: IWorkingAtGlobalImageItem = element[key];

        for (const subKey in sectionLevel) {
          const secondLevel: IWorkingAtGlobalImageItem = sectionLevel[subKey];

          for (const subKey in secondLevel) {
            const imagePath: string = secondLevel[subKey];
            this.imageCustomService.preloadImage(imagePath);
          }
        }
      }
    });
  }
}
