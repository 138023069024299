import { Injectable } from '@angular/core';
import { EmployeeProfileInfo, TeamProfileInfo } from '../shared/models/common.model'

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor() { }

  public imageObject = [
    {
      image: 'assets/images/about/1b.jpg',
      thumbImage: 'assets/images/about/1c.png',
    },
    {
      image: 'assets/images/about/2b.jpg',
      thumbImage: 'assets/images/about/2c.png',
    },
    {
      image: 'assets/images/about/3b.jpg',
      thumbImage: 'assets/images/about/3c.png',
    },
    {
      image: 'assets/images/about/4b.jpg',
      thumbImage: 'assets/images/about/4c.png',
    },
    {
      image: 'assets/images/about/5b.jpg',
      thumbImage: 'assets/images/about/5c.png',
    },
    {
      image: 'assets/images/about/6b.jpg',
      thumbImage: 'assets/images/about/6c.jpg',
    },
    {
      image: 'assets/images/about/7b.jpg',
      thumbImage: 'assets/images/about/7c.jpg',
    }
  ];

  private homeImgDesktop: EmployeeProfileInfo[] = [
    {
      imgPath: 'assets/images/home/1.jpg',
      animationDelay: '0.5s',
      width: '130px',
      height: '130px',
      left: '0',
      top: '157px',
      nextImgPath: 'assets/images/home/1-1.jpg',
      interval: 1500
    },
    {
      imgPath: 'assets/images/home/2.jpg',
      animationDelay: '1.0s',
      width: '100px',
      height: '100px',
      left: '456px',
      top: '80px',
      nextImgPath: 'assets/images/home/2-1.jpg',
      interval: 2000
    },
    {
      imgPath: 'assets/images/home/3.jpg',
      animationDelay: '1.5s',
      width: '120px',
      height: '120px',
      top: '130px',
      right: '100px',
      interval: 2500
    },
    {
      imgPath: 'assets/images/home/4.jpg',
      animationDelay: '2.0s',
      width: '128px',
      height: '128px',
      right: '-100px',
      top: '433px',
      nextImgPath: 'assets/images/home/4-1.jpg',
      interval: 3500
    },
    {
      imgPath: 'assets/images/home/5.jpg',
      animationDelay: '0.5s',
      width: '153px',
      height: '153px',
      top: '80%',
      right: '15%',
      nextImgPath: 'assets/images/home/5-1.jpg',
      interval: 4000
    },
    {
      imgPath: 'assets/images/home/6.jpg',
      animationDelay: '1.5s',
      width: '120px',
      height: '120px',
      left: '10%',
      top: '80%',
      nextImgPath: 'assets/images/home/6-1.jpg',
      interval: 4500
    },
    {
      imgPath: 'assets/images/home/7.jpg',
      animationDelay: '1.5s',
      width: '156px',
      height: '156px',
      left: '-20px',
      top: '572px',
      nextImgPath: 'assets/images/home/7-1.jpg',
      interval: 4600
    },
  ];


  private homeImgMobile: EmployeeProfileInfo[] = [
    {
      imgPath: 'assets/images/home/1.jpg',
      animationDelay: '0.5s',
      width: '130px',
      height: '130px',
      left: '-50px',
      top: '100px'
    },
    {
      imgPath: 'assets/images/home/2.jpg',
      animationDelay: '1.0s',
      width: '100px',
      height: '100px',
      left: '50%',
      top: '67px'
    },
    {
      imgPath: 'assets/images/home/3.jpg',
      animationDelay: '1.5s',
      width: '60px',
      height: '120px',
      top: '137px',
      right: '0',
      borderRadius: '80px 0 0 80px'
    },
    {
      imgPath: 'assets/images/home/4.jpg',
      animationDelay: '2.0s',
      width: '128px',
      height: '128px',
      right: '0',
      top: '500px'
    },
    // {
    //   imgPath: 'assets/images/home/5.jpg',
    //   animationDelay: '0.5s',
    //   width: '153px',
    //   height: '153px',
    //   right: '20px',
    //   top: '662px'
    // },
    {
      imgPath: 'assets/images/home/6.jpg',
      animationDelay: '1.5s',
      width: '120px',
      height: '120px',
      left: '10px',
      top: '580px'
    },
  ];


  private teamsList: TeamProfileInfo[] = [
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse179.jpg',
      fullName: 'Roman Lytvyn',
      fullNameShort: "Roman L.",
      position: 'Full Stack Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse211.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E',
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse166.jpg',
      fullName: 'Alina Gritsan',
      fullNameShort: "Alina G.",
      position: 'QA Engineer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse184.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse181.jpg',
      fullName: 'Samvel Sarkisian',
      fullNameShort: "Samvel S.",
      position: 'Team Lead Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse210.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E',
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse140.jpg',
      fullName: 'Aleksandra Morozova',
      fullNameShort: "Aleksandra M.",
      position: 'Web Designer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse206.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse150.jpg',
      fullName: 'Oleksandr Gyshchak',
      fullNameShort: "Oleksandr G.",
      position: 'Full Stack Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse189.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse162.jpg',
      fullName: 'Igor Udod',
      fullNameShort: "Igor U.",
      position: 'QA Engineer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse192.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse151.jpg',
      fullName: 'Kristina Novykova',
      fullNameShort: "Kristina N.",
      position: 'IT Recruiter',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse194.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse142.jpg',
      fullName: 'Pavlo Sukhonos',
      fullNameShort: "Pavlo S.",
      position: 'Data Analyst',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse196.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse153.jpg',
      fullName: 'Liza Pazhukova',
      fullNameShort: "Liza P.",
      position: 'Full Stack Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse185.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse154.jpg',
      fullName: 'Andrii Sobol',
      fullNameShort: "Andrii S.",
      position: 'Full Stack Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse190.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse155.jpg',
      fullName: 'Anna Bilytskykh',
      fullNameShort: "Anna B.",
      position: 'QA Engineer (TL)',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse198.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse152.jpg',
      fullName: 'Viacheslav Tyshchenko',
      fullNameShort: "Viacheslav T.",
      position: 'React Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse201.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse161.jpg',
      fullName: 'Rostyslav Panichev',
      fullNameShort: "Rostyslav P.",
      position: 'Content Manager',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse187.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse168.jpg',
      fullName: 'Anastasiia Chernobuy',
      fullNameShort: "Anastasiia C.",
      position: 'Office Manager/HR assistant',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse188.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse141.jpg',
      fullName: 'Kostiantyn Posudevskyi',
      fullNameShort: "Kostiantyn P.",
      position: 'QA Engineer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse208.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse164.jpg',
      fullName: 'Kostiantyn Kalinchuk',
      fullNameShort: "Kostiantyn K.",
      position: 'Full Stack Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse204.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse178.jpg',
      fullName: 'Anastasiia Zakharchenko',
      fullNameShort: "Anastasiia Z.",
      position: 'Data Analyst',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse205.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse165.jpg',
      fullName: 'Pavlo Danilov',
      fullNameShort: "Pavlo D.",
      position: 'IT Manager',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse183.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse139.jpg',
      fullName: 'Illia Korotchenko',
      fullNameShort: "Illia K.",
      position: 'Data Analyst',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse195.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse180.jpg',
      fullName: 'Ruslan Udintsev',
      fullNameShort: "Ruslan U.",
      position: 'WordPress Developer (TL)',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse207.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse138.jpg',
      fullName: 'Iryna Sukhonda',
      fullNameShort: "Iryna S.",
      position: 'Content Team Lead',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse182.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse170.jpg',
      fullName: 'Sergey Pogudin',
      fullNameShort: "Sergey P.",
      position: 'QA Engineer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse191.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse156.jpg',
      fullName: 'Andrii Mazur',
      fullNameShort: "Andrii M.",
      position: 'Support Specialist',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse197.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse163.jpg',
      fullName: 'Valentina Glushchenko',
      fullNameShort: "Valentina G.",
      position: 'IT Recruiter',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse212.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse167.jpg',
      fullName: 'Olha Shavra',
      fullNameShort: "Olha S.",
      position: 'General Manager',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse186.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E',
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse176.jpg',
      fullName: 'Denys Kihno',
      fullNameShort: "Denys K.",
      position: 'Back End Developer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse203.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse173.jpg',
      fullName: 'Daria Dashchenko',
      fullNameShort: "Daria D.",
      position: 'Graphic Designer (TL)',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse199.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E',
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse169.jpg',
      fullName: 'Ivan Dyvak',
      fullNameShort: "Ivan D.",
      position: 'Content Manager',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse209.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse175.jpg',
      fullName: 'Oleg Serdiuk',
      fullNameShort: "Oleg S.",
      position: 'DevOps',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse202.png',
      hoverBackgroundColor: 'var(--color-light-blue)',
      hoverTextColor: '#02102E',
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse171.jpg',
      fullName: 'Anna Rubanova',
      fullNameShort: "Anna R.",
      position: 'UI/UX Designer',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse193.png',
      hoverBackgroundColor: '#89DB98',
      hoverTextColor: '#FFFFFF'
    },
    {
      imgPath: 'assets/images/about/our-team/display/Ellipse174.jpg',
      fullName: 'Serhii Honcharenko',
      fullNameShort: "Serhii H.",
      position: 'English Teacher',
      hoverImgPath: 'assets/images/about/our-team/hover/Ellipse200.png',
      hoverBackgroundColor: '#0A2452',
      hoverTextColor: '#89DB98'
    }

  ];

  getRandomEmployees(count = 6): TeamProfileInfo[] {
    const maxItem = this.teamsList.length - 1;
    const randomEmployees: { [randomNumber: number]: TeamProfileInfo } = {};

    let i = 0;

    while (i < count) {
      const itemNo = Math.round(Math.random() * maxItem);

      if (!randomEmployees[itemNo]) {
        randomEmployees[itemNo] = this.teamsList[itemNo];
        i++;
      }
    }


    return Object.values(randomEmployees);
  }

  getEmployeePositionImgInfo(): TeamProfileInfo[] {
    return this.teamsList;
  }

  getHomeImg(isMobile: boolean): EmployeeProfileInfo[] {
    return isMobile ? [] : this.homeImgDesktop;
  }

  getOurTeams(): TeamProfileInfo[] {
    return this.teamsList;
  }

}
