<app-services-main-base [title]="'HEADER.End_to_End_Projects'" [textBody]="textBody">

  <app-dot class="managed-teams-65-65 desktop-only"></app-dot>
  <app-dot class="managed-teams-30-30"></app-dot>
  <app-dot class="managed-teams-95-95 desktop-only"></app-dot>

  <div class="image-right-part">
    <img src="/assets/svg/Ellipse132.svg" loading="lazy">
  </div>
</app-services-main-base>


<div class="end-to-end-projects-supporting-vectors-wrapper">
  <div class="end-to-end-projects-supporting-vectors-container">
    <h2 class="end-to-end-projects-supporting-vectors-title">
      {{'SUPPORTING_SERVICES.End_to_End_Project_Management_Supporting_Vectors'| translate}}
    </h2>

    <div class="end-to-end-projects-supporting-vectors-body">

      <div class="end-to-end-projects-supporting-vectors-body__block">
        <div class="end-to-end-projects-supporting-vectors-body__block__image">
          <img src="/assets/svg/end-to-end-projects/1.svg" loading="lazy">
        </div>

        <div class="end-to-end-projects-supporting-vectors-body__block-content">
          <div class="end-to-end-projects-supporting-vectors-body__block-title">
            {{'HEADER.QA'| translate}}
          </div>

          <div class="end-to-end-projects-supporting-vectors-body__block-description">
            {{'SUPPORTING_SERVICES.QA_DESC'| translate}}
          </div>
        </div>
      </div>


      <div class="end-to-end-projects-supporting-vectors-body__block">
        <div class="end-to-end-projects-supporting-vectors-body__block__image">
          <img src="/assets/svg/end-to-end-projects/2.svg" loading="lazy">
        </div>

        <div class="end-to-end-projects-supporting-vectors-body__block-content">
          <div class="end-to-end-projects-supporting-vectors-body__block-title">
            {{'HEADER.UI_UX'| translate}}
          </div>

          <div class="end-to-end-projects-supporting-vectors-body__block-description">
            {{'SUPPORTING_SERVICES.UI_UX_desc'| translate}}
          </div>
        </div>
      </div>


      <div class="end-to-end-projects-supporting-vectors-body__block">
        <div class="end-to-end-projects-supporting-vectors-body__block__image">
          <img src="/assets/svg/end-to-end-projects/3.svg" loading="lazy">
        </div>

        <div class="end-to-end-projects-supporting-vectors-body__block-content">
          <div class="end-to-end-projects-supporting-vectors-body__block-title">
            {{'HEADER.Specialist_Support'| translate}}
          </div>

          <div class="end-to-end-projects-supporting-vectors-body__block-description">
            {{'SUPPORTING_SERVICES.Specialist_Support_desc'| translate}}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
