import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UiServiceService } from '../../../services/ui-service.service';

@Component({
  selector: 'app-dedicated-teams',
  templateUrl: './dedicated-teams.component.html',
  styleUrls: ['./dedicated-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DedicatedTeamsComponent implements OnInit {

  textBody: string[] = [
    'STAFF_AUGMENTATION.Are_you_looking_to_hire_an_expert_with_a_specific_skill',
    'STAFF_AUGMENTATION.We_augment_your_team_of_talents_with_all_the_expert',
    'STAFF_AUGMENTATION.Our_professionals_have_experience_in_various_industries_and_technologies'
  ];

  constructor(private uiService: UiServiceService) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 0);
  }


}
