<h2 class="title">
  {{"HEADER.Services"|translate}}
  <div class="bubble-green"></div>
</h2>

<div class="items">

  <div class="services-container">
    <div class="services-title">

      <ng-container *ngFor="let item of servisesHomePage| keyvalue: asIsOrder">
        <div class="services-title-item" [class.active]="item.key === selectedService"
          (click)="onServiceClicked(item.key, item.value)">
          {{ item.key | translate }}
        </div>
      </ng-container>

    </div>

    <div class="selected-services-desc desktop-only">
      <ng-container *ngFor="let item of displayedDescription">
        <div class="selected-services-title-item" *ngIf="item.title">
          {{ item.title | translate }}
        </div>

        <div class="selected-services-desc-item" *ngIf="item.isBlockElement !== false">
          {{ item.text | translate }}
        </div>

        <span class="selected-services-desc-item {{item.additionalClass}}" *ngIf="item.isBlockElement === false"
          (click)="navigateTo(item.route, item.routeFragment)">
          {{ item.text | translate }}
        </span>
      </ng-container>

      <div class=" lets-talk-container" (click)="openLetsTalk()">
        <span>
          {{"HEADER.Lets_Talk"|translate}}
        </span>
      </div>

    </div>
  </div>


</div>
<app-rotating-technologies></app-rotating-technologies>
