<ng-container *ngIf="dataOptions">

  <select type="text" [formControl]="formControlItem" *ngIf="formControlItem">
    <!--placeholder start-->
    <option [value]="null" default>{{placeholderText|translate}}</option>
    <!--placeholder end-->

    <option *ngFor="let item of dataOptions" [value]="item.value">
      {{item.key | translate}}
    </option>
  </select>
</ng-container>
