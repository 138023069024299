<div class="title">
  {{"FOOTER.Your_success_is_our_KPI"|translate}}
</div>

<!--
<div class="successBoxes">

  <div class="box" id="successContainer1" [ngClass]="{'slide-in-fwd-center':inViews[0], 'removeAnim':hovered[0]}"
       [routerLink]="'/company/why-global-teams'" fragment="competitve">
    <div class="picture competitivePricing"></div>
    <div class="subtitle">{{"WHY_GLOBAL_TEAMS.Competitive_pricing"|translate}}</div>
    <div class="content">{{"WHY_OUR_SERVICES.Competitive_content"|translate}}</div>
  </div>

  <div class="box" id="successContainer2" [ngClass]="{'slide-in-fwd-center':inViews[1], 'removeAnim':hovered[1]}"
       [routerLink]="'/company/why-global-teams'" fragment="flexibility">
    <div class="picture flexibility"></div>
    <div class="subtitle">{{"WHY_OUR_SERVICES.Flexibility"|translate}}</div>
    <div class="content">
      {{"WHY_OUR_SERVICES.No_isnt_in_our_vocabulary"|translate}}
      {{"WHY_OUR_SERVICES.We_are_always_ready_to_rise"|translate}}
    </div>
  </div>

  <div class="box" id="successContainer3" [ngClass]="{'slide-in-fwd-center':inViews[2], 'removeAnim':hovered[2]}"
       [routerLink]="'/company/why-global-teams'" fragment="handpicked">
    <div class="picture handpicked"></div>
    <div class="subtitle">
      {{"WHY_OUR_SERVICES.Handpicked"|translate}}
      {{"WHY_OUR_SERVICES.Talents"|translate}}
    </div>
    <div class="content">
      {{"WHY_OUR_SERVICES.Whether_you_are_building_a_team_from_scratch"|translate}}
    </div>
  </div>

  <div class="box" id="successContainer4" [ngClass]="{'slide-in-fwd-center':inViews[3], 'removeAnim':hovered[3]}"
       [routerLink]="'/company/why-global-teams'" fragment="fullbackoffice">
    <div class="picture fullbackoffice"></div>
    <div class="subtitle">
      {{"WHY_OUR_SERVICES.Full_back_office"|translate}}
      {{"WHY_OUR_SERVICES.services"|translate}}
    </div>
    <div class="content">
      {{"WHY_OUR_SERVICES.We_take_over_all_the_hassle_of_managing_overhead"|translate}}
    </div>
  </div>

  <div class="box" id="successContainer5" [ngClass]="{'slide-in-fwd-center':inViews[4], 'removeAnim':hovered[4]}"
       [routerLink]="'/company/why-global-teams'" fragment="peopleoriendted">
    <div class="picture peopleoriented"></div>
    <div class="subtitle">{{"WHY_OUR_SERVICES.People_oriented"|translate}}</div>
    <div class="content">
      {{"WHY_OUR_SERVICES.We_invest_in_our_team_members"|translate}}
    </div>
  </div>

  <div class="box" id="successContainer6" [ngClass]="{'slide-in-fwd-center':inViews[5], 'removeAnim':hovered[5]}"
       (click)="scrollToContact()">
    <div class="globalTeamsIcon contentLogo"></div>
    <div class="ContactUs">{{"WHY_OUR_SERVICES.GET_STARTED_NOW"|translate}}</div>
  </div>

</div>

<h1 class="readyTitle">
  {{"READY_STEADY_GO.name"|translate}}
</h1>

<div style="    -webkit-perspective: 1000;">
  <div class="numbering" id="animatedNumbering" [ngClass]="{'slide-fwd-center':animateNumbering}">


    <div class="headline" [ngClass]="{'text-focus-in':animateNumbering}">
      <span class="text">
        {{"READY_STEADY_GO.TELL_US"|translate}} <br/>{{"READY_STEADY_GO.YOUR_NEEDS"|translate}}
      </span>
      <span class="hoveredText">
        {{"READY_STEADY_GO.Analyze_requirements_and"|translate}}
        <br/>
        {{"READY_STEADY_GO.hand_handpick_the_most"|translate}}
        <br/>
        {{"READY_STEADY_GO.relevant_talent"|translate}}
      </span>
    </div>
    <div class="headline" [ngClass]="{'text-focus-in':animateNumbering}">
      <span class="text">
        {{"READY_STEADY_GO.Choose_Your"|translate}}
        <br/>
        {{"READY_STEADY_GO.Dream_Team"|translate}}
      </span>
      <span class="hoveredText">
        {{"READY_STEADY_GO.Set_up_your_team"|translate}}
        <br/>
        {{"READY_STEADY_GO.according_on_your_needs"|translate}}
      </span>

    </div>
    <div class="headline" [ngClass]="{'text-focus-in':animateNumbering}">
      <span class="text">
        {{"READY_STEADY_GO.Start"|translate}}
        <br/>
        {{"READY_STEADY_GO.Working"|translate}}
      </span>
      <span class="hoveredText">
        {{"HOW_IT_WORKS.Run_back_office"|translate}}
        <br/>{{"HOW_IT_WORKS.management_and_HR"|translate}}
        <br/>{{"HOW_IT_WORKS.retention"|translate}}
      </span>

    </div>
  </div>
</div>

<div class="letsTalk" id="buttonBouncer" [ngClass]="{'bounce-top':buttonBouncer}" (click)="scrollToContact()">
  {{"HEADER.Lets_Talk"|translate}}
</div>

-->

<ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="homeCarouselSourceData">
  <ngu-tile *nguCarouselDef="let item; let i = index">
    <div
      class="tile carousel-Tile-item"
      [style.background-image]="'url(' + item.imgPath + ')'"
      >
      <div class="subtitle">
        <h1>{{ item.text | translate}}</h1>
      </div>

      <div class="text-hover">
        <h1>{{ item.text | translate}}</h1>

        <div class="text-hover-description" *ngIf="item.hoverText">
          <div
             class="text-hover-description-item"
             *ngFor="let hoverText of item.hoverText"
             [routerLink]="item.route"
             [fragment]="item.routeFragment">
            {{ hoverText | translate}}
          </div>
        </div>
      </div>
    </div>
  </ngu-tile>

  <ul class="myPoint-mobile" NguCarouselPoint>
    <button NguCarouselPrev class="leftRs">
      &#8592;
    </button>

    <ng-container *ngFor="let i of myCarousel.pointNumbers; let index = index">
      <div *ngIf="index==myCarousel.activePoint" class="active">
        {{ index+1}} / {{myCarousel?.pointNumbers?.length}}
      </div>
    </ng-container>

    <button NguCarouselNext class="rightRs">
      &#x2192;
    </button>

  </ul>

</ngu-carousel>

