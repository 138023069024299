import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JobOpenPosition } from '../pages/career/open-positions/models/open-positions.model';
import { StateKey, TransferState, makeStateKey } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { JobsApiServiceBase } from './JobsApiServiceBase';

@Injectable({
  providedIn: 'root'
})
export class JobsApiService implements JobsApiServiceBase {

  constructor(
    private http: HttpClient,
    private transferState: TransferState
  ) { }

  getJobs(): Observable<JobOpenPosition[]> {
    const key: StateKey<JobOpenPosition[]> = makeStateKey<JobOpenPosition[]>("transfer-translate-jobs");

    const data = this.transferState.get(key, null);

    if (data) {
      return of(data);
    }

    return this.http.get<JobOpenPosition[]>('assets/jobs/jobs.json');
  }

}
