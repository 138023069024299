import { Component, OnInit } from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-ui-ux',
  templateUrl: './ui-ux.component.html',
  styleUrls: ['./ui-ux.component.scss']
})
export class UiUxComponent implements OnInit {

  constructor(
    private uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
    ) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 300);
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

}
