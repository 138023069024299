import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FullScreenDialogData } from '../../shared/models/dialog-full-screen.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-full-screen',
  templateUrl: './dialog-full-screen.component.html',
  styleUrls: ['./dialog-full-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogFullScreenComponent implements OnInit {

  isImgVisible: boolean;

  constructor(
    private dialogRef: MatDialogRef<DialogFullScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: FullScreenDialogData) {
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onImgLoad() {
    this.isImgVisible = true;
  }

}
