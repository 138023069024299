
<div class="container">

  <div class="circle-half">
    <app-dot class="meet-our-specialists-2"></app-dot>
  </div>

  <div class="row">
    <div class="col">
      <div class="title">
        {{'HEADER.Meet_Our_Specialists' | translate}}
      </div>
    </div>

    <div class="col employeePosition-Team-Lead-Developer">
      <app-dot class="meet-our-specialists-1"></app-dot>

      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[0]">
      </app-employee-position-hover-text>
    </div>

    <div class="col employeePosition-UI-UX">
      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[1]">
      </app-employee-position-hover-text>
    </div>
  </div>

  <div class="row">
    <div class="col employeePosition-Frontend">
      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[2]">
      </app-employee-position-hover-text>
    </div>

    <div class="col employeePosition-Backend">
      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[3]">
      </app-employee-position-hover-text>
    </div>
  </div>

  <div class="row">
    <div class="col employeePosition-hr">
      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[4]">
      </app-employee-position-hover-text>
    </div>

    <div class="col employeePosition-QA-Engineer">
      <app-employee-position-hover-text [employeePositionProfileInfo]="ourSpecialists[5]">
      </app-employee-position-hover-text>
    </div>
  </div>
</div>
