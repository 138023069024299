<header>
  <app-header></app-header>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<footer>
  <app-footer></app-footer>
  <app-cookies></app-cookies>
</footer>
