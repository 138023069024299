<div class="title">
  {{title[0] | translate }}
  {{title[1] | translate}}
</div>

<div class="itemsWrapper">
  <div class="items">

    <ng-container *ngFor="let item of items">
      <div class="boxItem"  [routerLink]="item.url" [fragment]="item.fragment">
        <div class="subtitle">{{item.title | translate}}</div>
        <div class="description">{{item.desc | translate}}
        </div>
        <div class="button">
          <img src="/assets/svg/arrow-right.svg" alt="up">
        </div>
      </div>
    </ng-container>
  </div>
</div>

