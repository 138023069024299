import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { UiServiceService } from '../../../services/ui-service.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-supporting',
  templateUrl: './supporting.component.html',
  styleUrls: ['./supporting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportingComponent implements AfterViewInit , OnDestroy {

  private routerSubscription: Subscription;

  textBody: string[] = [
    'SUPPORTING_SERVICES.Global_Teams_offers_flexible_staffing_solutions'
  ];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private updateDifferentStateService: UpdateDifferentStateService,
    private uiService: UiServiceService,
    private route: ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.routerSubscription = this.route.fragment.subscribe((fragment: string) => {
          if (fragment != null) {
            this.uiService.scroll(fragment, 1000, -60);
          }
        });
      }, 0);
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }
}
