import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BenefitsItemData, CarouselSourceData, ServisesHomePageData } from '../shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class UiServiceService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }


  readonly defaultServiseOnHomePage = "HEADER.Extended_Teams";

  readonly servisesHomePage: { [key: string]: ServisesHomePageData } = {
    "HEADER.Extended_Teams": {
      data: [
        {
          title: "SUPPORTING_SERVICES.Supercharge_Your_Team_with_Staff_Augmentation",
          text: "SUPPORTING_SERVICES.Staff_augmentation_is_the_goto_solution"
        },
        {
          text: "SUPPORTING_SERVICES.IT_firms_are_embracing_staff_augmentation_over_traditional_hiring"
        },
      ],
      route: '/services/extended-teams'
    },
    "HEADER.Managed_Teams": {
      data: [
        {
          title: "SUPPORTING_SERVICES.Unleash_Innovation_with_Dedicated_Teams",
          text: "SUPPORTING_SERVICES.Dedicated_teams_operate_as_self_contained_autonomous"
        },
        {
          text: "SUPPORTING_SERVICES.Our_seasoned_IT_project_managers_bring_a_wealth"
        }
      ],
      route: '/services/managed-teams'
    },
    "HEADER.Specialist_Support": {
      data: [
        {
          title: "SUPPORTING_SERVICES.Expert_Help_When_You_Need_It_Most",
          text: "SUPPORTING_SERVICES.When_unexpected_challenges_arise"
        },
        {
          text: "SUPPORTING_SERVICES.Among_our_talents_are_top_tech_leaders_with"
        }
      ],
      route: '/services/supporting-services',
      routeFragment: 'specialistsupport'
    },
    "HEADER.End_to_End_Projects": {
      data: [
        {
          title: "SUPPORTING_SERVICES.From_Vision_to_Reality_Complete_Project_Execution",
          text: "SUPPORTING_SERVICES.We_assemble_team_of_expert_talents_equipped"
        },
        {
          text: "SUPPORTING_SERVICES.Our_professionals_possess_experience_in_diverse_industries"
        }
      ],
      route: '/services/end-to-end-projects'
    },
    "HEADER.QA": {
      data: [
        {
          title: "SUPPORTING_SERVICES.From_Vision_to_Reality_Complete_Project_Execution",
          text: "SUPPORTING_SERVICES.In_the_fast_paced_world_of_agile_businesses"
        },
        {
          text: "SUPPORTING_SERVICES.We_understand_that_you_might_not_always_need_a_full_cycle_Quality_Assurance_service"
        }
      ],
      route: '/services/supporting-services',
      routeFragment: 'qa'
    },
    "HEADER.UI_UX": {
      data: [
        {
          title: "SUPPORTING_SERVICES.Elevate_Your_Use_Experience_with_Europe_Top_UI_UX_Talent",
          text: "SUPPORTING_SERVICES.East_Central_Europe_is_a_hub_for_the_world_most_innovative"
        },
        {
          text: "SUPPORTING_SERVICES.When_your_project_team_is_ready_to_bring_in_an_experienced_UX_consultant"
        }
      ],
      route: '/services/supporting-services',
      routeFragment: 'uiux'
    }
  }

  homeCarouselSourceData: CarouselSourceData[] = [
    {
      "imgPath": "assets/images/success/1.png",
      'text': "WHY_GLOBAL_TEAMS.Competitive_pricing",
      'hoverText': ["WHY_OUR_SERVICES.Competitive_content"],
      "route": "/company/why-global-teams",
      'routeFragment': "competitive"
    },
    {
      "imgPath": "assets/images/success/4.png",
      'text': "WHY_OUR_SERVICES.Flexibility",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.We_know_that_your_IT_needs",
        "WHY_GLOBAL_TEAMS.We_aim_to_be_as_agile_and_versatile"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "flexibility"
    },
    {
      "imgPath": "assets/images/success/3.png",
      'text': "WHY_GLOBAL_TEAMS.Attentive_wraparound_service",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.Our_business_goal_is_simply",
        "WHY_GLOBAL_TEAMS.We_will_handle_travel_arrangements_if_you_choose",
        "WHY_GLOBAL_TEAMS.Our_responsive_group_of_HR_workers"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "attentiveWraparound"
    },
    {
      "imgPath": "assets/images/success/2.png",
      'text': "WHY_GLOBAL_TEAMS.Handpicked_teams",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.We_are_here_to_pick_and_choose",
        "WHY_GLOBAL_TEAMS.Our_energetic_tech_savvy_recruiters"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "handpicked"
    },
    {
      "imgPath": "assets/images/success/5.png",
      'text': "WHY_GLOBAL_TEAMS.Full_back_office_services",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.One_of_the_biggest_advantages_of_working_with_Global_Team",
        "WHY_GLOBAL_TEAMS.When_you_use_Global_Teams_you_can_rely"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "fullbackoffice"
    },
    {
      "imgPath": "assets/images/success/6.png",
      'text': "WHY_GLOBAL_TEAMS.People_oriented",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.We_firmly_believe_that_investing_in_people",
        "WHY_GLOBAL_TEAMS.Our_employee_retention_program_increases_engagement"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "peopleoriendted"
    },
    {
      "imgPath": "assets/images/success/7.png",
      'text': "WHY_GLOBAL_TEAMS.Office_space",
      'hoverText': [
        "WHY_GLOBAL_TEAMS.We_provide_a_modern_positive_work_environment",
        "WHY_GLOBAL_TEAMS.Our_workspace_provides_the_optimal_conditions",
        "WHY_GLOBAL_TEAMS.Our_premises_are_located_in_the_heart_of"
      ],
      "route": "/company/why-global-teams",
      'routeFragment': "officeSpace"
    },
  ];

  benefitsList: BenefitsItemData[] = [
    {
      'subtitle': 'BENEFITS.Remote_but_Close',
      "content": 'BENEFITS.UKRAINE_IS_JUST_2_HOURS_AHEAD_OF_GMT',
      "backgroundColor": 'var(--color-light-blue)',
      'subtitleMarginLeft': '72px',
      'circleItem': {
        'backgroundColor': 'var(--color-light-blue)',
        'width': '95px',
        'height': '95px',
        'top': '-25px',
        'left': '-28px',
        'delay': '0.5s',
        'animationName': 'MoveLeftRightBenefitItem',
      },
      'circleItemText': {
        'left': "27px",
        'top': "47px",
        'text': '01'
      }
    },
    {
      'subtitle': 'BENEFITS.Low_in_cost',
      "content": 'BENEFITS.Pricing_always_matters_Outstaffing_to_Ukraine_allows',
      "backgroundColor": '#89DA98',
      'subtitleMarginLeft': '71px',
      'circleItem': {
        'backgroundColor': '#89DA98',
        'width': '86px',
        'height': '86px',
        'top': '-15px',
        'left': '-28px',
        'animationName': 'MoveUpDownBenefitItem',
        'delay': '0.5s',
      },
      'circleItemText': {
        'top': '40px',
        'left': '28px',
        'text': '02'
      }
    },
    {
      'subtitle': 'BENEFITS.Rich_in_talent',
      "content": 'BENEFITS.Ukraine_is_one_of_the_out_outstaffing_capitals',
      "backgroundColor": '#89DA98',
      'subtitleMarginLeft': '71px',
      'circleItem': {
        'backgroundColor': '#89DA98',
        'width': '95px',
        'height': '95px',
        'top': '-20px',
        'left': '-28px',
        'delay': '1.5s',
        'animationName': 'MoveUpDownBenefitItem'
      },
      'circleItemText': {
        'top': '36px',
        'left': '36px',
        'text': '03'
      }
    },
    {
      'subtitle': 'BENEFITS.English_speaking',
      "content": 'BENEFITS.Over_80perc_of_Ukrainian_developers_speak_English',
      "backgroundColor": '#0A2452',
      'subtitleMarginLeft': '71px',
      'circleItem': {
        'backgroundColor': '#0A2452',
        'width': '80px',
        'height': '80px',
        'top': '-20px',
        'left': '-28px',
        'animationName': 'MoveLeftRightBenefitItem',
        'delay': '1.5s',
      },
      'circleItemText': {
        'top': '35px',
        'left': '36px',
        'text': '04'
      }
    },
    {
      'subtitle': 'BENEFITS.Focus_on_education',
      "content": 'BENEFITS.Ukraine_invests_heavily_in_tech_education',
      "backgroundColor": '#0A2452',
      'subtitleMarginLeft': '50px',
      'circleItem': {
        'backgroundColor': '#0A2452',
        'width': '80px',
        'height': '80px',
        'top': '-20px',
        'left': '-28px',
        'animationName': 'MoveLeftRightBenefitItem',
        'delay': '2.5s',
      },
      'circleItemText': {
        'top': '37px',
        'left': '20px',
        'text': '05'
      }
    },
    {
      'subtitle': 'BENEFITS.Globally_recognized',
      "content": 'BENEFITS.International_corporations_including_Google',
      "backgroundColor": 'var(--color-light-blue)',
      'subtitleMarginLeft': '65px',
      'circleItem': {
        'backgroundColor': 'var(--color-light-blue)',
        'width': '90px',
        'height': '90px',
        'top': '-20px',
        'left': '-28px',
        'animationName': 'MoveUpDownBenefitItem',
        'delay': '2.5s',
      },
      'circleItemText': {
        'top': '41px',
        'left': '40px',
        'text': '06'
      }
    }
  ];


  getHomeCarouselSourceData() {
    return [...this.homeCarouselSourceData];
  }

  scroll(elementId, timer, correction): void {
    if (!timer) {
      timer = 1000;
    }
    if (!correction) {
      correction = 0;
    }
//    window.animatedScrolling = true;
    this.doScrolling(document.getElementById(elementId)?.offsetTop + correction, timer);

  }

  isMobile(): boolean {
    return ((screen && screen.width) || window.innerWidth ) <= 800;
  }

  doScrollingWidthDelay(elementY: number, delay: number) {
    if (this.isPlatformBrowser()) {
      setTimeout(() => {
        window.scrollTo({
          top: elementY,
          behavior: "smooth"
        });
      }, delay);
    }
  }

  isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }
  doScrolling(elementY, duration): void {

    if (this.isPlatformBrowser()) {
      window.scrollTo({
        top: elementY,
        behavior: "smooth"
      });
    }



    // //window.animatedScrolling = true;
    // document.querySelector("html").animate({
    //     scrollTop: elementY,

    //   }, duration
    // );

    // // setTimeout(() => {
    // //   window.animatedScrolling = false;
    // // }, 250);
  }

  isInViewport(element): boolean {
    if (document.getElementById(element) == null) {
      return false;
    }
    const rect = document.getElementById(element).getBoundingClientRect();
    if (rect.top >= 0 && rect.left >= 0 && document.documentElement.clientHeight < 500 && rect.bottom < 700) {
      return true;
    }

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

}
