<div class="page">

  <h1 class="title">
    <div class="title-why">
      {{"HEADER.Why"|translate}}
      <div class="bubble-green"></div>
    </div>
    <div>
      {{"COMMON.GLOBAL_TEAMS_LOWER"|translate}}
    </div>
  </h1>

  <div class="section">
    <div class="picture pic1 picture-cut-left">
      <app-circle-with-text
        class="why-global-teams-competitive-pricing desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Competitive_pricing'">
      </app-circle-with-text>
    </div>
    <div class="box">
      <div class="subtitle mobile-only competitive_pricing-subtitle" id="competitive">
      {{"WHY_GLOBAL_TEAMS.Competitive_pricing"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.We_understand_that_in_business"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.Ukraine_cost_effective_taxation"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.When_you_use_Global_Teams_to_outstaf"|translate}}
      </div>
    </div>
  </div>

  <div class="section sectionPicRight" id="flexibility">
    <div class="picture pic2 picture-cut-right">
      <app-circle-with-text
        class="why-global-teams-flexibility desktop-only"
        [title]="'WHY_OUR_SERVICES.Flexibility'">
      </app-circle-with-text>

      <app-dot class="blue-50-50 flexibility-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only flexibility-subtitle">
        {{"WHY_OUR_SERVICES.Flexibility"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.We_know_that_your_IT_needs"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.We_aim_to_be_as_agile_and_versatile"|translate}}
      </div>
    </div>
  </div>

  <div class="section" id="attentiveWraparound">
    <div class="picture pic3 picture-cut-left">
      <app-circle-with-text
        class="why-global-teams-attentive_wraparound_service desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Attentive_wraparound_service'">
      </app-circle-with-text>

      <app-dot class="green-30-30 attentive_wraparound_service-dot desktop-only"></app-dot>
    </div>

    <div class="box">
      <div class="subtitle mobile-only attentive_wraparound_service-subtitle">
        {{"WHY_GLOBAL_TEAMS.Attentive_wraparound_service"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.Our_business_goal_is_simply"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.We_will_handle_travel_arrangements_if_you_choose"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.Our_responsive_group_of_HR_workers"|translate}}
      </div>
    </div>
  </div>

  <div class="section sectionPicRight" id="handpicked">
    <div class="picture pic4 picture-cut-right">
      <app-circle-with-text
        class="why-global-teams-handpicked_teams desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Handpicked_teams'">
      </app-circle-with-text>

      <app-dot class="blue-40-40 handpicked_teams-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only handpicked_teams-subtitle">
        {{"WHY_GLOBAL_TEAMS.Handpicked_teams"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.We_are_here_to_pick_and_choose"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.Our_energetic_tech_savvy_recruiters"|translate}}
      </div>
    </div>
  </div>

  <div class="section">
    <div class="picture pic5 picture-cut-left" id="fullbackoffice">
      <app-circle-with-text
        class="why-global-teams-Full_back_office_services desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Full'"
        [subTitle]="'WHY_GLOBAL_TEAMS.Back_office_services'">
      </app-circle-with-text>

      <app-dot class="dark-blue-25-25 full_back_office_services-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only full_back_office_services-subtitle">
        {{"WHY_GLOBAL_TEAMS.Full_back_office_services"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.One_of_the_biggest_advantages_of_working_with_Global_Team"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.When_you_use_Global_Teams_you_can_rely"|translate}}
      </div>
    </div>
  </div>

  <div class="section sectionPicRight" id="peopleoriendted">
    <div class="picture pic6 picture-cut-right">
      <app-circle-with-text
        class="why-global-teams-People_oriented desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.People_oriented'">
      </app-circle-with-text>

      <app-dot class="green-50-50 people_oriented-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only people_oriented-subtitle">
        {{"WHY_GLOBAL_TEAMS.People_oriented"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.We_firmly_believe_that_investing_in_people"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.Our_employee_retention_program_increases_engagement"|translate}}
      </div>
    </div>
  </div>


  <div class="section"  id="officeSpace">
    <div class="picture pic7 picture-cut-left">
      <app-circle-with-text
        class="why-global-teams-Office_space desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Office_space'">
      </app-circle-with-text>

      <app-dot class="green-30-30 office_space-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only office_space-subtitle">
        {{"WHY_GLOBAL_TEAMS.Office_space"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.We_provide_a_modern_positive_work_environment"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.Our_workspace_provides_the_optimal_conditions"|translate}}

        <br/><br/>

        {{"WHY_GLOBAL_TEAMS.Our_premises_are_located_in_the_heart_of"|translate}}
      </div>
    </div>

  </div>

  <div class="section sectionPicRight"  id="Start_up_oriented">
    <div class="picture pic8 picture-cut-right">
      <app-circle-with-text
        class="why-global-teams-Start-up-oriented desktop-only"
        [title]="'WHY_GLOBAL_TEAMS.Start_up_oriented'">
      </app-circle-with-text>

      <app-dot class="blue-40-40 start_up_oriented-dot desktop-only"></app-dot>
    </div>
    <div class="box">
      <div class="subtitle mobile-only start_up_oriented-subtitle">
        {{"WHY_GLOBAL_TEAMS.Start_up_oriented"|translate}}
      </div>
      <div class="content">
        {{"WHY_GLOBAL_TEAMS.At_Global_Teams_we_believe_in_startups"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.THATS_WHY_WE_ARE_PASSIONATE_ABOUT_HELPING"|translate}}
        <br/><br/>
        {{"WHY_GLOBAL_TEAMS.We_have_special_rates_and_services"|translate}}
      </div>
    </div>
  </div>

</div>


<app-home-lets-talk [description]="'WHY_OUR_SERVICES.We_have_special_rates_and_services_for_start_ups'"></app-home-lets-talk>
