<div class="container">
  <div class="title" *ngIf="title">
    {{title | translate}}
  </div>

  <div class="sub-title" *ngIf="subTitle">
    {{subTitle | translate}}
  </div>

  <div class="body-text" *ngIf="bodyText">
    {{bodyText | translate}}
  </div>

</div>
