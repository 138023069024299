<div class="container"
  [class.active-hover]="isHoverMode"
  *ngIf="employeePositionProfileInfo"
  (mouseenter)="onMouseenter()"
  (mouseleave)="onMouseleave()"
  [ngStyle]="{'background-color': isHoverMode ? employeePositionProfileInfo.hoverBackgroundColor : ''}">

  <img [src]="employeePositionProfileInfo.imgPath"
    loading="lazy" [alt]="employeePositionProfileInfo.position" />

  <div class="hover-body-container">
    <div class="hover-text"
      [ngStyle]="{'color': employeePositionProfileInfo.hoverTextColor}">
      {{employeePositionProfileInfo.position}}
    </div>
  </div>

</div>
