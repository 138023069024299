<div class="container">

  <div class="trusted-container">
    <div class="trusted-text">
      <div class="they-trust-us-label">
        <div>
          {{"THEY_TRUST_US.They"|translate}}
        </div>
        <div class="they-trust-us-label__second-part">
          {{"THEY_TRUST_US.Trust_Us"|translate}}
        </div>
      </div>

      <div class="they-trust-us-body">
        <div>
          {{"OUR_LEADERS.Our_mission_is_to_help"|translate}}
        </div>

        <div>
          {{"OUR_LEADERS.We_each_welcome_you_to_reach_out_and_set"|translate}}
        </div>
      </div>
    </div>

    <div class="trusted-by-logo-items" *ngIf="displayData">

      <div class="trusted-by-logo-row">
        <div class="trusted-by-logo" *ngFor="let clientItem of displayData">
          <img loading="lazy" [src]="clientItem.imgPath" />
        </div>
      </div>

    </div>
  </div>
</div>
