<div class="whyGlobalTeams">
  <div class="title">
    <span>{{"WHY_OUR_SERVICES.WHY"|translate}}</span>
    {{"COMMON.GLOBAL_TEAMS"|translate}}
  </div>
  <div class="whyGlobal">

    <div class="pContainer">
      <div class="title">
      {{"WHY_OUR_SERVICES.Competitive"|translate}}
      <br/>
      {{"WHY_OUR_SERVICES.pricing"|translate}}</div>
      <div class="content">
        {{"WHY_OUR_SERVICES.Competitive_content"|translate}}
      </div>
    </div>

    <div class="pContainer">
      <div class="title">{{"WHY_OUR_SERVICES.Flexibility"|translate}}</div>
      <div class="content">
        {{"WHY_OUR_SERVICES.No_isnt_in_our_vocabulary"|translate}}
        {{"WHY_OUR_SERVICES.We_are_always_ready_to_rise"|translate}}
      </div>
    </div>

    <div class="pContainer">
      <div class="title">
        {{"WHY_OUR_SERVICES.Handpicked"|translate}}
        <br/>
        {{"WHY_OUR_SERVICES.Talents"|translate}}
      </div>
      <div class="content">
        {{"WHY_OUR_SERVICES.Whether_you_are_building_a_team_from_scratch"|translate}}
      </div>
    </div>

    <div class="pContainer">
      <div class="title">
        {{"WHY_OUR_SERVICES.Full_back_office"|translate}}
        <br/>
        {{"WHY_OUR_SERVICES.services"|translate}}
      </div>
      <div class="content">
        {{"WHY_OUR_SERVICES.We_take_over_all_the_hassle_of_managing_overhead"|translate}}
      </div>
    </div>

    <div class="pContainer">
      <div class="title">{{"WHY_OUR_SERVICES.People_oriented"|translate}}</div>
      <div class="content">
        {{"WHY_OUR_SERVICES.We_invest_in_our_team_members"|translate}}
      </div>
    </div>




  </div>

</div>
