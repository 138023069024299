<div class="logosTechnologies">
  <div class="technologies marquee">
    <div>
      <div class="tech1"></div>
      <div class="tech2"></div>
      <div class="tech3"></div>
      <div class="tech4"></div>
      <div class="tech5"></div>
      <div class="tech6"></div>
      <div class="tech7"></div>
      <div class="tech8"></div>
      <div class="tech9"></div>
      <div class="tech10"></div>
      <div class="tech11"></div>
      <div class="tech12"></div>
      <div class="tech13"></div>
      <div class="tech14"></div>
      <div class="tech15"></div>
      <div class="tech16"></div>
      <div class="tech17"></div>
      <div class="tech18"></div>
      <div class="tech19"></div>
      <div class="tech20"></div>
      <div class="tech21"></div>
      <div class="tech22"></div>
    </div>
  </div>
  <div class="technologies marquee2">
    <div>
      <div class="tech1"></div>
      <div class="tech2"></div>
      <div class="tech3"></div>
      <div class="tech4"></div>
      <div class="tech5"></div>
      <div class="tech6"></div>
      <div class="tech7"></div>
      <div class="tech8"></div>
      <div class="tech9"></div>
      <div class="tech10"></div>
      <div class="tech11"></div>
      <div class="tech12"></div>
      <div class="tech13"></div>
      <div class="tech14"></div>
      <div class="tech15"></div>
      <div class="tech16"></div>
      <div class="tech17"></div>
      <div class="tech18"></div>
      <div class="tech19"></div>
      <div class="tech20"></div>
      <div class="tech21"></div>
      <div class="tech22"></div>
    </div>
  </div>
  <div class="technologies marquee3">
    <div>
      <div class="tech1"></div>
      <div class="tech2"></div>
      <div class="tech3"></div>
      <div class="tech4"></div>
      <div class="tech5"></div>
      <div class="tech6"></div>
      <div class="tech7"></div>
      <div class="tech8"></div>
      <div class="tech9"></div>
      <div class="tech10"></div>
      <div class="tech11"></div>
      <div class="tech12"></div>
      <div class="tech13"></div>
      <div class="tech14"></div>
      <div class="tech15"></div>
      <div class="tech16"></div>
      <div class="tech17"></div>
      <div class="tech18"></div>
      <div class="tech19"></div>
      <div class="tech20"></div>
      <div class="tech21"></div>
      <div class="tech22"></div>
    </div>
  </div>
</div>
