import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-lets-talk',
  templateUrl: './home-lets-talk.component.html',
  styleUrls: ['./home-lets-talk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeLetsTalkComponent {

  @Input() description = "HOME_LETS_TALK.DESCRIPTION";


}
