import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { EmployeeProfileInfo } from '../models/common.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-employee-profile-info',
  templateUrl: './employee-profile-info.component.html',
  styleUrls: ['./employee-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeProfileInfoComponent implements OnInit, OnDestroy {

  @Input() item: EmployeeProfileInfo;

  imgPath: string;

  private isDefaultImage = true;
  private intervalSubscription: Subscription


  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.imgPath = this.item?.imgPath;

    if (isPlatformBrowser(this.platformId)) {
      //this.initInterval();
    }
  }

  ngOnDestroy() {
    this.intervalSubscription?.unsubscribe();
  }

  private initInterval() {
    this.intervalSubscription = interval(this.item.interval || 3000).subscribe(() => {
      if (this.item.nextImgPath) {
        this.isDefaultImage = !this.isDefaultImage;
        this.imgPath = this.isDefaultImage ? this.item?.imgPath : this.item.nextImgPath || this.item?.imgPath;

        this.detectChanges();
      }
    });
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }
}
