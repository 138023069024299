import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-our-leaders',
  templateUrl: './our-leaders.component.html',
  styleUrls: ['./our-leaders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurLeadersComponent implements OnInit {


  constructor(private uiService: UiServiceService) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 300);
  }

}
