<app-intro (JumpTo)="openLetsTalk()" id="home" #home></app-intro>

<app-home-personal-touch></app-home-personal-touch>

<app-home-lets-talk></app-home-lets-talk>

<app-services id="services" #services></app-services>

<app-success (JumpTo)="openLetsTalk()" id="success" #success [scrolOnInit]="false"></app-success>

<div class="testimonialWrapper" id="testimonials" #testimonials>
  <app-testimonials [teamsRotating]="true"></app-testimonials>
</div>



