
<div class="job" *ngIf="job$ | async as job; else loadingTemplate">
  <div class="job__title">
    <div class="title" [innerHTML]="job.jobTitle">
    </div>

    <app-dot class="view-position-28-28 desktop-only"></app-dot>
    <app-dot class="view-position-70-70 desktop-only"></app-dot>
    <app-dot class="view-position-21-21 desktop-only"></app-dot>
  </div>

  <div class="job__description">
    <div class="section-title">
      {{"OPEN_POSITIONS.Description"|translate}}:
    </div>

    <div class="section-item" [innerHTML]="job.description">
    </div>
  </div>

  <div class="job__responsibilities">
    <div class="section-title" *ngIf="job.responsibilities">
      {{"OPEN_POSITIONS.Responsibilities"|translate}}:
    </div>

    <div *ngIf="job.responsibilities">
      <ul>
        <li class="section-item" *ngFor="let responsibilityItem of job.responsibilities">
          {{responsibilityItem}}
        </li>
      </ul>
    </div>

  </div>

  <div class="job__requirements">
    <div class="section-title">
      {{"OPEN_POSITIONS.Requirements"|translate}}:
    </div>

    <div>
      <ul *ngIf="job.requirements">
        <li class="section-item">
          {{job.englishLevel}}
          {{"OPEN_POSITIONS.English_level"|translate}}
        </li>

        <li class="section-item" *ngFor="let requirementItem of job.requirements">
          {{requirementItem}}
        </li>
      </ul>
    </div>

  </div>

  <div class="job__tech-stack" *ngIf="job.techStack?.length">
    <div class="section-title">
      {{"OPEN_POSITIONS.TechStack"|translate}}:
    </div>

    <div>
      <ul>
        <li class="section-item" *ngFor="let techStackItem of job.techStack">
          {{techStackItem}}
        </li>
      </ul>
    </div>
  </div>

  <div class="job__advantages" *ngIf="job.advantages?.length">
    <div class="section-title">
      {{"OPEN_POSITIONS.advantages_label"|translate}}:
    </div>

    <div>
      <ul>
        <li class="section-item" *ngFor="let advantagesItem of job.advantages">
          {{advantagesItem}}
        </li>
      </ul>
    </div>
  </div>

  <div class="job__advantages" *ngIf="job.you_are?.length">
    <div class="section-title">
      {{"OPEN_POSITIONS.you_are_label"|translate}}:
    </div>

    <div>
      <ul>
        <li class="section-item" *ngFor="let youAreItem of job.you_are">
          {{youAreItem}}
        </li>
      </ul>
    </div>
  </div>

  <div class="job__about" *ngIf="job.about">
    <div class="section-title">
      {{"OPEN_POSITIONS.about_label"|translate}}:
    </div>

    <div>
      <ul>
        <li class="section-item hide-dots">
          {{job.about}}
        </li>
      </ul>
    </div>
  </div>

  <div class="we_offer">
    <div class="section-title">
      {{"OPEN_POSITIONS.We_are_ready_to_offer"|translate}}:
    </div>
    <ul>
      <li class="section-item">{{"OPEN_POSITIONS.Competitive_salary_and_performance_based_incentives"|translate}}</li>
      <li class="section-item">{{"OPEN_POSITIONS.Comprehensive_benefits_package"|translate}}</li>
      <li class="section-item">{{"OPEN_POSITIONS.Opportunities_for_professional_growth_and_development"|translate}}</li>
      <li class="section-item">{{"OPEN_POSITIONS.A_dynamic_and_collaborative_work_environment"|translate}}</li>
    </ul>
  </div>

  <div class="button-container">
    <app-button-default class="button-apply" [buttonText]="'OPEN_POSITIONS.APPLY_FOR_THIS_JOB'"
      (buttonClicked)="onButtonCVClicked(job.id)">
    </app-button-default>

    <app-button-default class="backButton back-to-job-list" [buttonText]="'OPEN_POSITIONS.BACK_TO_JOB_LIST'"
      (buttonClicked)="openNavigateToOpenPositions()">
    </app-button-default>
  </div>
</div>

<ng-template #loadingTemplate>
  <app-loader-spinner></app-loader-spinner>
</ng-template>
