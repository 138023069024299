import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';

@Component({
  selector: 'app-ready-to-talk',
  templateUrl: './ready-to-talk.component.html',
  styleUrls: ['./ready-to-talk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadyToTalkComponent implements OnInit {

  constructor(
    private updateDifferentStateService: UpdateDifferentStateService) { }

  ngOnInit(): void {
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

}
