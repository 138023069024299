<div class="globaSection">
  <h1 class="title">
    {{"BENEFITS.AS_YOUR_REMOTE_BASE"|translate}}
  </h1>
</div>


<div class="benefitsList" appInViewPort (inViewportEvent)="onInViewportEvent()">
  <ng-container *ngIf="benefitsList">
    <div class="benefitItem" [ngStyle]="{'animation-delay': item.circleItem.delay }" *ngFor="let item of benefitsList">
      <span class="circle-item" [ngStyle]="{
          'backgroundColor': item.circleItem.backgroundColor,
          'width': item.circleItem.width,
          'height': item.circleItem.height,
          'top': item.circleItem.top,
          'left': item.circleItem.left,
          'animation-name': item.circleItem.animationName
        }">
        <span class="circle-item-text" [ngStyle]="{
            'top': item.circleItemText.top,
            'left': item.circleItemText.left
          }">
          {{item.circleItemText.text}}
        </span>
      </span>

      <div class="subtitle" [ngStyle]="{'margin-left': item.subtitleMarginLeft}">
        {{item.subtitle | translate}}
      </div>
      <div class="content">{{item.content | translate}}</div>
    </div>
  </ng-container>

</div>
