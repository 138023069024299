import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { JobsService } from '../../../services/jobs.service';
import { UiServiceService } from '../../../services/ui-service.service';
import { JobOpenPosition, OpenPositionFilterItem, SearchFilterFormData } from './models/open-positions.model'
@Component({
  selector: 'app-open-positions',
  templateUrl: './open-positions.component.html',
  styleUrls: ['./open-positions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenPositionsComponent implements OnInit, OnDestroy {

  @Input() isLatestOpeningsView: boolean;

  jobs$: Observable<JobOpenPosition[]>;
  filters$: Observable<OpenPositionFilterItem[]>;
  activeCategoryFilters: { [tagName: string]: boolean } = {};
  isRowView = false;
  filtersFormGroup: UntypedFormGroup;

  isSpinnerVisible = new BehaviorSubject<boolean>(true);

  constructor(
    public jobService: JobsService,
    public uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  private initializeData(): void {
    this.showSpinner();
    this.uiService.doScrolling(0, 50);
    this.filtersFormGroup = this.jobService.getSearchFilterForm();
    this.filters$ = this.jobService.getFilters();
    this.jobs$ = this.jobService.getJobs().pipe(tap(() => this.hideSpinner()));
  }

  ngOnDestroy(): void {
    this.jobService.clearJobsCache();
  }

  changeJobsView() {
    this.isRowView = !this.isRowView;
  }

  onPopularCategoryClicked(tagName: string) {
    this.showSpinner();

    this.filtersFormGroup.reset();

    this.jobs$ = this.jobService.filterJobsByCategory(tagName, this.activeCategoryFilters)
      .pipe(tap(() => this.hideSpinner()));
  }

  onButtonSearchClicked(filterItems: SearchFilterFormData) {
    this.showSpinner();

    this.activeCategoryFilters = {};

    this.jobs$ = this.jobService.filterJobsByMainFilters(filterItems)
      .pipe(tap(() => this.hideSpinner()));
  }

  onSendCvClicked() {
    this.updateDifferentStateService.emitLetsTalkCVForm(0);
  }

  private showSpinner() {
    this.isSpinnerVisible.next(true);
  }

  private hideSpinner() {
    this.isSpinnerVisible.next(false);
  }

}
