import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import {PrivacyComponent} from '../../pages/privacy/privacy.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {

  @Input() isHome = true;
  @Input() formMode = false;

  currentYear = new Date().getFullYear();

  constructor(
    private dialog: MatDialog,
    private updateDifferentStateService: UpdateDifferentStateService
    ) {
  }

  ngOnInit(): void {
  }

  openPrivacy(): void {
    const dialogRef = this.dialog.open(PrivacyComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openLetsTalk(isCV: boolean): void {
    this.updateDifferentStateService.emitLetsTalkCVForm(0);
  }


  scrollTop() {
    window.scroll(0,0);
  }

  openMailto() {
    window.open("mailto:info@globalteams.ltd", "_self");
  }

  openBlog() {
    window.open("https://www.blog.globalteams.ltd");
  }

}
