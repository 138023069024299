import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import { IWorkingAtGlobalImages } from 'src/app/shared/models/working-at-global.model';
import { UiServiceService } from '../../../services/ui-service.service';
import { WorkingAtGlobalService } from '../../../services/working-at-global.service';
@Component({
  selector: 'app-working-at-global',
  templateUrl: './working-at-global.component.html',
  styleUrls: ['./working-at-global.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkingAtGlobalComponent implements OnInit, AfterViewInit {

  allImagesList: IWorkingAtGlobalImages[];

  carouselTile: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  constructor(
    public uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService,
    private workingAtGlobalService: WorkingAtGlobalService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.allImagesList = this.workingAtGlobalService.getImagesList();
    this.uiService.doScrolling(0, 300);
  }

  ngAfterViewInit(): void {
    this.cdr?.detectChanges();
  }

  joinUsClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }
}
