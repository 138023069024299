import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { EmployeesService } from 'src/app/services/employees.service';
import { TeamProfileInfo } from '../models/common.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-meet-our-specialists',
  templateUrl: './meet-our-specialists.component.html',
  styleUrls: ['./meet-our-specialists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetOurSpecialistsComponent implements OnInit, OnDestroy {

  ourSpecialists: TeamProfileInfo[] = [];
  private subscriptionInterval: Subscription;

  constructor(
    private employeesService: EmployeesService,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }


  ngOnInit() {
    this.loadRandomEmployees();
    this.randomEmployeesInterval();
  }

  ngOnDestroy() {
    this.subscriptionInterval?.unsubscribe();
  }

  private randomEmployeesInterval() {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionInterval = interval(3 * 1000).subscribe(() => this.loadRandomEmployees());
    }
  }

  private loadRandomEmployees() {
    this.ourSpecialists = this.employeesService.getRandomEmployees();
    this.detectChanges();
  }

  private detectChanges() {
    if (!this.cdr['destroyed']) {
      this.cdr?.detectChanges();
    }
  }

}
