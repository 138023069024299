import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UpdateDifferentStateService } from 'src/app/services/update-different-state.service';
import {UiServiceService} from '../../../services/ui-service.service';

@Component({
  selector: 'app-managed-teams',
  templateUrl: './managed-teams.component.html',
  styleUrls: ['./managed-teams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagedTeamsComponent implements OnInit {

  textBody: string[] = [
    'SUPPORTING_SERVICES.Dedicated_teams_are_popular_because_they_are_well_suited_to_today',
    'SUPPORTING_SERVICES.These_managed_teams_operate_as_self_contained_autonomous_units_within_a_business',
    'SUPPORTING_SERVICES.Our_project_managers_have_extensive_IT_project_management_experience',
    'SUPPORTING_SERVICES.At_Global_Teams_we_have_seen_that_successful_projects_rely_on_effective_oversight',
  ];

  constructor(
    private uiService: UiServiceService,
    private updateDifferentStateService: UpdateDifferentStateService
    ) { }

  ngOnInit(): void {
    this.uiService.doScrolling(0, 0);
  }

  letsTalkClicked() {
    this.updateDifferentStateService.emitLetsTalkEvent();
  }

}
